import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { ThreeDots } from '../../common/svgIcons/chartIcon';
import { ITicket, getInitial } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { useDispatch } from 'react-redux';
import storeTicketPopoverInfo, { storeContactView } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { Link } from 'react-router-dom';
import timeSpanToReadbleFormat from '../../../util/timeSpanToReadbleFormat';
import { FallbackAvatar } from './ContactView';
interface IMobileTicketCardProps{
    ticket:ITicket
}
function MobileTicketCard(props:IMobileTicketCardProps) {
    const {ticket} = props;
    const dispatch = useDispatch();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorEl(event.currentTarget);
        // debugger;
        dispatch(storeTicketPopoverInfo({
            ticketId : ticket?.id || 0,
            anchorEl : event.currentTarget
        }))
    };
    const openContactView = () =>{
        if(ticket?.ticketLogs?.contacts){
            dispatch(storeContactView(ticket?.ticketLogs?.contacts))
        }
    }
    const SLA = timeSpanToReadbleFormat(`${ticket?.ticketLogs?.slaRemaining || "0"}`, "ss")
    return (<>
        <Box>
            <Box className="mobile-card">
                <Box className="first_row">
                    <Typography><Link to={`/ticketInfoView/${ticket?.id}`}>{ticket?.id}</Link></Typography>
                    <Button className="no-min-width" aria-describedby={`ticket-Id-${ticket?.id}`} onClick={handleClick}>
                        <ThreeDots />
                    </Button>
                </Box>
                <Box className="body">
                    <Typography>
                        {ticket?.ticketLogs?.title}
                    </Typography>
                </Box>
                <Divider />
                <Box className="mCardFooter">
                    <Box>
                        <Typography className="mcard-badges" component={"span"}>{ticket?.ticketLogs?.ticketStatus?.displayName || "-"}</Typography>
                        <Typography className="mcard-badges" component={"span"}>{ticket?.ticketLogs?.priority?.name || "-"}</Typography>
                    </Box>
                    <Box>
                        {/* <Typography onClick={openContactView} className="mcard-badges contact" component={"span"}>
                            {getInitial(`${ticket?.ticketLogs?.contacts?.name || "UN"}`)}
                        </Typography> */}
                        <Box className="sla-holder">
                            <Box onClick={openContactView}>
                                <FallbackAvatar
                                    src={`${ticket?.ticketLogs?.contacts?.profilePicturePath}`}
                                    alt={ticket?.ticketLogs?.contacts?.name || "Unknown Contact"}
                                    fallback="UN"
                                    width={30}
                                    height={30}
                                />
                            </Box>
                            <Typography component={"span"}>SLA <b className={`${`${SLA}`.includes("-")  ? "txt-danger" : "txt-success" }`} > {SLA}</b></Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    
      

    </>
    )
}

export default MobileTicketCard