import { Box, Button, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from "../../components/common2/Breadcrumbs";
import { getAIResponses } from '../../redux/actions/ChatBot/chatBot';
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest';
import { debounce } from 'lodash';
import SearchIcon from "@mui/icons-material/Search";
import ViewArticlePopup from './AI/ViewArticlePopup';


interface RowData {
    id : number,
    title : string,
    description : string,
    entityResolution : string,
    entityType : "Ticket" | "Article",
}

// Custom cell component
const ResolutionCell: React.FC<{ row: RowData }> = ({ row }) => {
    const { entityResolution, entityType } = row;
    return (
      <>
        {entityType === "Ticket" ? entityResolution  : <u><span className='text-primary'>Click to read Article</span></u> }
      </>
    );
};

const columns: GridColDef[] = [
    {
      field: "entityType",
      headerName: "Type",
      width: 100,
      renderHeader: () => <Typography style={{ fontSize: "14px" }}>Type</Typography>
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      renderHeader: () => <Typography style={{ fontSize: "14px" }}>Title</Typography>
    },
    {
      field: "description",
      headerName: "Description",
      width: 450,
      renderHeader: () => <Typography style={{ fontSize: "14px" }}>Description</Typography>
    },
    {
      field: "entityResolution",
      headerName: "Resolution",
      width: 350,
      renderHeader: () => <Typography style={{ fontSize: "14px" }}>Resolution</Typography>,
      renderCell: (params) => <ResolutionCell row={params.row as RowData} />,
    }
  ];
export function AIResponses() {
    const dispatch = useDispatch();
    const searchRef:any = React.createRef();
    const [list,setList] = React.useState([])
    const handleCellClick = (params: any) => {
        // Access row data via params.row
        if(params?.field === "entityResolution" && params?.row?.entityType === "Article"){
            // alert(`Clicked on ${params.field} of ${params.row.name}`);
            openArticle(params.row)
        }
    };

    const table = React.useMemo(()=>{
        return <DataGrid 
                    rows={list} 
                    columns={columns} 
                    disableRowSelectionOnClick
                    onCellClick={handleCellClick} 
                />
    },[list])
    React.useEffect(()=>{
        if(searchRef?.current){
            searchRef.current.focus();
        }
        onSearchKeyDownHandler({key:"Enter"})
        return ()=>{
            dispatch({
                type:"ARTICLE_POPUP_INFO",
                payload : {}
            })
        }
    },[])
    const getAIResponsesCB = (isSuccess:boolean,response:any) =>{
        setList(isSuccess ? response : []);
    }
    const onSearchKeyDownHandler = debounce((event: any) =>{
        if (event.key === 'Enter') {
            dispatch(getAIResponses(event?.target?.value || "",getAIResponsesCB))
        }
    },300)
    const onchangeHandler = debounce((event: any) =>{
        dispatch(getAIResponses(event?.target?.value || "",getAIResponsesCB))
    },300)
    const openArticle = (params:any) =>{
        const {entityId,entityResolution,title} = params;
        dispatch({
            type:"ARTICLE_POPUP_INFO",
            payload : {
              articleId: entityId,
              articleBody : entityResolution,
              title
            }
          })
    }
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'AI Search ',
                        path: '/AI-Search'
                    }
                ]}
            />
            <Box className="white-bg p-1">
                <Box className="pb-1">
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12} md={4}>
                            <Typography component="h2">AI Search</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextBoxLatest
                                placeholder="Please Enter"
                                ref={searchRef}
                                name='search'
                                onKeyDownHandler={onSearchKeyDownHandler}
                                onchangeHandler={onchangeHandler}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment onClick={()=>{onSearchKeyDownHandler({key:"Enter"})}} className='icon-holder pointer' position="end">
                                            <SearchIcon className="pointer"  /> &nbsp; &nbsp;
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box className="ai-results-table pt-1" style={{marginTop:"10px", height: "85vh", width: '100%' }}>
                    {table}
                </Box>
            </Box>

            <ViewArticlePopup/>
        </>
    );
}




export default AIResponses;