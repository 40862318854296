import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import useGetQueryParams from "../../../hooks/useGetQueryParams";
import { convertToTimeSpan } from "../../../pages/new-ticket/AddUpdate";
import { FindItem } from "../../../pages/new-ticket/LatestTicket/Edit";
import {
  generateErrorList,
  generateIdFromObjectForTicketCreation,
  getBase64,
} from "../../../pages/new-ticket/LatestTicket/Form";
import {
  getMimeType,
  onClickThumbNail,
} from "../../../pages/new-ticket/LatestTicket/RightPanel";
import {
  schemaForUpdateFormData,
  schemaForUpdateFormDataEmail,
} from "../../../pages/new-ticket/LatestTicket/Schema";
import {
  ValidationResultS,
  iFormData,
  iTicketManagementProperties,
} from "../../../pages/new-ticket/LatestTicket/Type";
import { isValidate } from "../../../pages/new-ticket/LatestTicket/Validation";
import {
  getAllGroup,
  getTicketByID,
  getUsrList,
  newFilterAllTickets,
} from "../../../redux/actions/TicketsManagement/TicketsManagement";
import getTemplates from "../../../redux/actions/adminInterfaces/EmailTemplates";
import { ActionType } from "../../../redux/actions/dashboard";
import { IState } from "../../../redux/reducers/rootReducers";
import {
  addNewTicket,
  updateNewTicket,
} from "../../../services/dashboard/dashboard.service";
import {
  fileUploadService,
  fileUploadServiceForBloeStorage,
  uploadTicketAttachment,
} from "../../../services/fileUpload/FileUpload";
import BasicButton from "../../common/button/BasicButton";
import CustomSelect from "../../common/customSelect/CustomSelect";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import Radio from "../../common/radio/Radio";
import CustomInput from "../../common/textField/CustomInput";
import {
  addEllipsis,
  parseTimeSpan,
  truncate,
} from "../../helpers/helperFunctions";
import { debounce } from "lodash";
import { getOrganisationsForDropdown } from "../../../redux/actions/CMDB/Organisation";
import AsyncDropDown from "../../../pages/new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown";
import LanguageData from "../../../configs/LanguageData.json";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import CloseCategoryOption from "./CloseCategoryOption";
import WordImg from "../../../assest/office.png";
import CsvImg from "../../../assest/csv.png";
import XlxsImg from "../../../assest/Xlsx.png";
import emlFormat from "../../../assest/eml-icon.png";

const ticketCreation = async (payload: any) => {
  console.log("payload", payload);
  let newTicket: any = await addNewTicket(payload);
  return newTicket;
};

const fileAttachment = async (attachment: any) => {
  let fileAttachment = await uploadTicketAttachment(attachment);
  return fileAttachment;
};

const updateTicket = async (id: any, payload: any) => {
  console.log("payload", payload);
  let newTicket: any = await updateNewTicket(payload, id);
  return newTicket;
};

const GenrateTemplateField = ({
  isEmailShow,
  handleSelectChange,
  value,
  setEmailChecked,
  emailChecked,
  template,
  setTemplate,
  error,
}: {
  isEmailShow: any;
  handleSelectChange: any;
  value: any;
  setEmailChecked: any;
  emailChecked: any;
  template: any;
  setTemplate: any;
  error: any;
}) => {
  const TemplatesList = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.AllEmailTemplates
  );

  useEffect(() => {
    console.log("isEmailShow", isEmailShow);
  }, [isEmailShow]);

  return (
    <>
      <Grid item xs={6} md={6}>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Radio
              setEmailChecked={setEmailChecked}
              emailChecked={emailChecked}
            />
            <span style={{ fontSize: "14px" }}>Send email</span>
          </div>
          {emailChecked && (
            <>
              <span style={{ fontSize: "14px" }}>
                Select a template
                <span style={{ color: "red" }}>*</span>
              </span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a Template"
                width={"100%"}
                name="template"
                isColor={false}
                isDisabled={false}
                isError={false}
                defaultValue={value}
                options={template}
                handleChange={(e: any) => {
                  handleSelectChange(e, "template");
                }}
                isMulti={false}
              />
              {generateErrorList(error.isValid, error.errors, "template")}
            </>
          )}
        </>
      </Grid>
    </>
  );
};
const GenratePrivateRadioBtn = ({
  setEmailChecked,
  emailChecked,
}: {
  setEmailChecked: any;
  emailChecked: any;
}) => {
  useEffect(() => {
    console.log("isEmailShow", emailChecked);
  }, [emailChecked]);

  return (
    <>
      <Grid item xs={6} md={6}>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Radio
              setEmailChecked={setEmailChecked}
              emailChecked={emailChecked}
            />
            <span style={{ fontSize: "14px" }}>Private Note</span>
          </div>
        </>
      </Grid>
    </>
  );
};

const TicketAddUpdatePopup = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ticketById, setTicketById] = useState<any>(null);
  const ticketInfo = useSelector(
    (state: IState) => state?.TicketViewReducer?.addUpdate
  );
  const ticketManagementProperties: iTicketManagementProperties = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses
  );

  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );

  const isEmailShow = useSelector(
    (state: IState) => state?.EmailSettingsReducer?.AllEmailSettings
  );

  const onCloseHandler = useCallback(() => {
    dispatch({
      type: "ADD_UPDATE_POPUP_INFO",
      payload: null,
    });
  }, []);
  useEffect(() => {
    console.log("ticketInfo check ", ticketInfo);
    if (ticketInfo?.id && (ticketInfo?.type === "quick-update")) {
      dispatch(getTicketByID(ticketInfo?.id, getTicketByIDCB));
    } else {
      setTicketById(ticketInfo);
    }
  }, [ticketInfo?.id]);
  const getTicketByIDCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      console.log("getTicketById", res);
      setTicketById({
        ...res,
        type: ticketInfo?.id,
      });
    }
  };
  const viewForm = useMemo(() => {
    return (
      <AddUpdateForm
        isEmailShow={isEmailShow}
        currAgent={currAgent}
        ticketInfo={ticketById}
        ticketManagementProperties={ticketManagementProperties}
      />
    );
  }, [ticketById]);
  const viewContent = useMemo(() => {
    const errort = parseTimeSpan(ticketInfo?.ticketLogs?.effort);
    return (
      <>
        {ticketById && (
          <>
            <Box className={classes.popupHolder}>
              <Box className="modal-dialog">
                <Box className="modal-content">
                  <Box className="modal-header">
                    <Typography variant="h6" className="txtCapitalize">
                      {ticketById?.id
                        ? `Update ${ticketById?.id}`
                        : "New ticket"}
                    </Typography>
                    <Box className={`gap15 ${classes.row}`}>
                      <Typography className={classes.txtWithLink} gutterBottom>
                        <span>
                          Effort: {errort?.days}d {errort?.hours}h{" "}
                          {errort?.mins}m
                        </span>
                      </Typography>
                      <Typography
                        className="cursor-pointer"
                        onClick={onCloseHandler}
                      >
                        <CloseIcon className="cursor-pointer" />
                      </Typography>
                    </Box>
                  </Box>
                  {viewForm}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  }, [ticketById]);
  return <>{viewContent}</>;
});

const AddUpdateForm = (props: any) => {
  const { ticketInfo, currAgent, ticketManagementProperties, isEmailShow } =
    props;

  const AllInfo = useSelector(
    (state: IState) => state?.TicketsReducer?.alldataStructure
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const [error, setError] = useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [isNotePrivate, setIsPrivateNote] = useState<boolean>(true);

  const statusIdFromUrl: string | boolean = useGetQueryParams("status");
  const priorityIdFromUrl: string | boolean = useGetQueryParams("priority");

  const TemplatesList = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.AllEmailTemplates
  );

  const [loading, setLoading] = useState<boolean>(false);
  console.log("ticketInfo?.ticketLogs?.agentId", ticketInfo);
  const [updateFormData, setUdateFormData] = useState<any>({
    group: FindItem(
      ticketManagementProperties.groups,
      ticketInfo?.ticketLogs?.groupId
    ),
    agent: FindItem(
      ticketManagementProperties.AgentsList,
      ticketInfo?.ticketLogs?.agentId
    ),
    status: FindItem(
      ticketManagementProperties.ticketStatus,
      ticketInfo?.ticketLogs?.statusId
    ),
    contactOrganisationId: ticketInfo?.ticketLogs?.contactOrganisation
      ? {
          ...ticketInfo?.ticketLogs?.contactOrganisation,
          label: ticketInfo?.ticketLogs?.contactOrganisation?.name,
          value: ticketInfo?.ticketLogs?.contactOrganisation?.name,
        }
      : 0,
    note: "",
    thirdPartyRefNo: ticketInfo?.ticketLogs?.thirdPartyRefNo || null,
    effortDay: "0",
    effortHour: "00",
    effortMinute: "00",
    downTimeDay: "0",
    downTimeHour: "00",
    downTimeMinute: "00",
    template: null,
    attachment: [],
  });
  const [agentOptions, setAgentOptions] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const noteRef: any = createRef();
  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getAllGroup(getCallback));
    if (noteRef?.current && ticketInfo?.ticketLogs?.isAISuggested) {
      noteRef.current.value = ticketInfo?.ticketLogs?.note || null;
    }
  }, []);
  console.log(
    "updateFormData_attachment",
    ticketInfo,
    updateFormData.attachment
  );

  useEffect(() => {
    prefillTemnplateBasedOnStatus(updateFormData.status);
  }, [updateFormData.status, TemplatesList]);

  function filterAttachmentPaths(data: any) {
    // Use the map function to extract attachmentPath values
    if (data?.length > 0) {
      const attachmentPaths = data.map((item: any) => {
        return {
          AttachmentPath: item.fileName ? item.fileName : item.attachmentPath,
          isActive: item.fileName ? true : item?.isActive,
          ticketLogId: item.fileName ? 0 : item?.ticketLogId,
          id: item.fileName ? 0 : item?.id,
        };
      });
      console.log("filterAttachmentPaths", attachmentPaths);
      return attachmentPaths;
    } else {
      return [];
    }
  }

  const onSubmit = (event: any) => {
    setLoading(true);

    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data: iFormData = {};

    formData.forEach((value, key) => {
      data[key] = value.toString();
    });

    let validationDataForUpdate: any = emailChecked
      ? {
          groupId: generateIdFromObjectForTicketCreation(updateFormData.group),
          agentId: generateIdFromObjectForTicketCreation(updateFormData.agent),
          statusId: generateIdFromObjectForTicketCreation(
            updateFormData.status
          ),
          note: data.note || "",
          template: generateIdFromObjectForTicketCreation(
            updateFormData.template
          ),
        }
      : {
          groupId: generateIdFromObjectForTicketCreation(updateFormData.group),
          agentId: generateIdFromObjectForTicketCreation(updateFormData.agent),
          statusId: generateIdFromObjectForTicketCreation(
            updateFormData.status
          ),
          note: data.note || "",
        };

    const validation = isValidate(
      validationDataForUpdate,
      emailChecked ? schemaForUpdateFormDataEmail : schemaForUpdateFormData
    );
    console.log("validation updateFormData", updateFormData,ticketInfo.ticketLogs,data);

    if (validation.isValid) {
      setError({ isValid: false, errors: {} });
      if(validationDataForUpdate.note === ""){
        validationDataForUpdate.note = null;
      }
      let payload: any = ticketInfo?.id
        ? {
            id: Number(ticketInfo?.id),
            sourceCode: ticketInfo.sourceCode,
            ticketLogs: {
              ...ticketInfo.ticketLogs,
              ...validationDataForUpdate,
              IsNotePrivate: isNotePrivate,
              group: updateFormData.group,
              ticketStatus: updateFormData.status,
              agent: {
                id: updateFormData?.agent?.id,
                firstName: updateFormData?.agent?.label,
                email: updateFormData?.agent?.email,
              },
              effort: convertToTimeSpan(
                data?.effortDay,
                data?.effortHour,
                data?.effortMinute
              ),
              downtime: convertToTimeSpan(
                data?.downTimeDay,
                data?.downTimeHour,
                data?.downTimeMinute
              ),
              ticketLogAttachmentsList: filterAttachmentPaths(
                ticketInfo.type !== "addOrEdit"
                  ? updateFormData.attachment
                  : ticketInfo?.ticketLogs?.ticketLogAttachmentsList
              ),
              circulationListTO: ticketInfo.ticketLogs?.circulationListTO,
              circulationListCC: ticketInfo?.ticketLogs?.circulationListCC,
              circulationListBCC: ticketInfo?.ticketLogs?.circulationListBCC,
              thirdPartyRefNo: data?.thirdPartyRefNo || updateFormData?.thirdPartyRefNo || null,
              contactOrganisationId: updateFormData?.contactOrganisationId?.id || 0,
            },
            ticketAssetList: ticketInfo.ticketAssetList,
            ticketRelationshipList: null,
            ticketTagList: ticketInfo.ticketTagList,
            ticketTaskList: [],
            totalEffort: ticketInfo.totalEffort,
          }
        : {
            sourceCode: ticketInfo.sourceCode,
            ticketLogs: {
              ...ticketInfo.ticketLogs,
              ...validationDataForUpdate,
              IsNotePrivate: isNotePrivate,
              group: updateFormData.group,
              ticketStatus: updateFormData.status,
              agent: {
                id: updateFormData?.agent?.id,
                firstName: updateFormData?.agent?.label,
                email: updateFormData?.agent?.email,
              },
              effort: convertToTimeSpan(
                data?.effortDay,
                data?.effortHour,
                data?.effortMinute
              ),
              downtime: convertToTimeSpan(
                data?.downTimeDay,
                data?.downTimeHour,
                data?.downTimeMinute
              ),
              ticketLogAttachmentsList: filterAttachmentPaths(
                ticketInfo.type !== "addOrEdit"
                  ? updateFormData.attachment
                  : ticketInfo?.ticketLogs?.ticketLogAttachmentsList
              ),
              circulationListTO: ticketInfo.ticketLogs?.circulationListTO,
              circulationListCC: ticketInfo?.ticketLogs?.circulationListCC,
              circulationListBCC: ticketInfo?.ticketLogs?.circulationListBCC,
              thirdPartyRefNo: data?.thirdPartyRefNo || updateFormData?.thirdPartyRefNo || null,
              contactOrganisationId: updateFormData?.contactOrganisationId?.id || 0,
            },
            ticketAssetList: ticketInfo.ticketAssetList,
            ticketRelationshipList: null,
            ticketTagList: ticketInfo.ticketTagList,
            ticketTaskList: [],
            totalEffort: ticketInfo.totalEffort,
          };
      console.log(
        "validation ticketInfo",
        payload,
        updateFormData.template,
        data
      );
      // return
      if (ticketInfo.id) {
        updateTicket(ticketInfo.id, payload).then((res: any) => {
          if (res.status == 200) {
            setLoading(false);
            const updatedTckt: any = res.data;
            console.log("updatedTcktupdatedTckt", res.data);
            dispatch({
              type: "UPDATE_LIST_AFTER_QUICKUPDATE",
              payload: {
                ticketId: res.data.id,
                ticketInfo: res.data,
              },
            });
            res?.data?.ticketLogs?.ticketLogAttachmentsList.map(
              (item: any, index: any) => {
                ticketInfo.type !== "addOrEdit"
                  ? fileUploadServiceForBloeStorage(
                      item.attachmentPath,
                      updateFormData.attachment[index].formFile,
                      (isDone: any, res: any) => {
                        if (isDone) {
                          console.log("inputFileHandler file uploaded", res);
                          // navigate("/contact");
                        } else {
                          console.log("inputFileHandler file failed", res);
                        }
                      }
                    )
                  : fileUploadServiceForBloeStorage(
                      item.attachmentPath,
                      ticketInfo?.ticketLogs?.ticketLogAttachmentsList[index]
                        .formFile,
                      (isDone: any, res: any) => {
                        if (isDone) {
                          console.log("inputFileHandler file uploaded", res);
                          // navigate("/contact");
                        } else {
                          console.log("inputFileHandler file failed", res);
                        }
                      }
                    );
              }
            );
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `${LanguageData.SUCCESS} (${ticketInfo.id}) ${LanguageData.TICKET_UPDATED}`,
                severity: "success",
                open: true,
              },
            });
            // dispatch(getAllTickets());
            if (emailChecked) {
              onCloseHandler();
              dispatch({
                type: "SEND_EMAIL_VIEW",
                payload: {
                  ...res.data,
                  template: updateFormData.template,
                },
              });
            } else {
              onCloseHandler();
              if (ticketInfo?.type == "update") {
                // navigate(-1);
              } else {
                navigate("/TicketManagementSys");
                //dispatch(newFilterAllTickets(AllInfo?.statusTypes));
              }
            }
          } else {
            setLoading(false);
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `Ticket Update Failed`,
                severity: "error",
                open: true,
              },
            });
          }
        });
      } else {
        ticketCreation(payload).then((res: any) => {
          if (res.status == 201) {
            setLoading(false);
            // dispatch(getAllTickets());
            // {res.}
            console.log(
              "response ticket creation",
              res?.data?.ticketLogs?.ticketLogAttachmentsList,
              ticketInfo?.ticketLogs?.ticketLogAttachmentsList
            );
            res?.data?.ticketLogs?.ticketLogAttachmentsList.map(
              (item: any, index: any) => {
                fileUploadServiceForBloeStorage(
                  item.attachmentPath,
                  ticketInfo?.ticketLogs?.ticketLogAttachmentsList[index]
                    .formFile,
                  (isDone: any, res: any) => {
                    if (isDone) {
                      console.log("inputFileHandler file uploaded", res);
                      // navigate("/contact");
                    } else {
                      console.log("inputFileHandler file failed", res);
                    }
                  }
                );
              }
            );

            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: ` ${LanguageData.TICKET_ADDED_SUCCESSFULLY}${res?.data?.id}`,
                severity: "success",
                open: true,
              },
            });
            if (emailChecked) {
              onCloseHandler();
              dispatch({
                type: "SEND_EMAIL_VIEW",
                payload: {
                  ...res.data,
                  template: updateFormData.template,
                },
              });
            } else {
              onCloseHandler();
              navigate("/TicketManagementSys");
              // dispatch(newFilterAllTickets(AllInfo?.statusTypes));
            }
          } else {
            setLoading(false);
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `Ticket creation Failed`,
                severity: "error",
                open: true,
              },
            });
          }
        });
      }
    } else {
      // Form is invalid, update the errors state

      setError(validation);

      setLoading(false);
    }
  };

  const handleSelectChange = (e: unknown, type: string) => {
    setUdateFormData((prevUserFormData: any) => {
      return {
        ...prevUserFormData,
        [type]: e,
      };
    });
  };

  const onCloseHandler = useCallback(() => {
    dispatch({
      type: "ADD_UPDATE_POPUP_INFO",
      payload: null,
    });
  }, []);

  useEffect(() => {
    console.log("currAgentcurrAgentcurrAgent", updateFormData.agent, currAgent);

    if (currAgent) {
      if (!updateFormData.agent) {
        let currAgentModifiedObjectForSelect: any = {
          label: `${currAgent.firstName} ${currAgent.lastName} (${currAgent.userName})`,
          name: currAgent.firstName,
          value: currAgent.id,
          id: currAgent.id,
          color: "red",
          email: currAgent?.email,
        };
        let isCurrAgentPresentInAgentList: any = FindItem(
          ticketManagementProperties.AgentsList,
          currAgentModifiedObjectForSelect.id
        );
        console.log(
          "isCurrAgentPresentInAgentList",
          isCurrAgentPresentInAgentList,
          currAgentModifiedObjectForSelect,
          ticketManagementProperties.AgentsList
        );

        if (isCurrAgentPresentInAgentList) {
          handleSelectChange(currAgentModifiedObjectForSelect, "agent");
          if (!ticketInfo?.ticketLogs?.isAISuggested) {
            prefillStatusBasedOnAgentChange(currAgentModifiedObjectForSelect);
          }
        }
      } else {
        // prefillStatusBasedOnAgentChange(updateFormData.agent);
      }

      if (!updateFormData.group) {
        let isDefault = null;
        currAgent.userGroupList.filter((ele: any) => {
          if (ele.isDefault == true) {
            isDefault = ele.groupId;
          }
        });
        let isDefaultPresentForCurrAgent: any = FindItem(
          ticketManagementProperties.groups,
          isDefault
        );
        console.log("currAgentcurrAgentcurrAgent", currAgent);

        if (isDefaultPresentForCurrAgent) {
          handleSelectChange(isDefaultPresentForCurrAgent, "group");
          prefillAgentOptionsBasedGroup(isDefaultPresentForCurrAgent);
        }
      } else {
        prefillAgentOptionsBasedGroup(updateFormData.group);
      }
    }
  }, [currAgent]);

  useEffect(() => {
    if (statusIdFromUrl) {
      let statusTemp = ticketManagementProperties.ticketStatus.find(
        (x: any) => x.id == statusIdFromUrl
      );
      console.log("priorityIdFromUrlpriorityIdFromUrl", statusTemp);

      handleSelectChange(statusTemp, "status");
      //------------------------------------------------------
      let currAgentModifiedObjectForSelect: any = {
        label: `${currAgent.firstName} ${currAgent.lastName} (${currAgent.userName})`,
        name: currAgent.firstName,
        value: currAgent.id,
        id: currAgent.id,
        color: "red",
        email: currAgent?.email,
      };
      let isCurrAgentPresentInAgentList: any = FindItem(
        ticketManagementProperties.AgentsList,
        currAgentModifiedObjectForSelect.id
      );
      if (isCurrAgentPresentInAgentList) {
        handleSelectChange(currAgentModifiedObjectForSelect, "agent");
        // prefillStatusBasedOnAgentChange(currAgentModifiedObjectForSelect);
      }
      let isDefault = null;
      currAgent.userGroupList.filter((ele: any) => {
        if (ele.isDefault == true) {
          isDefault = ele.groupId;
        }
      });
      let isDefaultPresentForCurrAgent: any = FindItem(
        ticketManagementProperties.groups,
        isDefault
      );
      console.log("currAgentcurrAgentcurrAgent", currAgent);

      if (isDefaultPresentForCurrAgent) {
        handleSelectChange(isDefaultPresentForCurrAgent, "group");
        prefillAgentOptionsBasedGroup(isDefaultPresentForCurrAgent);
      }
      //-----------------------------------------------------------------------
    }
  }, [statusIdFromUrl]);

  useEffect(() => {
    if (priorityIdFromUrl) {
      console.log("hshshs", priorityIdFromUrl);
      let statusTemp = ticketManagementProperties.ticketStatus.find(
        (x: any) => x.name == "InProgress"
      );
      handleSelectChange(statusTemp, "status");
      let currAgentModifiedObjectForSelect: any = {
        label: `${currAgent.firstName} ${currAgent.lastName} (${currAgent.userName})`,
        name: currAgent.firstName,
        value: currAgent.id,
        id: currAgent.id,
        color: "red",
        email: currAgent?.email,
      };
      let isCurrAgentPresentInAgentList: any = FindItem(
        ticketManagementProperties.AgentsList,
        currAgentModifiedObjectForSelect.id
      );
      if (isCurrAgentPresentInAgentList) {
        handleSelectChange(currAgentModifiedObjectForSelect, "agent");
        prefillStatusBasedOnAgentChange(currAgentModifiedObjectForSelect);
      }
      let isDefault = null;
      currAgent.userGroupList.filter((ele: any) => {
        if (ele.isDefault == true) {
          isDefault = ele.groupId;
        }
      });
      let isDefaultPresentForCurrAgent: any = FindItem(
        ticketManagementProperties.groups,
        isDefault
      );
      console.log("currAgentcurrAgentcurrAgent", currAgent);

      if (isDefaultPresentForCurrAgent) {
        handleSelectChange(isDefaultPresentForCurrAgent, "group");
        prefillAgentOptionsBasedGroup(isDefaultPresentForCurrAgent);
      }
    }
  }, [priorityIdFromUrl]);

  const prefillAgentOptionsBasedGroup = (group: any) => {
    console.log("prefillAgentOptionsBasedGroup", group, updateFormData.agent);
    if (group) {
      let temp: any = [];
      group.userGroupList?.filter((groupEle: any) => {
        if (groupEle?.isActive && groupEle?.agent?.isActive) {
          let a: any = {
            label: `${groupEle?.agent?.firstName} ${groupEle?.agent?.lastName} (${groupEle?.agent?.userName})`,
            name: groupEle?.firstName,
            value: groupEle?.userId,
            id: groupEle?.userId,
            color: "red",
          };
          temp.push(a);
        }
      });
      setAgentOptions(temp);
    } else {
      setAgentOptions([]);
      setGroupOptions(ticketManagementProperties.groups);
    }
  };

  const prefillTemnplateBasedOnStatus = (status: any) => {
    console.log("prefillTemnplateBasedOnStatus", status);

    if (status?.name == "NewtoMe") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Assign") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp, "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (status?.name == "Resolved") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Resolve") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (status?.name == "Closed") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Close") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (status?.name == "OnHold") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "AddUpdate") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (status?.name == "InProgress") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "AddUpdate") {
          temp.push(ele);
        }
      });
      console.log("check template", temp);
      setTemplate(temp);

      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else {
    }
  };

  const prefillStatusBasedOnAgentChange = (agent: any) => {
    console.log("prefillStatusBasedOnAgentChange", agent.id, currAgent.id);
    if (agent.id == currAgent.id) {
      let statusTemp = ticketManagementProperties.ticketStatus.find(
        (x: any) => x.name === "InProgress"
      );
      handleSelectChange(statusTemp, "status");
      prefillTemnplateBasedOnStatus(statusTemp);
    } else {
      let statusTemp = ticketManagementProperties.ticketStatus.find(
        (x: any) => x.name === "NewtoMe"
      );
      handleSelectChange(statusTemp, "status");
      prefillTemnplateBasedOnStatus(statusTemp);
    }
  };

  function truncateString(str: string) {
    if (str.length > 5) {
      return str.substring(0, 8) + "...";
    }
    return str;
  }

  const imgCallBack = async (params: any, file: any) => {
    let recs: any = updateFormData.attachment;
    recs.push({
      file: params,
      fileName: file.name,
      Type: file.type,
      formFile: file,
    });
    handleSelectChange([...recs], "attachment");
  };
  const onRemoveAttchment = (index: number) => {
    let allRecs = updateFormData?.attachment;
    allRecs.splice(index, 1);
    handleSelectChange([...allRecs], "attachment");
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getCallback = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      res = res.map((i:any)=>{
        return {
          ...i,
          agentsList: getUsrList(i?.userGroupList),
        }
      })
      setGroupOptions(res);
    }
  };
  const generateDownTimeBasedOnStatus = (status: any) => {
    // console.log("generateDownTimeBasedOnStatus", status,ticketInfo);
    if (
      (status?.name === "Closed" || status?.name === "Resolved") &&
      (ticketInfo?.ticketLogs?.ticketLogAssetList?.length > 0 || ticketInfo?.ticketAssetList?.length > 0|| ticketInfo?.assets?.length > 0)
    ){
      return (
        <Grid item md={6}>
          <CustomFormLabel labelName={"Downtime"} isMandotary={false} />
          <Box style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <Box>
              <CustomFormLabel
                labelName={"Hour"}
                isMandotary={false}
                classNames="sml-txt"
              />
              <CustomInput
                type="number"
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Enter the downTime"}
                name="downTimeHour"
                helperText={""}
                placeholder={"Hours"}
              />{" "}
            </Box>
            <Box>
              <CustomFormLabel
                labelName={"Minute"}
                isMandotary={false}
                classNames="sml-txt"
              />
              <CustomInput
                type="number"
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Enter the downTime"}
                name="downTimeMinute"
                helperText={""}
                placeholder={"Mins"}
              />{" "}
            </Box>
          </Box>
        </Grid>
      );
    }
  };
  const promiseOptionsForOrg = debounce((inputValue: any, callback: any) => {
    if (inputValue.length > 2) {
      dispatch(getOrganisationsForDropdown(inputValue, callback, true));
    } else {
      callback([]); // Pass options to the callback
    }
  }, 300);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className="modal-body">
          <Box className="p-1">
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <CustomFormLabel labelName={"Group"} isMandotary={true} />
                <CustomSelect
                  isOpen={false}
                  placeholder="Select Group"
                  width={"100%"}
                  name="group"
                  isColor={false}
                  isDisabled={false}
                  isError={false}
                  defaultValue={updateFormData.group}
                  options={groupOptions}
                  handleChange={(e: any) => {
                    handleSelectChange(e, "group");
                    handleSelectChange(null, "agent");
                    prefillAgentOptionsBasedGroup(e);
                  }}
                  isMulti={false}
                  helperText={""}
                />
                {generateErrorList(error.isValid, error.errors, "groupId")}
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomFormLabel labelName={"Agent"} isMandotary={true} />
                <CustomSelect
                  isOpen={false}
                  placeholder="Select Agent"
                  width={"100%"}
                  name="agent"
                  isColor={false}
                  isDisabled={false}
                  isError={false}
                  defaultValue={updateFormData.agent}
                  options={agentOptions}
                  handleChange={(e: any) => {
                    handleSelectChange(e, "agent");
                    prefillStatusBasedOnAgentChange(e);
                  }}
                  isMulti={false}
                  helperText={""}
                />
                {generateErrorList(error.isValid, error.errors, "agentId")}
              </Grid>
              <Grid item xs={6} md={6}>
                <br />
                <CustomFormLabel labelName={"Status"} isMandotary={true} />
                <CustomSelect
                  isOpen={false}
                  placeholder="Select Status"
                  width={"100%"}
                  name="status"
                  isColor={false}
                  handleChange={(e: any) => {
                    handleSelectChange(e, "status");
                    prefillTemnplateBasedOnStatus(e);
                  }}
                  isDisabled={false}
                  isError={false}
                  defaultValue={updateFormData.status}
                  options={ticketManagementProperties.ticketStatus}
                  isMulti={false}
                  helperText={""}
                />
                {generateErrorList(error.isValid, error.errors, "statusId")}
              </Grid>
              {(window.location.host.split(".")?.[0] === "simplisys" && (updateFormData?.status?.name === "Resolved" ||
                updateFormData?.status?.name === "Closed")) && (
                <Grid item xs={6} md={6}>
                  <br />
                  <CloseCategoryOption />
                </Grid>
              )}
              {updateFormData?.status?.name == "ThirdParty" && (
                <>
                  <Grid item xs={6} md={6}>
                    <br />
                    <CustomFormLabel
                      labelName={"Choose Third Party Organisation"}
                      isMandotary={true}
                    />
                    <AsyncDropDown
                      promiseOptions={promiseOptionsForOrg}
                      name="contactOrganisationId"
                      placeHolder="Search and select a Third Party"
                      handleChange={(e: any) => {
                        handleSelectChange(e, "contactOrganisationId");
                      }}
                      isError={false}
                      defaultValue={updateFormData.contactOrganisationId}
                    /> 
                    <p style={{fontSize:"12px",margin:0, color:"#c8c8c8"}}>* You need at least 3 characters to search</p>
                    {generateErrorList(
                      error.isValid,
                      error.errors,
                      "contactOrganisationId"
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <br />
                    <CustomFormLabel
                      labelName={"Third Party RefNo"}
                      isMandotary={true}
                    />
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={false}
                      label={"Enter the Third Party Ref Number"}
                      name="thirdPartyRefNo"
                      defaultValue={updateFormData?.thirdPartyRefNo||""}
                      helperText={""}
                      placeholder={"Third Party RefNo"}
                    />
                    {generateErrorList(
                      error.isValid,
                      error.errors,
                      "thirdPartyRefNo"
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={6} md={6}>
                <CustomFormLabel labelName={"Effort"} isMandotary={false} />
                <Box style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                  <Box> </Box>
                  <Box>
                    <CustomFormLabel
                      labelName={"Hour"}
                      isMandotary={false}
                      classNames="sml-txt"
                    />
                    <CustomInput
                      type="number"
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={false}
                      label={"Enter the effort"}
                      name="effortHour"
                      helperText={""}
                      placeholder={"Hours"}
                    />{" "}
                  </Box>
                  <Box>
                    <CustomFormLabel
                      labelName={"Minute"}
                      isMandotary={false}
                      classNames="sml-txt"
                    />
                    <CustomInput
                      type="number"
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={false}
                      label={"Enter the effort"}
                      name="effortMinute"
                      helperText={""}
                      placeholder={"Mins"}
                    />{" "}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <br />
                {/* <CustomFormLabel labelName={"Note"} isMandotary={false} />
                <CustomInput
                  isDisabled={false}
                  readOnly={false}
                  onClick={null}
                  iconPosition={"start"}
                  icon={false}
                  isError={false}
                  label={"Enter the Notes"}
                  name="note"
                  defaultValue={updateFormData.note}
                  helperText={""}
                  placeholder={"Note"}
                /> */}
                <TextBoxLatest
                  title="Note"
                  // isMandotary={true}
                  placeholder="Enter the Note"
                  ref={noteRef}
                  name="note"
                  multiline={true}
                />
                {generateErrorList(error.isValid, error.errors, "note")}
              </Grid>
              {generateDownTimeBasedOnStatus(updateFormData.status)}

              <GenrateTemplateField
                setEmailChecked={setEmailChecked}
                emailChecked={emailChecked}
                isEmailShow={isEmailShow}
                handleSelectChange={handleSelectChange}
                value={updateFormData.template}
                template={template}
                setTemplate={setTemplate}
                error={error}
              />
              <GenratePrivateRadioBtn
                setEmailChecked={setIsPrivateNote}
                emailChecked={isNotePrivate}
              />
            </Grid>
            {ticketInfo.type !== "addOrEdit" && (
              <Grid item xs={6} md={6}>
                <input
                  id="AssetFileUpload"
                  accept=".jpeg, .img, .png, .pdf, .bmp, .doc, .msg, .docx, .jpg, .csv, .xlxs, .xls, .eml"
                  type="file"
                  multiple={false}
                  onChange={(e: any) => {
                    // setProgress(true);
                    getBase64(e.target.files[0], imgCallBack);
                  }}
                  //value={FileUploadVal}
                  className="d-none"
                />

                <label
                  htmlFor="AssetFileUpload"
                  className="Add-Attachment"
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    float: "left",
                    color: "#0A93FC",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                  onClick={(e: any) => {}}
                >
                  + Add attachments
                </label>
                <>
                  {updateFormData.attachment?.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        padding: "25px",
                        display: "flex",
                      }}
                    >
                      <Slider {...settings}>
                        {updateFormData.attachment?.map(
                          (item: any, key: number) => {
                            console.log("AssetImagesAssetImages", item);

                            return (
                              <Box
                                sx={{ position: "relative", margin: "auto" }}
                              >
                                <Box
                                  className="slider-img"
                                  sx={{
                                    position: "relative",
                                    width: "100px",
                                    margin: "auto",
                                  }}
                                  onClick={() => {
                                    // deleteAttachment(key);
                                  }}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -20,
                                      backgroundColor: "white",
                                    }}
                                    onClick={() => {
                                      onRemoveAttchment(key);
                                    }}
                                  >
                                    {" "}
                                    <RemoveCircleIcon sx={{ color: "red" }} />
                                  </IconButton>
                                  {item.Type == "application/pdf" && (
                                    <PictureAsPdfIcon
                                      sx={{
                                        width: "100px",
                                        height: "100px",
                                        color: "gray",
                                        margin: "auto",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                      }}
                                      onClick={() => {
                                        var pdfResult = item.file;
                                        let pdfWindow: any = window.open("");
                                        pdfWindow.document.write(
                                          "<iframe width='100%' height='100%' src='" +
                                            encodeURI(pdfResult) +
                                            "'></iframe>"
                                        );
                                      }}
                                    />
                                  )}

                                 

                                  {item.Type == "image/png" && (
                                    <img
                                      src={`${item.file}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                      }}
                                      alt="image"
                                      onClick={() => {
                                        let image = new Image();
                                        image.src = item.file;
                                        var newTab: any = window.open();
                                        newTab.document.body.innerHTML =
                                          image.outerHTML;
                                      }}
                                    />
                                  )}

                                  {item.Type == "image/jpeg" && (
                                    <img
                                      src={`${item.file}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                      }}
                                      alt="image"
                                      onClick={() => {
                                        let image = new Image();
                                        image.src = item.file;
                                        var newTab: any = window.open();
                                        newTab.document.body.innerHTML =
                                          image.outerHTML;
                                      }}
                                    />
                                  )}
                                  {item.Type == "image/jpg" && (
                                    <img
                                      src={`${item.file}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                      }}
                                      alt="image"
                                      onClick={() => {
                                        let image = new Image();
                                        image.src = item.file;
                                        var newTab: any = window.open();
                                        newTab.document.body.innerHTML =
                                          image.outerHTML;
                                      }}
                                    />
                                  )}

                                  {item.Type === "application/msword" && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${WordImg}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                  {item.Type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${WordImg}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                  {item.Type === "text/csv" && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${CsvImg}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                  {["eml", "message/rfc822"].includes(
                                    item.Type
                                  ) && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${emlFormat}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                  {item.Type === "xlsx" && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${XlxsImg}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                  {item.Type === "application/vnd.ms-excel" && (
                                    <img
                                      onClick={() =>
                                        onClickThumbNail(fileAttachment)
                                      }
                                      src={`${XlxsImg}`}
                                      width={100}
                                      height={100}
                                    />
                                  )}

                                  {item.Type == "image/bmp" && (
                                    <img
                                      src={`${item.file}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                      }}
                                      alt="image"
                                      onClick={() => {
                                        let image = new Image();
                                        image.src = item.file;
                                        var newTab: any = window.open();
                                        newTab.document.body.innerHTML =
                                          image.outerHTML;
                                      }}
                                    />
                                  )}
                                  <p>{addEllipsis(item?.fileName, 10)}</p>
                                  {/* <HighlightOffOutlinedIcon /> */}
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </Slider>
                    </div>
                  ) : (
                    <>
                      {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <AttachmentIcon
                        sx={{ width: "50px", height: "50px", color: "#2F80ED" }}
                      />
                      <div>Selected Attachments will appear here</div>
                    </div> */}
                    </>
                  )}
                </>
              </Grid>
            )}
          </Box>
        </Box>
        <Box className="modal-footer">
          <Box className="text-right p-1">
            <BasicButton
              onClick={onCloseHandler}
              type={"cancel"}
              isLoading={false}
              isDisabled={false}
              color="primary"
              size="large"
              label={"Cancel"}
              variant={"outlined"}
              endIcon={<CloseIcon sx={{ width: "20px" }} />}
            />

            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <BasicButton
              type="submit"
              label={"Save"}
              isLoading={loading}
              disabled={loading}
              color="primary"
              size="large"
              variant={"contained"}
              endIcon={<SaveIcon sx={{ width: "20px" }} />}
            />
          </Box>
        </Box>
      </form>
    </>
  );
};
const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
    },
    txtWithLink: {
      fontSize: ".7em",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      "& span": {
        wordBreak: "break-all",
      },
      "& a": {
        fontSize: ".7em",
        textDecoration: "none",
        display: "inline-block",
        lineHeight: 1,
        "& svg": {
          fontSize: "12px",
        },
      },
    },
    popupHolder: {
      position: "fixed",
      top: 0,
      left: 0,
      background: "#0005",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 1399,
      "& .modal-dialog": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px",
      },
      "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px",
      },
      "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)",
      },
      "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "1000px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
          width: "calc(100% - 10px)",
        },
        "& img": {
          objectFit: "cover",
        },
      },
    },
  })
);
export default TicketAddUpdatePopup;
