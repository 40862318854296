import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import Form, { generateTagFromEdit } from "./Form";
import {
  iPermissions,
  iTicketManagementProperties
} from "./Type";
import AccessDenied from "../../../components/OtherPages/AccessDenied";

export const FindItem = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.id == searchItem);
  return temp ? temp : 0;
};
export const FindItemByName = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.code === searchItem);
  return temp ? temp : 0;
};
const modifyLabel = (item: any) => {
  if (item) {
    return {
      label: `${item.name},(${item.email})`,
      name: item.name,
      value: item.name,
      id: item.id,
      knownAs: item.knownAs,
      userName: item.userName,
      jobTitle: item.jobTitle,
      email: item.email,
      whatsAppNumber: item.whatsAppNumber,
      company: item.companyName,
      place: item.officeLocation,
      no: item.mobilePhone,
      isVIP: item.isVIP,
      alert: item.alert,
      profilePictureId: item.profilePictureId,
      icon: <AccountCircleOutlinedIcon sx={{ color: "#a6a6a6" }} />,
    };
  } else {
    return null;
  }
};



const Edit = ({
  style,
  id,
}: {
  style: any;
  id: any;
}) => {
  const dispatch = useDispatch();
  const ticketManagementProperties: any = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses
  );
  const permissions: any = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
  // const permissions1 = {
  //   isSuperAdmin: permissions?.isSuperAdmin,
  //   assetAdminAccess: permissions?.CMDB,
  // };

  useEffect(() => {
    if (!id) return;
    dispatch(getTicketByID(id, updatelogs))
  }, [id]);
  
  const [editData, setEditData] = useState<any>(false);
  const [errorResponse, setErrorResponse] = useState<any>("");

  const updatelogs = (resStatus: string, res: any) => {

      if (resStatus === '1'){
          setErrorResponse(res.status)
      }else{
        const defaultValue: any = {
          id: id,
          primaryContact: modifyLabel(res?.ticketLogs?.contacts),
          ticketType: FindItem(
            ticketManagementProperties.TicketTypes,
            res?.ticketLogs?.ticketTypeId
          ),
          secondaryContact: modifyLabel(res?.ticketLogs?.secondaryContacts),
          priority: FindItem(
            ticketManagementProperties.priorities,
            res?.ticketLogs?.priorityId
          ),
          source: FindItemByName(
            ticketManagementProperties.ticketSources,
            res?.sourceCode
          ),
          subject: res?.ticketLogs?.title,
          openDate: res?.ticketLogs?.openDateTime,
          desc: res?.ticketLogs?.description,
          nextActionDesc: res?.ticketLogs?.nextActionDescription ? res?.ticketLogs?.nextActionDescription: null,
          nextActionDate: res?.ticketLogs?.firstResponseDueDateTime,
          dueDate: res?.ticketLogs?.dueDateTime,
          tag: generateTagFromEdit(res?.ticketTagList),
          asset: res?.assets,
          category:  res?.ticketLogs?.category ||  null,//generateCategoryLabel(res?.ticketLogs?.categoryId, Category),
          groupId: res?.ticketLogs?.groupId,
          agentId: res?.ticketLogs?.agentId,
          statusId: res?.ticketLogs?.statusId,
          note: res?.ticketLogs?.note ? res?.ticketLogs?.note : "",
          effort: res?.ticketLogs?.effort,
          totalEffort: res.totalEffort,
          attachment: res?.attachmentDetails,
          circulationListTO: res.ticketLogs?.circulationListTO,
          circulationListBCC: res.ticketLogs?.circulationListBCC,
          circulationListCC: res.ticketLogs?.circulationListCC,
        };
        console.log("category defaultValue show", defaultValue,resStatus);
  
        setEditData(defaultValue);
        
      }
      console.log("category defaultValue show2222", res,resStatus,errorResponse);

  }


  return (
    <>
      {editData ? (
        <Form
          ticketManagementProperties={ticketManagementProperties}
          permissions={{
            isSuperAdmin: permissions?.isSuperAdmin,
            assetAdminAccess: permissions?.CMDB,
          }}
          style={style}
          defaultValue={editData}
          id={id}
          pageTitle="Edit Ticket"
          actionType="Edit"
        />
      ) : errorResponse === 403 ? (
        <AccessDenied />
      ) : null}
    </>
  );
  
};

export default Edit;
