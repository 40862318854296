import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import React, { createRef, useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    image,
    imageGallery,
    lineHeight,
    link,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    video,
} from 'suneditor/src/plugins';
import { ValidationResultS } from '../../pages/new-ticket/LatestTicket/Type';
import { isValidate } from '../../pages/new-ticket/LatestTicket/Validation';
import { FileUpload } from '../../redux/actions/FileUpload/FileUpload';
import { addArticle, editArticle, getArticlesById } from '../../redux/actions/KnowledgeBase/article';
import { getArticleType } from '../../redux/actions/KnowledgeBase/articleTypes';
import { getCategory } from '../../redux/actions/SystemManagement/AddCategory';
import { IState } from '../../redux/reducers/rootReducers';
import CommonAddAttachment from '../common/CommonAddAttachment';
import CustomSelect from '../common/customSelect/CustomSelect';
import CustomFormLabel from '../common/form-label/CustomFormLabel ';
import ProfilePictureUploader from '../common/ProfilePictureUploader/ProfilePictureUploader';
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest';
import Breadcrumbs from "../common2/Breadcrumbs";
import FileViewFromBlobUrl, { FileView } from '../MobileComponents/MobileCommon/FileView';
import AddContactAid from '../ServicesSSP/AddNew/AddContactAid';
import { addArticleSchema, iArticle } from './schema';
import { triggerAppAlert } from '../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import getTicket from '../../redux/actions/SystemManagement/ticket';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initVal: CounterState = {
    ticketType: null,
    articleType:null,
    category: null,
    thumbnailIconPath:"",
    thumbnailIconId:0,
    errors: {}
}
// Define the type for the state
interface CounterState {
    ticketType: null | any;
    articleType: null | any;
    category: null | any;
    thumbnailIconPath: string;
    thumbnailIconId : number;
    errors: any;
    articleAttachmentList?:any[];
}

// Define the type for the action
type CounterAction = { type: string, payload?: any };

// Reducer function
const articalReducer = (state: CounterState = initVal, action: CounterAction): CounterState => {
    switch (action.type) {
        case 'CHANGE_VALUE':
            return {
                ...state,
                ...action.payload
            };
        case 'STORE_ERR':
            return {
                ...state,
                errors: action.payload
            };
        case 'UNSET_ERR':
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload]: null
                }
            };
        default:
            return state;
    }
};


function CreateArticle(props: any) {
    const { dispatch} = props;
    const param = useParams();
    const [editVal, seteditVal] = useState<any>(null);
    const [articleTypeList, setArticleTypeList] = useState<any>([]);
    const getAllArticleTypeCB = (resStatus:string,res:any) => {
        if(resStatus === "0"){
            setArticleTypeList(res)
            if(res.length === 0){
                dispatch(triggerAppAlert("Please add Article types before you create Article","warning"))
            }
        }
    }
    useEffect(() => {
        dispatch(getArticleType(getAllArticleTypeCB))
        dispatch(getTicket())
        return ()=>{
                initVal.ticketType = null
                initVal.articleType =null
                initVal.category = null
                initVal.thumbnailIconPath =""
                initVal.thumbnailIconId =0
                initVal.errors = {}
        }
    }, [])
    useEffect(() => {
        if (!param?.id) return;
        dispatch(getArticlesById(param.id, getArticlesByIdCB))
    }, [param?.id])
    const getArticlesByIdCB = (resStatus: string, res: any) => {
        console.log("getArticlesByIdCB", res, resStatus)
        if (resStatus === "0"){
            initVal.category= res?.category || null;
            initVal.ticketType= res?.ticketType || null;
            initVal.articleType= res?.articleType || null;
            initVal.thumbnailIconPath =  res.thumbnailIconPath || "";
            initVal.thumbnailIconId = res.thumbnailIconId || 0;
            initVal.articleAttachmentList = res.articleAttachmentList || [];
            seteditVal(res)
        }
    }
    return (
        <>
            {((articleTypeList.length > 0) && (!param?.id || editVal?.id)) &&
                <CreateArticle1 articleTypeList={articleTypeList} editVal={editVal} />
            }
        </>
    )
}
function CreateArticle1(props: any) {
    console.log("CreateArticle1_initVal",initVal)
    const { editVal,articleTypeList=[] } = props;
    const nameRef: any = createRef();
    const navigate = useNavigate();
    const param = useParams();
    const keywordRef: any = createRef();
    const isPublishedRef: any = useRef();
    const isSSPRef: any = useRef();
    const [state, customDispatch] = useReducer(articalReducer, initVal);
    const [body, setBody] = useState<any>(editVal?.body || "");

    const [articleTypes, setArticleTypes] = useState<any>(articleTypeList || []);
    const [articleTypeVal, setarticleTypeVal] = useState<any>(editVal?.articleType || null);
    
    const [thumbObj, setThumbObj] = useState<any>({
        path : initVal?.thumbnailIconPath,
        name : initVal?.thumbnailIconPath,
        isOld : true
    });
    const [attachmentList, setAttachmentList] = useState<any>(initVal?.articleAttachmentList || []);

    const dispatch = useDispatch();
    const onImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
        const uploadCB = (resStatus: string, res: any) => {
            if (resStatus !== "0") return;
            let { filePath = "", name = "", fileSize = 0 } = res?.data;
            console.log("uploadesRes", res.data)
            const response = {
                "result": [
                    {
                        "url": filePath,
                        "name": name,
                        "size": fileSize
                    },
                ]
            }
            uploadHandler(response)
        }
        let fileObject = {
            description: "First File",
            filetypeid: 1,
            ExtensionTypeId: 1,
            name: "email-photo",
            formFile: files[0],
        };
        dispatch(FileUpload(fileObject, () => { }, false, uploadCB,null,"articleattachmentinline"));
    }
    const unsetErr = (name: string = "") => {
        customDispatch({
            type: "UNSET_ERR",
            payload: name
        })
    }
    const cancelHandler = () => {
        navigate('/ArticlesList')
    }
    const onSubmit = () => {
        const { category, ticketType } = state;
        const payload: iArticle = {
            "id": param.id ? +param.id : 0,
            "name": nameRef?.current?.value || "",
            "categoryId": category?.id || null,
            "ticketTypeId": ticketType?.id || 0,
            "body": body,
            "articleTypeId": articleTypeVal?.id || 0,
            "keywords": keywordRef?.current?.value || "",
            "isPublished": isPublishedRef?.current?.checked,
            "issspAccess": isSSPRef?.current?.checked,
            "isActive": true,
            "thumbnailIconPath": thumbObj?.name || null,
            "thumbnailIconObj": thumbObj?.isOld ? null : thumbObj,
            "thumbnailIconId": 0,
            "articleAttachmentList": attachmentList.map((i:any)=>{
                return {
                    id : i?.oldId,
                    fileObj : i?.isOld ? null : i,
                    attachmentPath: i.name,
                }
            })
        }

        /* ================== validation ================== */
        const { name, categoryId, ticketTypeId, articleTypeId} = payload;
        const validation: ValidationResultS = isValidate(
            { name, categoryId, articleTypeId, ticketTypeId },
            addArticleSchema
        );
        
        if(payload.body.length < 50){
            validation.errors.body = [];
            validation.isValid = false;
            validation.errors.body = ["Please enter minimum 50 characters"];
        }
        if (!validation.isValid) {
            customDispatch({
                type: "STORE_ERR",
                payload: {
                    ...validation.errors
                }
            })
            return;
        }
        console.log("onSubmit_____", payload);
        // return;
        if (param.id) {
            dispatch(editArticle(payload.id, payload, submitCB))
            return;
        }
        dispatch(addArticle(payload, submitCB))
    }
    const submitCB = (resStatus: string, res: any) => {
        console.log("submitCB", resStatus, res)
        if (resStatus === "0") {
            cancelHandler()
        }
    }
    useEffect(() => {
        /* ==== Prefill EDIT value ==== */
        if (!editVal) return;
        const { keywords, name, category } = editVal;
        if (nameRef?.current) nameRef.current.value = name;
        if (keywordRef?.current) keywordRef.current.value = keywords;
    }, [editVal])
    const imageUploadCB = (file:any=[]) => {
        setAttachmentList([...attachmentList,...file])
    }

    const uploadThumbCB = (res:any) =>{
        console.log("uploadThumbCB", res)
        setThumbObj(res)
    }
    const onRemoveAttachment = (index:number) =>{
        setAttachmentList((old:any)=>old.filter((_:any,i:number)=>i !== index))
    }
    return (
        <Box className="p-1">
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'Article List ',
                        path: '/ArticlesList'
                    },
                    {
                        title: `${param?.id ? "Edit" : "Add"} Article`,
                        path: `${param.id ? "/ArticlesList/" + param.id : "/createArticle"}`
                    }
                ]
                }
            />
            <Box className="white-bg p-2 mt-1 add-article">
                <Typography>
                    {param?.id ?  "Edit Article" : "Add New Article"}
                </Typography>
                <hr/>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomFormLabel
                            labelName={"Choose Thumbnail Image"}
                            isMandotary={false}
                            classNames='pb-1'
                        />

                        <ProfilePictureUploader path={thumbObj?.path} callback={uploadThumbCB}  />
                    </Grid>
                    <Grid item xs={4}>
                        <TextBoxLatest
                            multiline={false}
                            placeholder="Enter Name"
                            title={"Name"}
                            onchangeHandler={() => { 
                                if(state?.errors?.name){unsetErr("name")}
                             }}
                            ref={nameRef}
                            isMandotary={true}
                            errorMsg={`Please Enter`}
                            isError={state?.errors?.name || false}
                            name='name'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <CustomFormLabel
                            labelName={"Select Article Type"}
                            isMandotary={true}
                        />
                        <CustomSelect
                            isOpen={false}
                            placeholder="Select Article Type"
                            width={"100%"}
                            name="articleTypeId"
                            isColor={false}
                            isDisabled={false}
                            isError={state?.errors?.articleTypeId || false}
                            helperText={`Article type is required.`}
                            defaultValue={articleTypeVal}
                            options={articleTypes}
                            handleChange={(e: any) => {
                                setarticleTypeVal(e)
                                if(state?.errors?.articleTypeId){
                                    unsetErr("articleTypeId")
                                }
                            }}
                            isMulti={false}
                            customClassNames="sml-txt-dropdown new-service"
                        />
                    </Grid>
                    <AddContactAid colVal={4} hideWebForm={true} stateVal={state} customDispatch={customDispatch} initValue={editVal} />
                    <Grid item xs={12}>
                        <TextBoxLatest
                            multiline={true}
                            placeholder="Enter Keywords"
                            title={"Keywords"}
                            onchangeHandler={() => { unsetErr("keywords") }}
                            ref={keywordRef}
                            isMandotary={false}
                            errorMsg={`Please Enter`}
                            isError={false}
                            name='keywords'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomFormLabel
                            labelName={"Article Body"}
                            isMandotary={true}
                        />
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            setOptions={{
                                showPathLabel: false,
                                minHeight: "70vh",
                                placeholder: "Enter your text here!!!",
                                plugins: [
                                    align,
                                    font,
                                    fontColor,
                                    fontSize,
                                    formatBlock,
                                    hiliteColor,
                                    horizontalRule,
                                    lineHeight,
                                    list,
                                    paragraphStyle,
                                    table,
                                    template,
                                    textStyle,
                                    image,
                                    video,
                                    link,
                                    imageGallery
                                ],
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock", "paragraphStyle"],
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript"
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["align", "list", "lineHeight"],
                                    ["table", "horizontalRule",],
                                    ["link", "image", "video"],
                                    ["showBlocks", "codeView"]
                                ],
                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                font: [
                                    "Arial",
                                    "Calibri",
                                    "Comic Sans",
                                    "Courier",
                                    "Garamond",
                                    "Georgia",
                                    "Impact",
                                    "Lucida Console",
                                    "Palatino Linotype",
                                    "Segoe UI",
                                    "Tahoma",
                                    "Times New Roman",
                                    "Trebuchet MS"
                                ],
                                // imageMultipleFile: true,
                            }}
                            // onImageUpload={()=>{
                            //   console.log('upload Progress')
                            // }}
                            onImageUploadBefore={onImageUploadBefore}
                            //imageMultipleFile={true}
                            defaultValue={body}
                            // setContents={values?.body}
                            onChange={(content: string) => {
                                setBody(content)
                                console.log('setBody(content)',content)
                                unsetErr("body")
                            }}
                        />
                        {(state?.errors?.body?.length && state?.errors?.body?.length>0) &&
                            <CustomFormLabel
                                labelName={state?.errors?.body?.[0] || ""}
                                isMandotary={false}
                                classNames='sml-txt txt-danger'
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={`radio-label`} >
                            <Checkbox
                                defaultChecked={editVal?.isPublished || false}
                                inputRef={isPublishedRef}
                            />
                            <span>Published</span>
                        </Typography>
                        <Typography className={`radio-label`} >
                            <Checkbox
                                defaultChecked={editVal?.issspAccess || false}
                                inputRef={isSSPRef}
                            />
                            <span>Available for Self Service</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        
                        <CommonAddAttachment callback={imageUploadCB} />

                        {attachmentList.length > 0 && (
                                <Box className={`row`}>
                                    {attachmentList.map((i: any, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {/* <img src={`${i.filePath}`}  height={50}/> */}
                                                <Box className="col-auto p-3p" key={index}>
                                                    {/* <AttachmentItem
                                                        fileData={i?.src}
                                                        callback={() => { attachmentDeleteHandler(i.id) }}
                                                    />
                                                     */}
                                                    
                                                    {i.fileObj ? 
                                                        <FileView name={i.name} onRemoveHandler={()=>{onRemoveAttachment(index)}} fileExt={i.type} url={i.src}/>
                                                        :
                                                        <FileViewFromBlobUrl url={i.path} onRemoveHandler={()=>{onRemoveAttachment(index)}}/>
                                                    }
                                                </Box>
                                            </React.Fragment>
                                        );
                                    })}
                                </Box>
                            )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="text-center pt-2">
                            <Button onClick={cancelHandler}>
                                Cancel
                            </Button> &nbsp; &nbsp; &nbsp;
                            <Button variant='contained' onClick={onSubmit}>
                                {param?.id ? "Update" : "Submit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

// export default CreateArticle;
const mapStateToProps = (state: IState) => {
    return ({
    })
};
export default connect(mapStateToProps)(CreateArticle);