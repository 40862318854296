import "moment/min/locales";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from "react-redux";
import 'simple-line-icons/css/simple-line-icons.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import { messaging, onMessage, requestPermission } from "./firebase";
import { ContactByTokenAction } from './redux/actions/ContactManagement/Contact';
import { ActionType } from "./redux/actions/dashboard";
import { isLoginAction } from './redux/actions/Login/Login';
import { theme } from "./redux/actions/Theme/Theme";
import { getLoggedAgent } from './redux/actions/userManagement/agents';
import { ErrorFallback } from './util/ErrorBoundary';
import { loadLocales } from "./util/locale";
import { MainPageWrapper } from './util/MainPage';
const packageData = require('../package.json');
console.log(`%cversion------${packageData.version}`, 'color: green; font-size: 50px;');

const App: React.FC = () => {
  const [initIntl, setInitIntl] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      const initLocale = await loadLocales();
      setInitIntl(initLocale)
    }
    fetchData();
    const isDarkmode:any = localStorage.getItem("darkTheme");
    if(isDarkmode){
      dispatch(theme(true));
    }
    const loginValues = localStorage.getItem("loginInfo");
    if (loginValues !== null) {
      dispatch(isLoginAction(true));
    }
    requestPermission();
    // Handle incoming messages
    onMessage(messaging, (payload: any) => {
      console.log("Message received", payload);
      // Handle notification or data payload as needed
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: payload?.data?.title,
          severity: "success",
          open: true,
        },
      });
    });
  },[])
  useEffect(() => {
    let logInfo: any = localStorage.getItem("loginInfo");
    const { isContactLogin,isAdminLogin,isAgentLogin } = JSON.parse(logInfo||"{}");
    if (isContactLogin) {
        dispatch(ContactByTokenAction());
    }else if(isAgentLogin){
          dispatch(getLoggedAgent());
    }
    // console.log=()=>{}
  }, []);
  return (
    <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
              <>{initIntl ?  <MainPageWrapper /> : <>Loading...</>}</>
        </ErrorBoundary>
         
    </>
  );
}

export default App;
