import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);  // Random red value
  const g = Math.floor(Math.random() * 256);  // Random green value
  const b = Math.floor(Math.random() * 256);  // Random blue value
  const a = 0.5 + Math.random() * 0.5;  // Random alpha (opacity) value between 0.5 and 1
  return `rgba(${r}, ${g}, ${b}, ${a})`;  // Return rgba color string
};
const getDarkLightModeTheme = (theme:boolean,isDarkMode:boolean) =>{
  return  {
    y: {
      beginAtZero: true,
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid: {
        color: theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    },
    x:{
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid : {
        color : theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    }
  }
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [20,80],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [20,30],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};
const VerticalChart = ({
  id='myReportBar',
  label,
  dataSets=[],
  chartLabel,
  callback,
  isDarkMode = false,
  showLabels = true
}: {
  label: any;
  dataSets: any;
  chartLabel?: string;
  callback?: any;
  id?: any
  isDarkMode?: boolean
  showLabels?: boolean
})=>{
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  const options = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio

    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    //   responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const labels = chart.data.labels;

            return labels.map((label: any, index: any) => {
              const dataset = datasets.find((d: any) => d.data[index] !== undefined);
              const backgroundColor = dataset.data.map(() => getRandomColor())
              const dataValue = dataset?.data[index] || 0;

              return {
                text: `${label} (${dataValue})`,
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          usePointStyle: false, // Use regular legend markers (not point-style)
          color: !isDarkMode ? "black" : "white", // Dynamic color based on mode
          font: {
            size: 14, // Adjust font size based on `showLabels`
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10,      // Custom size for the legend box width (color box)
          boxHeight: 10,     // Custom size for the legend box height
        },
        
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return true;
        }
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true, // Enable the title
        text: chartLabel, // Use the chartLabel prop for the title text
        color: isDarkMode ? 'white' : 'black', // Dynamic color based on mode
        font: {
          size: 18, // Font size for the title
          family: 'Arial', // Font family for the title
        },
        padding: {
          top: 10,
          bottom: 20,
        },
        align: 'center'as const,
      },
    },
    scales: getDarkLightModeTheme(toggleTheme,isDarkMode),
  };
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
    return (
        <Bar
          height={400}
          data={data}
          options={options}
        />
    );
  }
  export default VerticalChart;