import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import arrowIconChild from "../../../../../assest/arrowIconChild.svg";
import { IState } from "../../../../../redux/reducers/rootReducers";
import timeSpanToReadbleFormat from "../../../../../util/timeSpanToReadbleFormat";
import { TicketActions } from "../../../../TicketManagementSystem/Modules/TicketActions";
import { ContactPopover } from "../../../../TicketManagementSystem/Modules/TicketCard";
import { ConvertZ } from "../../../../helpers/helperFunctions";
function createData(val: any) {
  const {
    title,
    ticketStatus,
    ticketType,
    contacts,
    group,
    agent,
    openDateTime,
    dueDateTime,
    firstResponseDueDateTime,
    dueDateTimeFormatted,
    priority,
    secondaryContacts,
    description="",
    slaRemaining,
  } = val.ticketLogs;
  return {
    id: val.id,
    description,
    status: ticketStatus?.displayName,
    title,
    ticketType: ticketType?.displayName,
    contact: contacts?.name || "-",
    source: val?.sourceCode,
    group: group?.name || "-",
    agent: agent?.firstName,
    secondaryContacts : secondaryContacts || {},
    // openedDate: ConvertZ(openDateTime),
    // nextActionDate: ConvertZ(firstResponseDueDateTime),
    dueDate: dueDateTimeFormatted!=null ? dueDateTimeFormatted :"----",
    priority: priority?.name,
    color: priority?.color ? priority?.color : "#5552",
    slaRemaining: dueDateTime ? timeSpanToReadbleFormat(slaRemaining, "ss") : "No SLA",
    info: val,
    ticketRelationshipList: val.ticketRelationshipList
  };
}
const TableView = memo(() => {
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  const [rows, setRows] = useState<any>([]);
  const [isShow, setIsShow] = useState(false);
  const [columns, setColumns] = useState<any>([
    "ID",
    "Contact",
    "Actions",
    "Subject",
    "Status",
    "Ticket Type",
    "Group",
    "Agent",
    // "Next Action Date",
    "Due Date",
    "Priority",
    "SLA",
  ]); //"Open Date","Source",
  useEffect(() => {
    if (AllTickets?.map) {
      setRows([
        ...AllTickets.map((i: any) => {
          return createData(i);
        }),
      ]);
    }
  }, [AllTickets]);
  useEffect(() => {
    console.log("rowsrows_rows", rows);
  }, [rows]);

  
  const layout = useMemo(
    () => (
      <>
        <TableBody>
          {rows.map((row: any,index:number) => {
            return (
              <React.Fragment key={index}>
                <TableRowItem item={row} indexVal={index} setIsShow={setIsShow} isShow={isShow} />
                {  row?.ticketRelationshipList?.length > 0 &&
                  row?.ticketRelationshipList?.map((i: any) => {
                    return  <TableRowItem item={createData(i.childTicket)} setIsShow={setIsShow} isShow={isShow} type="child" />;
                  })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </>
    ),
    [rows,isShow]
  );
  const columnsLayout = useMemo(
    () => (
      <>
        <TableHead>
          <TableRow>
            {columns.map((i: string, index: number) => {
              return (
                <TableCell>
                  {index === 0 && (
                    <Box
                      className="colorBox"
                      sx={{ background: "#5552" }}
                    ></Box>
                  )}
                  {i}
                </TableCell>
              );
            })}
            {/* <TableCell align="right">Actions</TableCell> */}
          </TableRow>
        </TableHead>
      </>
    ),
    []
  );
  return (
    <>
      <Box className="list-view-box">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {columnsLayout}
            {layout}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
});

interface TableRowItemProps{
  item: any;
  isShow: any;
  setIsShow: any;
  type?: any;
  indexVal?: number;
}
const TableRowItem = ({ item,isShow,setIsShow,type,indexVal }: TableRowItemProps) => {
  const {
    id,
    title,
    description,
    status,
    color,
    ticketType,
    group,
    agent,
    dueDate,
    priority,
    slaRemaining,
    info,
    secondaryContacts = {}
  } = item;

  // const rolesAccess = useSelector(
  //   (state: IState) => state?.LoginReducer?.roleAgentAccess?.ticketTypeAccess
  // );
  const classes = useStyles();
  // const dispatch = useDispatch();
  const contactInfo = item?.info?.ticketLogs?.contacts || {}; //profilePicturePath
  const SecondaryContactInfo = item?.info?.ticketLogs?.secondaryContacts || {};

  return (
    <>
    <div className={type=="child" ? "childArrow" : "nothing"}>
        <img
        className={type=="child" ? "childArrowImg" : ""}
                            src={arrowIconChild}
                            
                          />
        </div>
      <TableRow className={`table-view-row row-${indexVal}`}>
      {/* <TableCell /> */}
        <TableCell >
          <Box className="colorBox" sx={{ background: color }}></Box>
          {id}
        </TableCell>
        <TableCell>
          <Box className={classes.imgBox}>
            <Box>
              {/* <img src={ContactListImgs?.[`${ticketData?.ticketLogs?.contacts?.profilePictureId}`] ? ContactListImgs?.[`${ticketData?.ticketLogs?.contacts?.profilePictureId}`] : UserIcon} className="cursor-pointer" alt="UserIcon" height={25} width={25} onClick={onPopoverImgClick} />
                            {popover} */}
              <Avatar
                className="sml-avatar"
                sx={{ height: 25, width: 25 }}
                alt={item?.contact || "PP"}
                src={`${contactInfo?.profilePicturePath || ""}`}
              />
              <ContactPopover contactInfo={contactInfo} secContactInfo={SecondaryContactInfo} />
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box className={`${classes.row} ${classes.justifyCenter}`}>
            <Box className="rightAlignedActions">
              <TicketActions type={type} ticketData={info} isShow={isShow} setIsShow={setIsShow} />
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip title={<Typography variant="h5" sx={{fontSize:"1.3em"}}>{`${description}`}</Typography>}>
            <p>{title}</p>
          </Tooltip>
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>{ticketType}</TableCell>
        {/* <TableCell>{contact}</TableCell> */}
        {/* <TableCell>{source}</TableCell> */}
        <TableCell>{group}</TableCell>
        <TableCell>{agent}</TableCell>
        {/* <TableCell>{openedDate}</TableCell> */}
        {/* <TableCell>{nextActionDate}</TableCell> */}
        <TableCell>{dueDate}</TableCell>
        <TableCell>
          <Typography sx={{ color: color ? color : "#000" }}>
            {priority}
          </Typography>
        </TableCell>
        <TableCell>
          {slaRemaining !== "No SLA" ? 
          <b className={`${slaRemaining.includes("-") ? "txt-danger" : "txt-success"}`} >
            {status === "OnHold" ? "On-Hold" : slaRemaining}
            &nbsp; &nbsp;
          </b>
          : <>{slaRemaining}</>}
        </TableCell>
      </TableRow>
    </>
  );
};
const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
    },
    primaryTxt: {
      color: "#1261b4",
    },
    imgBox: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent:"center",
      gap: "6px",
      "& img": {
        borderRadius: "50%",
        objectFit: "cover",
      },
      "& .popover-box": {
        position: "absolute",
        top: "100%",
        left: 0,
        background: "#fff",
        padding: "10px",
        zIndex: 1,
        boxShadow: "0 0 4px 0px #ddd",
        borderRadius: "5px",
        display: "none",
        minWidth:"200px"
      },
      "& div:hover .popover-box": {
        display: "block",
      },
    },
    flxWrap: {
      flexWrap: "wrap",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    justifyCenter: {
      justifyContent: "center",
    },
    cardBadges: {
      background: "#ddd", //data.mode === "light" ? '#ddd' : mode.cardHeader,
      display: "block",
      lineHeight: 1,
      fontSize: "8px !important",
      borderRadius: "9px",
      padding: "2px 5px",
      fontWeight: "800 !important",
      fontFamily: "'DM Sans', sans-serif !important",
      cursor: "pointer",
    },
  })
);
export default TableView;
