import { Box, Button, InputAdornment, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { debounce } from 'lodash';
import React, { createRef, useEffect, useState } from 'react'
import { CategoryAddIcon, CategoryMinusIcon, CloseIcon, DropdownArrow } from '../../common/svgIcons/chartIcon';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import BottomDrawer from './BottomDrawer';
import { AddBox } from '@mui/icons-material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, getCategoryByTypeId } from '../../../redux/actions/SystemManagement/AddCategory';
import { IState } from '../../../redux/reducers/rootReducers';


const CategorySelection = (props:any) => {
    const fieldRef:any  = createRef();
    const {onChange,ticketTypeId=0} = props;
    const [opened,setOpened] = useState(false);
    const [categoryItem,setCategoryItem] = useState<any>(null)
    const [value,setValue] = useState<any>(null);
    const searchRef: any = createRef();
    const dispatch = useDispatch();
    const allCategories = useSelector((state: IState) => state?.categoryReducer?.Data?.data);
    const onChangeHandler = debounce((i:any) =>{
        console.log("CategorySelection",i)
        setValue(i)
        setOpened(false)
    },100)
    const drawerCallback = () =>{
        setOpened(false)
    }
    const clearValue = ()=>{
        onChangeHandler(null)
    }
    useEffect(()=>{
        if(fieldRef.current){
            fieldRef.current.value = value?.id || "";
        }
        setSearchVal("");
        if(onChange) onChange(value);
    },[value])


    useEffect(()=>{
        if(!allCategories){
            dispatch(getCategory());
        }
    },[])
    
    useEffect(()=>{
        setValue(null)
        // const relatedCategory =  allCategories?.find((i:any)=>i?.categoryTicketTypeList?.[0]?.ticketType?.id === ticketTypeId)
        if(ticketTypeId){
            dispatch(getCategoryByTypeId(ticketTypeId,singleCategoryCB))
        }else{
            setCategoryItem(null)
        }
        // setCategoryItem(relatedCategory);
    },[ticketTypeId])
    const singleCategoryCB = (res:{isSuccess : boolean, data : any}) =>{
        const {isSuccess, data} = res;
        // setLoading(false)
        if(isSuccess){
            setCategoryItem(data)
        }
    }
    const [searchVal,setSearchVal] = useState("")
    const onSearchChangeHandler = debounce((event: any) => {
        setSearchVal(`${event.target.value || ""}`);
    },500)
    const clearField = () =>{
        searchRef.current.value = "";
        setSearchVal("");
    }
    return (
        <>
            <Box className="mobile-dropdown">
                <Typography><span className="label-required">Category <b>*</b></span></Typography>
                <Box className="dropdown-ui">
                    <Typography onClick ={()=>{setOpened(true)}}>
                        {!value?.id && <span className='val'>  Choose Category</span>}
                        {value?.id && <span className='val value'>{value?.name}</span>}
                        <span><DropdownArrow /></span>
                    </Typography>
                </Box>
            </Box>

            
            <Box className="d-none"><TextBoxLatest  ref={fieldRef} name={"categoryId"} /></Box>

            <BottomDrawer
                isOpened ={opened}
                onClose ={drawerCallback}
                title={`Category - ${categoryItem?.name || ""}`}
                rightCornerHandler={clearValue}
                rightCornerText="Clear"
            >
                <>
                <Box>
                    <TextBoxLatest
                        multiline={false}
                        placeholder="Please Enter"
                        title={""}
                        ref={searchRef}
                        onchangeHandler={onSearchChangeHandler}
                        isMandotary={false}
                        errorMsg={``}
                        isError={false}
                        name='search'
                        type="text"
                        parentClassNames="mobile-search-field"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment onClick={clearField} className='icon-holder' position="end">
                                    <CloseIcon />
                                </InputAdornment>
                            ),
                        }}
                    />


                    <Box className='drop-down-options'>
                            {categoryItem?.subCategoryList &&  categoryItem.subCategoryList.map((i:any)=>(
                                <Box key={i.id} className="items-holder"><CategoryTree searchVal={searchVal} selectedId={value?.id||0} onChangeHandler={onChangeHandler} val={i} /></Box>
                            ))}
                        {/* <CategoryTree val={categoryItem} /> */}
                    </Box>
                </Box>
                </>
            </BottomDrawer>
        </>
    )
}


function CategoryTree(props:any){
    const {val,onChangeHandler,customClass="category-level1",selectedId=0,searchVal=""} = props
    const [showMore,setShowMore] = useState(selectedId === val?.id ? true : false);
    const toggleShow = ()=>{
        setShowMore((show)=>!show)
    }
    const hasChild = val?.childrenItemList?.length > 0 || val?.itemList?.length>0;
    return(
        <Box className={`${customClass}`}>
            {JSON.stringify(val).toLowerCase().includes(`${searchVal}`.toLowerCase()) &&
                <Box className='mbl-catrgory-item'>
                    <Typography className="category-name" onClick={()=>{onChangeHandler(val)}} >
                       {selectedId === val?.id  ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}   {val?.name}
                    </Typography>
                    {hasChild && 
                        <Button onClick={toggleShow}>
                            {showMore ? <CategoryMinusIcon/> : <CategoryAddIcon />}
                        </Button>
                    }
                </Box>
            }
            {showMore && 
                <>
                    {val.itemList && val.itemList.map((i:any)=>(
                        <Box key={i.id} className="items-holder"><CategoryTree customClass="category-level2" {...props} val={i} /></Box>
                    ))}
                    {val.childrenItemList  && val.childrenItemList.map((i:any)=>(
                        <Box key={i.id} className="items-holder"><CategoryTree customClass="category-level3" {...props} val={i} /></Box>
                    ))}
                </>
            }
        </Box>
    )

}


export default CategorySelection