import axios,{AxiosResponse} from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import intl from "react-intl-universal";
import LanguageData from "../../../configs/LanguageData.json";
import { updateAllResponseList } from "../TicketsManagement/TicketsManagement";
export const ticketSourcesurl = `${configs.BASE_URL}/Source`;
export const url = `${configs.BASE_URL}/TicketStatus`;
export const SspStatusUrl = `user/TicketStatus`;
const deleteUrl = `${configs.BASE_URL}/TicketStatus/permanent/delete/`;
const bulkUpdate = `${configs.BASE_URL}/TicketStatus/status/change?ids=`;
const ticketBaseStatusUrl = `${url}/base-status`;
export const storeAllTicketStatus = (val: any) => ({
  type: 'ticketStatusAdd',
  payload: val,
});


export const ticketStatusAdd = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_TICKECT_STATUS}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getTicketStatus());
      callback(response);
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
};
export const getTicketSources = (callback?:any,url?:string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url ? `${configs.BASE_URL}/${url}` :  ticketSourcesurl);
      dispatch({
        type: "getTicketSources",
        payload: response.data,
      });
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      if(callback) callback(['1', error.response]);
      return console.log(error);
    }
  };
};
export const getTicketBaseStatus = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(ticketBaseStatusUrl);
      dispatch({
        type: "getTicketBaseStatus",
        payload: response.data,
      });
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      if(callback) callback(['1', error.response])
      return console.log(error);
    }
  };
};

export const getTicketStatus = (callback?:any, contactUrl?:string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(contactUrl ? `${configs.BASE_URL}/${contactUrl}` : url);
      dispatch({
        type: "getTicketStatus",
        payload: contactUrl ? response.data : response,
      });



      const temp:any = {
        ticketTaskStatus : [],
        ticketStatus : []
      }
      response.data.map((i:any)=>{
        if(!i.isActive) return;
        if(i.type === 'TicketStatus'){
          temp.ticketStatus.push({...i,label:i.displayName,value:i.id})
        }else {
          temp.ticketTaskStatus.push({...i,label:i.displayName,value:i.id})
        }
      })
      dispatch(updateAllResponseList({
        "ticketTaskStatus" : temp.ticketTaskStatus
      }))
      dispatch(updateAllResponseList({
        "ticketStatus" : temp.ticketStatus
      }))
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      if(callback) callback(['1', error.response])
      return console.log(error);
    }
  };
};

export const singleTicketStatus = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singleTicketStatus",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

// };
export const updateTicketStatus =  (data: any, id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      let response = await axios.put(`${url}/${id}`, data);
     
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      })
      dispatch(getTicketStatus());
      callback(response);
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
}

export const multipalUpdateTicketStatus = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateTicketStatus",
      });
      dispatch(getTicketStatus());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};


export const softDeleteTicketStatus = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}`);
      dispatch({
        type: "softDeleteTicketStatus",
        payload: response,
      });
      dispatch(getTicketStatus());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_TICKET_STATUS}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deleteTicketStatus = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteTicketStatus",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Ticket Status Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(getTicketStatus());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default ticketStatusAdd;