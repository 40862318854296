import { Box, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tableIcons } from "../../pages/new-ticket/AddAsset";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import { IState } from "../../redux/reducers/rootReducers";
import timeSpanToReadbleFormat from "../../util/timeSpanToReadbleFormat";
import { ConvertZ, handleTime } from "../helpers/helperFunctions";
function swapValues<T>(arr: T[], index1: number, index2: number): T[] {
  [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  return arr;
}
const colWidth: any = {
  assetInfo: 400,
  AssetEffort: 200,
  subject: 200,
  description: 300,
  nextActionDescription: 300,
  AssetDownTime: 200,
  createdDateTime: 200,
};
export const columnLabels:{[key : string] : any} = {
  "id" : "Ticket Id",
  "title" : "Subject",
  "description" : "Description",
  "ticketType" : "Ticket Type",
  "contactId" : "Contact Known As",
  "contactEmail" : "Contact's Email",
  "contactJob" : "Contact's Job Title",
  "Org" : "Third party Organisation",
  "contactOrg" : "Contact Organisation",
  "secondaryContactId" : "Secondary Contact",
  "contactDiv" : "Division",
  "contactDep" : "Department",
  "contactLoc" : "Location",
  "createdDateTime" : "Created Date",
  "dueDateTime" : "Due Date",
  "effort" : "Effort",
  "groupId" : "Group",
  "firstResponseDueDateTime" : "First Action Date",
  "nextActionDescription" : "First Action Description",
  "note" : "Note",
  "ownerId" : "Agent",
  "priority" : "Priority",
  "slaRemaining" : "SLA Remaining",
  "source" : "Source",
  "status" : "Status",
  "updatedDateTime" : "Updated Date",
  "assetInfo" : "Assets name",
  "assetTag" : "Assets Tag",
  "assetType" : "Assets Type",
  "AssetDownTime" : "Downtime"
}
export const printColumns = [
  "id~Ticket ID",
  "title~Subject",
  "description~Description",
  "ticketType~Ticket Type",
  "contactId~Contact Known As",
  "contactEmail~Contact's Email",
  "contactJob~Contact's Job Title",
  "Org~Third party Organisation",
  "contactOrg~Contact Organisation",
  "secondaryContactId~Secondary Contact",
  "contactDiv~Division",
  "contactDep~Department",
  "contactLoc~Location",
  "createdDateTime~Created Date",
  "dueDateTime~Due Date",
  "effort~Total effort",
  "firstResponseDueDateTime~First Action Date",
  "groupId~Group",
  "nextActionDescription~First Action Description",
  "note~Note",
  "ownerId~Agent",
  "priority~Priority",
  "slaRemaining~SLA Remaining",
  "source~Source",
  "status~Status",
  "updatedDateTime~Updated Date",
  "assetInfo~Assets name",
  "assetTag~Assets Tag",
  "assetType~Assets Type",
  "AssetDownTime~Downtime",
]; //AssetDownTime,AssetEffort
export default function ReportExportCSV(props: any) {
  const { allTickets, AllInfo } = props;//AllData
  const dispatch = useDispatch();
  const [rows, setRows] = useState([] as any);
  const [column, setColumns] = useState([] as any);
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );

  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const {
      searchVal,
      contacts,
      agents,
      agentsWithGroup,
      statuses,
      priorities,
      ticketTypes,
      sortType,
      rangeVal,
    } = FiltersVal;
    if (
      searchVal === "" &&
      contacts.length === 0 &&
      agents.length === 0 &&
      agentsWithGroup.length === 0 &&
      statuses.length === 0 &&
      priorities.length === 0 &&
      ticketTypes.length === 0 &&
      sortType === "unset" &&
      rangeVal === null
    ) {
      console.log("storeFiltersValue123456", currAgent?.id);
      dispatch(storeFiltersValue([currAgent?.id], "TCKT_AGENTS_VALUE"));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(allTickets).length === 0) return;
    console.log("AllInfoallTickets", AllInfo, allTickets);
    if (allTickets?.length === 0) return;
    console.log("AllContact");
    handleTime.cancel();
    handleTime.setup(() => {
      paintData();
    });
  }, [allTickets]);

  useEffect(() => {
    if (props?.chartRes?.map) {
      const schemaArray = (props.chartRes || []).map(({field,hidden}:{field:string,hidden:boolean}) => {
        return {
          title: columnLabels[field] || field, // Default title
          field,
          hidden,
          cellStyle: {
            width: colWidth[field] || "250px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        };
      });
    
        
      console.log("columnVisibilityModel", schemaArray);
      setColumns(schemaArray)
    } else {
      setColumns([
        {
          title: columnLabels["id"],
          field: "id",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
          render: (rowData: any) => {
            return rowData.id;
          },
        },
        {
          title: columnLabels["title"],
          field: "title",
          hidden: false,
          render: (rowData: any) => {
            return rowData.title.length > 30
              ? rowData.title.substring(0, 30) + "..." // Truncate text after 30 characters
              : rowData.title;
          },
          cellStyle: {
            width: "250px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["description"],
          field: "description",
          hidden: false,
          render: (rowData: any) => {
            return rowData.description.length > 50
              ? rowData.description.substring(0, 50) + "..." // Truncate text after 50 characters
              : rowData.description;
          },
          cellStyle: {
            width: "250px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["ticketType"],
          field: "ticketType",
          hidden: false,
          cellStyle: {
            width: "100px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactId"],
          field: "contactId",
          hidden: false,
          cellStyle: {
            width: "100px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactEmail"],
          field: "contactEmail",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactJob"],
          field: "contactJob",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["secondaryContactId"],
          field: "secondaryContactId",
          hidden: false,
          cellStyle: {
            width: "100px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["Org"],
          field: "Org",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactOrg"],
          field: "contactOrg",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactDiv"],
          field: "contactDiv",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactDep"],
          field: "contactDep",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["contactLoc"],
          field: "contactLoc",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["createdDateTime"],
          field: "createdDateTime",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["dueDateTime"],
          field: "dueDateTime",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["effort"],
          field: "effort",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["firstResponseDueDateTime"],
          field: "firstResponseDueDateTime",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["groupId"],
          field: "groupId",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["nextActionDescription"],
          field: "nextActionDescription",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["note"],
          field: "note",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["ownerId"],
          field: "ownerId",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["priority"],
          field: "priority",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["slaRemaining"],
          field: "slaRemaining",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["source"],
          field: "source",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["status"],
          field: "status",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["updatedDateTime"],
          field: "updatedDateTime",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["assetInfo"],
          field: "assetInfo",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["assetTag"],
          field: "assetTag",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["assetType"],
          field: "assetType",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
        {
          title: columnLabels["AssetDownTime"],
          field: "AssetDownTime",
          hidden: false,
          cellStyle: {
            width: "150px", // Set width for the subject column
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          },
        },
      ]);
    }
  }, [props.chartRes]);

  const paintData = () => {
    try {
      const modifiedArr: any = [];

      allTickets?.map((j: any) => {
        if (!j?.ticketLogs) return;
        const logs = j?.ticketLogs;
        const tempObj: any = {};
        printColumns.map((i: any) => {
          const splitVal: any = i.split("~");
          if (!tempObj[splitVal[0]]) {
            tempObj[splitVal[0]] = "";
          }
          if (splitVal[0] === "ticketType") {
            tempObj[splitVal[0]] = logs?.ticketType?.name || "-";
          } else if (splitVal[0] === "groupId") {
            tempObj[splitVal[0]] = logs?.group?.name || "-";
          } else if (splitVal[0] === "ownerId") {
            tempObj[splitVal[0]] = logs?.agent
              ? `${logs?.agent?.firstName} ${logs?.agent?.lastName}`
              : "-";
          } else if (splitVal[0] === "priority") {
            tempObj[splitVal[0]] = logs?.priority?.name || "-";
          } else if (splitVal[0] === "contactEmail") {
            tempObj[splitVal[0]] = logs?.contacts?.email || "-";
          } else if (splitVal[0] === "contactEmail") {
            tempObj[splitVal[0]] = logs?.contacts?.email || "-";
          } else if (splitVal[0] === "secondaryContactId") {
            tempObj[splitVal[0]] = logs?.secondaryContacts?.name || "-";
          } else if (splitVal[0] === "contactJob") {
            tempObj[splitVal[0]] = logs?.contacts?.jobTitle || "-";
          } else if (splitVal[0] === "contactId") {
            tempObj[splitVal[0]] = logs?.contacts?.name || "-";
          } else if (splitVal[0] === "contactLoc") {
            tempObj[splitVal[0]] = logs?.contacts?.officeLocation || "-";
          } else if (splitVal[0] === "Org") {
            tempObj[splitVal[0]] = logs?.contactOrganisation?.name || "-";
          } else if (splitVal[0] === "contactOrg") {
            tempObj[splitVal[0]] =
              logs?.contacts?.contactOrganisation?.name || "-";
          } else if (splitVal[0] === "contactDep") {
            tempObj[splitVal[0]] = logs?.contacts?.department?.name || "-";
          } else if (splitVal[0] === "contactDiv") {
            tempObj[splitVal[0]] = logs?.contacts?.division?.name || "-";
          } else if (splitVal[0] === "source") {
            tempObj[splitVal[0]] = j?.sourceCode || "-";
          } else if (splitVal[0] === "status") {
            tempObj[splitVal[0]] = logs?.ticketStatus?.displayName || "-";
          } else if (splitVal[0] === "updatedDateTime") {
            tempObj[splitVal[0]] = ConvertZ(j?.createdDateTime) || "-";
          } else if (splitVal[0] === "effort") {
            tempObj[splitVal[0]] = j?.totalEffort || "-";
          } else if (splitVal[0] === "AssetDownTime") {
            tempObj[splitVal[0]] = j?.totalDowntime || "-";
          } else if (splitVal[0] === "createdDateTime") {
            tempObj[splitVal[0]] = ConvertZ(j?.ticketLogs?.openDateTime) || "-";
          } else if (splitVal[0] === "firstResponseDueDateTime") {
            tempObj[splitVal[0]] =
              ConvertZ(logs?.firstResponseDueDateTime) || "-";
          } else if (splitVal[0] === "dueDateTime") {
            tempObj[splitVal[0]] = ConvertZ(logs?.dueDateTime) || "-";
          } else {
            tempObj[splitVal[0]] = logs[splitVal[0]];
          }
          tempObj["id"] = j.id;
          tempObj["body"] = logs?.body || "-";
          tempObj["description"] = logs?.description || "-";
          tempObj["title"] = logs?.title || "-";
          tempObj["nextActionDescription"] = logs?.nextActionDescription || "-";
          tempObj["note"] = logs?.note || "-";
          tempObj["slaRemaining"] =
            logs?.ticketStatus?.name === "OnHold"
              ? "onHold"
              : timeSpanToReadbleFormat(logs?.slaRemaining) || "-";
        });
        if (j?.ticketAssetList) {
          const assetIds = j?.ticketAssetList?.map((i: any) => {
            return i?.asset?.name;
          });
          const assetTags = j?.ticketAssetList?.map((i: any) => {
            return i?.asset?.assetTag;
          });
          const assettypes = j?.ticketAssetList?.map((i: any) => {
            return i?.asset?.assetType?.displayName;
          });
          tempObj["assetInfo"] =
            assetIds?.length > 0 ? assetIds?.join(" , ") : "-"; //assetIds.length;
          tempObj["assetType"] =
            assettypes?.length > 0 ? assettypes?.join(" , ") : "-"; //assetIds.length;
          tempObj["assetTag"] =
            assetTags?.length > 0 ? assetTags?.join(" , ") : "-"; //assetIds.length;

          // tempObj['AssetEffort'] = getAssetInfo(assetIds).effort.join(' | ');//assetIds.length;
          // tempObj['AssetDownTime'] = getAssetInfo(assetIds).downtime.join(' | ');//assetIds.length;
          // console.log("logs.ticketLogAssetList", AssetInfo, assetIds);
        } else {
          tempObj["assetInfo"] = "-"; //assetIds.length;
        }
        modifiedArr.push(tempObj);
      });
      setRows(modifiedArr);
    } catch (error) {
      console.log("paintData", error);
    }
  };

  const handleToggleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  // Handle column drag event
  const handleColumnDrag = (sourceIndex: any, destinationIndex: any) => {
    // setColumns(
      const newColumns  = swapValues(column,sourceIndex, destinationIndex)
    // )
    

    setColumns(newColumns);

    // const configToSave = newColumns.map((col: any) => ({
    //   field: col.field,
    //   hidden: col.hidden,
    // }));
    // // const configObject = configToSave.reduce((acc:  any, curr: any) => {
    // //   acc[curr.field] = curr.hidden;
    // //   return acc;
    // // }, {});
    props.setColumnVisibilityModel(newColumns);
    // console.log("updatedColumns", newColumns);
  };

  // Handle column visibility toggle
  const toggleColumnVisibility = (field: any,toggle?:boolean,value?:boolean) => {
    const updatedColumns = column.map((col: any) =>{
      if(toggle){
        return { ...col, hidden: value }
      }
      return col.field === field ? { ...col, hidden: !col.hidden } : col
    });
    setColumns(updatedColumns);

    // Save updated configuration
    const configToSave = updatedColumns.map((col: any) => ({
      field: col.field,
      hidden: col.hidden,
    }));
  //   const configObject = configToSave.reduce((acc:  any, curr: any) => {
  //     acc[curr.field] = curr.hidden;
  //     return acc;
  // }, {});
    props.setColumnVisibilityModel(configToSave);

    console.log("updatedColumns", configToSave);
  };

  return (
    <Box className={`p-0`}>
      <div style={{ height: "100%", width: "100%"}}>
        {/* <BasicButton
          type={"button"}
          size="small"
          label={"Manage Columns"}
          variant={"outlined"}
          onClick={handleToggleClick}
        /> */}
        <Popover
          style={{ maxWidth: '320px', height: "500px" }}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box className="text-center pt-1">
            <Button size="small" onClick={() => toggleColumnVisibility(0,true,false)} >Show All</Button> &nbsp;
            <Button size="small" onClick={() => toggleColumnVisibility(0,true,true)} >Hide All</Button>
          </Box>
          <List>
            {column.map((ele: any) => (
              <ListItem key={ele.field} className="fields-list-toggler">
                <ListItemText primary={ele.title}/>
                <ListItemSecondaryAction >
                  <Switch
                    size="small"
                    checked={!ele.hidden}
                    onChange={() => toggleColumnVisibility(ele.field)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Popover>
        <Box className="csv-report-table">
          {(!props?.isViewOnly)&&<Box className="manage-btn">
                  <Button type="button" size="small" onClick={handleToggleClick}>
                      Manage Columns
                  </Button>
                </Box>
          }
          {/* {
            column.map((i:any)=>{
              return <>{i.field} -</>
            })
          } */}
          <MaterialTable
            columns={column}
            title={""}
            data={allTickets?.length > 0 ? rows : []}
            options={{
              exportAllData:true,
              exportButton: true,
              // filtering: true, // Enable column filters
              // search: true, // Enable global search
              draggable: true, // Enable drag-and-drop for reordering
              padding: "dense", // Makes the table more compact
              rowStyle: {
                height: "32px", // Adjust row height for compactness
                fontSize: "14px", // Smaller font size for headers
              },
              headerStyle: {
                fontSize: "12px", // Smaller font size for headers
                padding: "14px", // Reduce padding in the header
              },
              pageSize: 100, // Set number of rows per page
              maxBodyHeight: "calc(100vh - 150px)", // This sets the table body to occupy full available height minus any header/footer
              // tableLayout: "auto",
            }}
            icons={tableIcons}
            onColumnDragged={handleColumnDrag} // Hook into the drag event
          />
        </Box>
      </div>
    </Box>
  );
}
