import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import addSystemIcons, {
  deleteSystemIcons,
  getSystemIcons,
} from "../../../redux/actions/SystemIcons/systemIcons";
import { Box, IconButton, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { createStyles, makeStyles } from "@mui/styles";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import { bulkFileUploadService } from "../../../services/fileUpload/FileUpload";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const ChooseAidImage = (props: any) => {
  const { customDispatch, stateVal, initValue } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [docsList, setDocsList] = useState<any[]>([]);
  const [chosenItem, setChosenItem] = useState<number>(0);
  const [chosenItemPath, setChosenItemPath] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    dispatch(getSystemIcons(getSystemIconsCB));
  }, []);

  const getSystemIconsCB = (resStatus: any, res: any) => {
    console.log("getSystemIconsCB", resStatus, res);
    if (resStatus !== "1") {
      setDocsList(res);
    }
  };
  
  useEffect(() => {
    customDispatch({
      type: "CHANGE_VALUE",
      payload: {
        pictureId: chosenItem,
        pictureIdPath: chosenItemPath,
      },
    });
    unsetErr("systemIconsId");
  }, [chosenItem]);
  {
    /* === Prefil On Edit === */
  }
  useEffect(() => {
    if (!initValue) return;
    setChosenItem(initValue?.systemIconsId || 0);
    setChosenItemPath(initValue?.systemIcons || {});
  }, [initValue]);
  const uploadImages = async (file: any) => {
    const filesList: any = [];
    for (const item in file) {
      if (item !== "length" && item !== "item") {
        filesList.push({
            iconType: "ContactAidIcons",
            fileId: 0,
            filePath: file[`${item}`]?.name
        });
      }
    }
    dispatch(addSystemIcons(filesList,file, getSystemIconsCB));

    console.log("data after uploading", file);

  };
  const fileUploadCallback = (resStatus: string, res: any) => {
    console.log("bulkUploadCB", resStatus, res);
    if (resStatus === "0") {
      const docIds: any = [];
      const docs: any = res.map((i: any) => {
        docIds.push({
          iconType: "ContactAidIcons",
          fileId: i.data.id,
          filePath: i.data.filePath,
        });
        return i.data;
      });
      console.log("docIds", docIds);
      dispatch(addSystemIcons(docIds, getSystemIconsCB));
    }
  };
  
  const deleteIconHandler = async (id: any) => {
    setLoading(true)
    const result: any = await deleteSystemIcons(id);
    if (result) {
      console.log("Icon deleted successfully.");
      dispatch(getSystemIcons(getSystemIconsCB))
      setLoading(false)
    } else {
      console.log("Failed to delete icon.");
    }
   
  };
  const unsetErr = (name: string = "") => {
    //UNSET_ERR
    customDispatch({
      type: "UNSET_ERR",
      payload: name,
    });
  };
  return (
    <div>
      <CustomFormLabel
        labelName={"Select icon for the service"}
        isMandotary={true}
      />
      {stateVal?.errors?.systemIconsId && (
        <CustomFormLabel
          labelName={"Please Choose Icon"}
          isMandotary={false}
          classNames="sml-txt txt-danger"
        />
      )}
      <Box className={`${classes.row} ssp-services-iconslist`}         style={{marginTop:"30px"}}
      >
        {docsList?.map &&
          docsList.map((i: any, key: number) => {
            return (
              <Box
                key={key}
                className={`${i.id === chosenItem ? "checked" : ""}`}
              >
                <Box
                  className={classes.imgHolder}
                  onClick={() => {
                    setChosenItem(i.id);
                    setChosenItemPath(i);
                  }}
                >
                  <img src={i.filePath} height={70} />
                  <IconButton
                          aria-label="delete"
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -20,
                            // backgroundColor: "white",
                          }}
                          onClick={() => {
                            deleteIconHandler(i.id);
                          }}
                        >
  <RemoveCircleIcon sx={{ color: "red", fontSize: "18px" }} /> {/* Adjust the size here */}
  </IconButton>

                </Box>
              </Box>
            );
          })}
        <Box>
          <input
            id="AssetFileUpload"
            accept=".jpeg, .jpg, .png"
            type="file"
            multiple={true}
            onChange={(e: any) => {
              uploadImages(e.target.files);
            }}
            className="d-none"
          />
          <label
            htmlFor="AssetFileUpload"
            className={`${classes.addBtn} cloud-up-load`}
          >
            <Tooltip title="Upload New Icons" className="cloud-img">
              <CloudUploadIcon />
            </Tooltip>
          </label>
        </Box>
      </Box>
      {/* <Box className={classes.dangerTxt}>{showIconErr && <>Choose icon to proceed</>}</Box> */}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
      "& img": {
        background: "#0000",
        boxShadow: "0 0 0 7px #0000",
        transition: ".5s ease-in-out",
        cursor: "pointer",
      },
      "& .checked": {
        "& img": {
          background: "#c5d0f7",
          boxShadow: "0 0 0 7px #c5d0f7",
        },
      },
    },
    imgHolder: {
      position:"relative",
      padding: 10,
      // border:"1px solid #ddd",
      borderRadius: "50%",
      "& img": {
        height: 50,
        width: 50,
        objectFit: "contain",
      },
    },
    blueLabel: {
      fontSize: "16px",
      cursor: "pointer",
      float: "left",
      color: "#0A93FC",
      marginTop: "30px",
    },
    addBtn: {
      color: "#000",
      width: "70px",
      border: "1px dashed #555",
      height: "70px",
      display: "flex",
      fontSize: "30px",
      background: "#fff0",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      cusor: "pointer",
      "& svg": {
        height: 35,
        width: 35,
      },
    },
    dangerTxt: {
      color: "red",
    },
  })
);
export default ChooseAidImage;
