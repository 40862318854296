import { Box, Button, Grid, Typography } from '@mui/material';
import { useRef } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

function PrintArticleWrapper(props: any) {
    const { children,title="",name="" } = props;
    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <Box>
            <Grid className='p-1 white-bg print-article' container spacing={0} alignItems={"center"}>
                <Grid item md={7}>
                    <Typography>
                        {title}
                    </Typography>
                </Grid>
                <Grid item md={5} className="text-right print-article-btn">
              
                      <ReactToPrint
                          trigger={() => <Button className='txtCapitalize' size='small'>{`Print ${name}`}(Landscape)</Button>}
                          content={() => componentRef.current}
                          pageStyle="@page { size: landscape; }"
                          documentTitle={title}
                      />
                      <ReactToPrint
                          trigger={() => <Button className='txtCapitalize' size='small'>{`Print ${name}`}</Button>}
                          content={() => componentRef.current}
                          pageStyle="@page { size: A4; }"
                          documentTitle={title}
                      />
                    
                    
                </Grid>

            </Grid>
            <Box className="pt-1">
              <Box id="printableArea" className="printable-content" ref={componentRef}>
                  {children} 
              </Box>
            </Box>

           


        </Box>
    );
}

export default PrintArticleWrapper;
