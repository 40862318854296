import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import CustomSelect from '../../common/customSelect/CustomSelect';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';

//AddContactAid
function GroupAgent(props: any) {
    const { groups = [], stateVal, customDispatch, initValue, isAgentRequired=true } = props;
    console.log("GroupAgentGroupAgent",props)

    {/* === GroupVals  === */ }
    const [groupVal, setGroupVal] = useState<any>(null);

    {/* === Agent === */ }
    const [agentOptions, setAgentOptions] = useState<any>(null);
    const [agentVal, setAgentVal] = useState<any>(null);

    useEffect(()=>{
        setAgentOptions(groupVal?.agentsList || [])
    },[groupVal])
    useEffect(()=>{
        customDispatch({
            type: "CHANGE_VALUE",
            payload : {
                group : groupVal,
                agent : agentVal,
            }
        })
    },[groupVal,agentVal])


    {/* === Populate on Edit === */}
    {/*=== populate on edit ===*/}
    useEffect(()=>{
        if(!initValue) return;
        console.log('initVali___nitValinitVal',initValue);
        const {agent,groupId} = initValue;
        setAgentVal(agent  || null)
        setGroupVal(groups.filter((i:any)=>i.id === groupId)?.[0]  || null)

    },[initValue]);
    const unsetErr = (name:string="") => {
        //UNSET_ERR
        customDispatch({
            type: "UNSET_ERR",
            payload : name
        })
    }
    return (
        <>
            <Grid item md={6} xs={12}>
                <CustomFormLabel
                    labelName={"Select Group"}
                    isMandotary={true}
                />
                <CustomSelect
                    isOpen={false}
                    placeholder="Select Group"
                    width={"100%"}
                    name="groupId"
                    isColor={false}
                    isDisabled={false}
                    isError={stateVal?.errors?.groupId ? true : false}
                    helperText={`${stateVal?.errors?.groupId ? stateVal?.errors?.groupId : ""}`}
                    defaultValue={groupVal}
                    options={groups}
                    handleChange={(e: any) => {
                        setGroupVal(e)
                        setAgentVal(null)
                        unsetErr("groupId")
                    }}
                    isMulti={false}
                    customClassNames="sml-txt-dropdown new-service"
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <CustomFormLabel
                    labelName={"Select agent"}
                    isMandotary={false}
                />
                <CustomSelect
                    isOpen={false}
                    placeholder="Select Agent"
                    width={"100%"}
                    name="agentId"
                    isColor={false}
                    isDisabled={false}
                    isError={stateVal?.errors?.agentId ? true : false}
                    helperText={`${stateVal?.errors?.agentId ? stateVal?.errors?.agentId : ""}`}
                    defaultValue={agentVal}
                    options={agentOptions}
                    handleChange={(e: any) => {
                        setAgentVal(e)
                        unsetErr("agentId")
                    }}
                    isMulti={false}
                    customClassNames="sml-txt-dropdown new-service"
                />
            </Grid>
        </>
    )
}
const mapStateToProps = (state: IState) => {
    return ({
        groups: state?.TicketsReducer?.allResponses?.groups,
    })
};

export default connect(mapStateToProps)(GroupAgent);