import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { Box,  Popover, Tooltip, Typography } from "@mui/material";
import { memo, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
const tooltipValue:any = {
    "unset":"Choose Sort Option",
    "&sortprop=DueDateTime&sorttype=Desc" : "Due Date - Descending",
    "&sortprop=DueDateTime&sorttype=Asc" : "Due Date - Ascending",
    "&sortprop=OpenDateTime&sorttype=Desc" : "Open Date - Descending",
    "&sortprop=OpenDateTime&sorttype=Asc" : "Open Date - Ascending",
    "&sortprop=FirstResponseDueDateTime&sorttype=Desc" : "First Action Date - Descending",
    "&sortprop=FirstResponseDueDateTime&sorttype=Asc" : "First Action Date - Ascending",
}
export const SortOption = memo(() => {
    console.log('SearchBox_rerendered', 'SortOption')
    const dispatch = useDispatch();
    const classes = useStyles();
    const SortVal: any = useSelector(
        (state: IState) => state?.filtersTicketManagementReducer?.sortType
    );
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const clickHandler = ( value: string) => {
        dispatch(storeFiltersValue(value, 'TCKT_SORT_VALUE'));
        handleClose();
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Tooltip title={`${tooltipValue?.[`${SortVal}`]}`}>
                <Typography aria-describedby={id} onClick={handleClick} className={`${classes.filterItem} sort-option ${SortVal !== "unset" ? "active" : "" }`}>
                    <SortOutlinedIcon /> Sort  <ArrowDropDownIcon />
                </Typography>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {/* <Typography className={classes.filterTitle} sx={{ p: 1 }}>Choose Ticket Status</Typography> */}
                <Box sx={{ p: 1 }}>
                    <Typography onClick={() => { clickHandler("unset") }} className={`${classes.filterItem} ${SortVal === "unset" ? "active" : "" }`}>
                        <span>Unset</span>
                    </Typography>
                    <Typography className={`${classes.filterItem} ${(SortVal === "&sortprop=DueDateTime&sorttype=Desc" || SortVal === "&sortprop=DueDateTime&sorttype=Asc") ? "active" : "" }`}>
                        <span>Due Date</span> 
                        <span>
                            <ArrowUpwardIcon onClick={() => { clickHandler("&sortprop=DueDateTime&sorttype=Desc") }} className={`${SortVal === "&sortprop=DueDateTime&sorttype=Desc" ? "active" : ""}`}/>
                            <ArrowDownwardIcon onClick={() => { clickHandler("&sortprop=DueDateTime&sorttype=Asc") }} className={`${SortVal === "&sortprop=DueDateTime&sorttype=Asc" ? "active" : ""}`}/>
                        </span>
                    </Typography>
                    <Typography className={`${classes.filterItem} ${(SortVal === "&sortprop=OpenDateTime&sorttype=Desc" || SortVal === "&sortprop=OpenDateTime&sorttype=Asc") ? "active" : "" }`}>
                            <span>Open Date</span> 
                        <span>
                            <ArrowUpwardIcon onClick={() => { clickHandler("&sortprop=OpenDateTime&sorttype=Desc") }} className={`${SortVal === "&sortprop=OpenDateTime&sorttype=Desc" ? "active" : ""}`}/>
                            <ArrowDownwardIcon onClick={() => { clickHandler("&sortprop=OpenDateTime&sorttype=Asc") }} className={`${SortVal === "&sortprop=OpenDateTime&sorttype=Asc" ? "active" : ""}`}/>
                        </span>
                    </Typography>
                    <Typography className={`${classes.filterItem} ${(SortVal === "&sortprop=FirstResponseDueDateTime&sorttype=Desc" || SortVal === "&sortprop=FirstResponseDueDateTime&sorttype=Asc") ? "active" : "" }`}>
                        <span>First Action Date</span> 
                        <span>
                            <ArrowUpwardIcon onClick={() => { clickHandler("&sortprop=FirstResponseDueDateTime&sorttype=Desc") }} className={`${SortVal === "&sortprop=FirstResponseDueDateTime&sorttype=Desc" ? "active" : ""}`}/>
                            <ArrowDownwardIcon onClick={() => { clickHandler("&sortprop=FirstResponseDueDateTime&sorttype=Asc") }} className={`${SortVal === "&sortprop=FirstResponseDueDateTime&sorttype=Asc" ? "active" : ""}`}/>
                        </span>
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})
const useStyles = makeStyles(() => ({
    filterTitle:{
        fontSize:"13px",
        borderBottom:"1px solid #ddd"
    },
    filterItem:{
        fontSize:"11px",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent:"space-between",
        cursor:"default",
        gap: "2px",
        "&.toggler":{
            cursor:"pointer",
            "&.active":{
                color:"#0b0b89 !important",
                background:"#fff0 !important"
            },
        },
        "& svg":{
            width: "18px",
            height: "18px",
            color: "#777",
            cursor:"pointer",
            "&:hover":{
                color:"#007bff"
            },
            "&.active":{
                color:"#007bff !important"
            },
        },
        "&:hover":{
            background:"#2f80ed14"
        },
        "&.active":{
            background:"#2f80ed14",
            color : "#007bff"
        },
    },
    checkedIcon:{
        color:"#007bff !important"
    }
}))
export default SortOption;