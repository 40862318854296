import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCvu5eGbgucwm8_XeVncIqee_qrp0anyT4",
  authDomain: "vizionsys-test.firebaseapp.com",
  projectId: "vizionsys-test",
  storageBucket: "vizionsys-test.firebasestorage.app",
  messagingSenderId: "815110004090",
  appId: "1:815110004090:web:f5d48355a13697f7738ac3",
  measurementId: "G-6TSXEJ3SWL"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


const GenerateToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, { vapidKey: "BJa-xhk5xQgywZa-fTcae4Et9TtZlD4u0X1LQcx_tAf0V-ZVnPmQ5FMthTeB6f0aLGbl8DsMGkNFjCkgYjiDq5o" });
      console.log('Token:', token);
      return token; // Return the token
    } else {
      console.log("Unable to get permission to notify.");
      return null; // Or you can return something else to signify no token
    }
  } catch (error) {
    console.error("Error getting permission:", error);
    return null; // Return null in case of error
  }
};


// Request permission and get token
const requestPermission = async (callBack) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, { vapidKey: "BH-oKbNhXRU6Y6a4Ff0PCgyRxQkrsvMz58DP8khjjruQYIEx9y-SvbwTkHVJ7yOyw3BxUGKeTQzRmY22q9C27fM" });
      console.log('Token:', token);
      callBack(token)
      // Send token to your server for storage
    } else {
      console.log("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("Error getting permission:", error);
  }
};



export {onMessage, messaging, GenerateToken}