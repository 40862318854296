import { Typography } from "@mui/material";
import { addDays, subYears } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getReportByIdForCsv } from "../../redux/actions/Reports/reports";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import { IState } from "../../redux/reducers/rootReducers";
import Breadcrumbs from "../common2/Breadcrumbs";
import { getDateRange } from "../helpers/helperFunctions";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilterCopyForCSV";
import ReportExportCSV from "./ReportCSV";

function ReportCsvView() {
  const dispatch = useDispatch();
  const param: any = useParams();

  const AllData = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses
  );
  const AllInfo = useSelector(
    (state: IState) => state?.TicketsReducer?.alldataStructure
  );
  const AllTicketsLogs = useSelector(
    (state: IState) => state?.TicketsReducerForCSV?.allTickets
  );

 

  const [chartTitle, setChartTitle] = useState("");

  const [columnVisibilityModel, setColumnVisibilityModel] = useState();


console.log('AllTicketdsLogs', AllTicketsLogs)

const getByIdCB = (resStatus: string, res: any) => {
  console.log(
    "checvk check error report black screen",
    res
  );

  if (res) {
    const { columnFilters={}, agents=[]} = JSON.parse(res?.filterValue);
    const tempCharts: any = Object.keys(columnFilters).length >0 ? columnFilters : undefined;
    const tempChartsAgents: any = agents;
    setChartTitle(res?.name);
    setColumnVisibilityModel(tempCharts)
    dispatch(
      storeFiltersValue(
        tempChartsAgents,
        "TCKT_AGENTS_VALUE_CSV"
      )
    );

    let temp: any = JSON.parse(res?.filterValue);

    if (temp.rangeVal.key !== "selection") {
      const dateRange = getDateRange(temp.rangeVal.key);
      console.log("dateRange dateRange", dateRange);

      if (dateRange) {
        console.log("date check", new Date(dateRange.endDate).toISOString());

        let rangeState = {
          startDate: new Date(dateRange.startDate),
          endDate: new Date(dateRange.endDate),
          key: temp.rangeVal.key,
          node: temp.rangeVal.node,
        };

        console.log("date check", rangeState, temp);

        dispatch(storeFiltersValue(rangeState, "TCKT_RANGE_VALUE_CSV"));
      }
    } else {
      let rangeState = {
        startDate: new Date(temp?.rangeVal?.startDate),
        endDate: new Date(temp?.rangeVal?.endDate),
        key: temp.rangeVal?.key,
        node: temp.rangeVal?.node,
      };
      console.log("temp daterange--", rangeState);

      dispatch(storeFiltersValue(rangeState, "TCKT_RANGE_VALUE_CSV"));
    }
 
  }
};

useEffect(() => {
  console.log("render render", param.id);
  if (param.id) {
    getReportByIdForCsv(param.id, getByIdCB);
  } else {
    const currentDate = new Date();
    const yesterday = new Date();

    yesterday.setDate(currentDate.getDate() - 1);

    let rangeState = {
      startDate: subYears(new Date(), 1),
      endDate: addDays(new Date(), 0),
      key: "selection",
    };

    dispatch(
      storeFiltersValue(
        { node: "openDateTime", ...rangeState },
        "TCKT_RANGE_VALUE_CSV"
      )
    );
    dispatch(storeFiltersValue([], "TCKT_AGENTS_VALUE_CSV"));
    console.log("profileImg", param.id);
  }
}, [param.id]);

useEffect(()=>{
  console.log("somethingss")
  return ()=>{dispatch({type:"STORE_ALL_TICKETS_FOR_CSV_UNSET",payload:null})}
},[])
  return (
    <div className="CSV-Reports">
      <Breadcrumbs
        data={[
          {
            title: "Reports Management ",
            path: "/ReportsManagement",
          },
          {
            title: "CSV Reports ",
            path: "/all-csv-reports",
          },
        ]}
      />
      <br />
      <CommonAppFilter
          report={true}
          hideAddBtn={true}
          hideViewMode={true}
          hideSort={true}
          hideAgent={false}
          hideSearch={true}
          hideFilter={false}
          hideExtraIcons={true}
          customIconFunctions={
            [
              
            ]
          }
        />
   <br />
     

      <Typography
                      variant="body2"
                      className="li-title"
                      style={{
                        color: "black",
                        marginTop: "-5px",
                        fontSize: "20px",
                        textDecoration: "underline",
                        marginBottom:"15px"
                      }}
                    >
                      Report title - {chartTitle}
                    </Typography>

    
        <>
          <ReportExportCSV
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
            allTickets={AllTicketsLogs}
            AllInfo={AllInfo}
            AllData={AllData}
            chartRes={columnVisibilityModel}
            isViewOnly={true}
          />
          {/* <CsvToTable/> */}
        </>
    
    </div>
  );
}
export default ReportCsvView;
