import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  labels,
  datasets,
  chartLabel,
  showLegend = true, // Enable legend by default
  showLabels = true,
  isDarkMode = false,
}: {
  labels: any;
  datasets: any;
  chartLabel?: string;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const options: any = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    plugins: {
      legend: {
        display: showLegend,
        position: 'left', // Position legend on the left
        labels: {
          generateLabels: (chart: any) => {
            const { datasets, labels } = chart.data;
            const backgroundColors = datasets[0]?.backgroundColor || [];
            return labels.map((label: any, index: any) => {
              const dataValue = datasets[0]?.data[index] || 0; // Get data value
              return {
                text: `${label} (${dataValue})`, // Include data count in legend label
                fillStyle: backgroundColors[index], // Match legend color with chart segment
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic legend text color
          font: {
            size: 14, // Adjust font size
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 12, // Adjust color box width
          boxHeight: 12, // Adjust color box height
        },
      },
      datalabels: {
        display: true,
        labels: {
          value: {
            color: isDarkMode ? "#ffffff" : "#000000", // Dynamic label color
            font: { size: 14 },
            formatter: function (value: any, ctx: any) {
              return value > 0 ? value : ""; // Show value only if greater than 0
            },
          },
        },
      },
      title: {
        display: true,
        text: chartLabel, // Set the title text
        color: isDarkMode ? "white" : "black", // Dynamic title color based on mode
        font: {
          size: 18, // Title font size
          family: "Arial", // Font family for the title
        },
        padding: {
          top: 10,
          bottom: 30,
        },
        align: "center", // Align title in the center
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
