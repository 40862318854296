import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"
import { triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
const url = `${configs.BASE_URL}/Charts`;
const getAllCharts = `${configs.BASE_URL}/Charts?type=Custom`;
const something_went_wrong = 'Something went wrong.!';
const rec_add_success = 'New Report Added Successfully.!';
const urlForPivot = `${configs.BASE_URL}/pivot`;
const getAllCSVCharts = `${configs.BASE_URL}/CsvReport`;
const statusChangeCSV = `${configs.BASE_URL}/CsvReport/status/change?ids=`;


export const showErrMsg = (msg:string, type:string)=>{
  return async (dispatch: any) => {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: msg,
        severity: type,
        open: true,
      },
    });
  }
}

export const getReportsAction = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(getAllCharts);
     dispatch({type: "STORE_REPORTS", payload: response.data})
    if(callback) callback("0",response.data)
    } catch (error:any) {
      console.log(error.response);
        if(callback) callback("1",error.response)
      return error.response;
    }
  };
};

export const getPivotTableAction = async () => {
  try {
      let result = await axios.get(urlForPivot);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}

export const getCSVReportsAction = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(getAllCSVCharts);
     dispatch({type: "STORE_CSV_REPORTS", payload: response.data})
    if(callback) callback("0",response.data)
    } catch (error:any) {
      console.log(error.response);
        if(callback) callback("1",error.response)
      return error.response;
    }
  };
};

export const StatusChangeCSVReport = (ids:string|number,isActive:boolean,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${statusChangeCSV}${ids}&status=${isActive}`);
      dispatch(triggerAppAlert(`Success! CSV report Updated to ${isActive ? "" : "In-"}Active`,"success"))
      if(callback) callback("0",response.data)
    } catch (error:any) {
      console.log(error.response);
      if(callback) callback("1",error.response)
      return error.response;
    }
  };
};

export const getPivotTableActionById = async (id: any) => {
  try {
      let result = await axios.get(`https://simplisyssystemapi.azurewebsites.net/api/Pivot/${id}`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}
export const postPivotTableAction = async (payload: any) => {
  try {
      let result = await axios.post("https://simplisyssystemapi.azurewebsites.net/api/Pivot",payload);
      return true
  } catch (e) {
      console.log(e)
      return false
  }
}

export const putPivotTableAction = async (id: any,payload: any) => {
  try {
      let result = await axios.put(`https://simplisyssystemapi.azurewebsites.net/api/Pivot/${id}`,payload);
      return true
  } catch (e) {
      console.log(e)
      return false
  }
}

export const getReportById = async (id : number|string,callback?:any) => {
    try {
      const response:any = await axios(`${url}/${id}`);
    if(callback) callback("0",response.data)
    } catch (error:any) {
      console.log(error.response);
        if(callback) callback("1",error.response)
      return error.response;
    }
};

export const addEditReportActionForCsv = (data:any,callback:any,id:any) => {
  return async (dispatch: any) => {
    try {
      const response = id ? await axios.put(`${getAllCSVCharts}/${id}`,data) : await axios.post(getAllCSVCharts,data);
      console.log("erro message", data)
      dispatch(showErrMsg(!id ? `${LanguageData.SUCCESS_NEW_REPORT}${LanguageData.TICKET_CREATED}` : `${LanguageData.SUCCESS_REPORT}${LanguageData.TICKET_UPDATED}`,'success'));
      callback(true,response.data)

    } catch (e:any) {
      callback(false,e.response);
        console.log(e.response)
        let errMsg = e?.response?.data && typeof e.response.data === 'string' ? e.response.data : something_went_wrong;
        dispatch(showErrMsg(errMsg,'error'));
    }
  };
};

export const getReportByIdForCsv = async (id : number|string,callback?:any) => {
  try {
    const response:any = await axios(`${getAllCSVCharts}/${id}`);
  if(callback) callback("0",response.data)
  } catch (error:any) {
    console.log(error.response);
      if(callback) callback("1",error.response)
    return error.response;
  }
};

export const addEditReportAction = (data:any,callback:any,id:any) => {
  return async (dispatch: any) => {
    try {
      const response = id ? await axios.put(`${url}/${id}`,data) : await axios.post(url,data);
      console.log("erro message", data)
      dispatch(showErrMsg(!id ? `${LanguageData.SUCCESS_NEW_REPORT}${LanguageData.TICKET_CREATED}` : `${LanguageData.SUCCESS_REPORT}${LanguageData.TICKET_UPDATED}`,'success'));
      callback('0',response.data)

    } catch (e:any) {
      callback('1',e.response);
        console.log(e.response)
        let errMsg = e?.response?.data && typeof e.response.data === 'string' ? e.response.data : something_went_wrong;
        dispatch(showErrMsg(errMsg,'error'));
    }
  };
};




export default getReportsAction;
