const configs = {
    'BASE_URL': process.env.REACT_APP_TEST_API_URL,
    'BASE_URL_Ticket': process.env.REACT_APP_TEST_API_URL,
    //PROD-API- https://api.servodesk.com/gateway
    'BASE_URL_FILE_UPLOAD': process.env.REACT_APP_TEST_API_URL,
    'BASE_URL_VERSIONING':  process.env.REACT_APP_BASE_URL_VERSIONING,
    'SOURCE': "Phone",
    "SECRET_KEY" : process.env.REACT_APP_SERVODESK_APP_KEY 
    // "http://192.168.0.100:5001/gateway"
}
export default configs;
