import { Box, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import { createRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storeFiltersValue } from '../../../redux/actions/TicketsManagement/GetTickets';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { CloseIcon } from '../../common/svgIcons/chartIcon';
import AddUpdateOption from '../MobileUtilities/AddUpdateOption';
import ContactViewOption from '../MobileUtilities/ContactView';
import MobileFilter from '../MobileUtilities/MobileFilter';
import NavBar from '../MobileUtilities/NavBar';
import SearchResultTickets from './SearchResultTickets';
function SearchResultsMobile() {
    // const result:any = {};
    const dispatch = useDispatch(); 
    const searchRef: any = createRef();
    const onSearchChangeHandler = debounce((event: any) => {
            dispatch(storeFiltersValue(`${event.target.value || ""}`, 'TCKT_MOBILE_SEARCH_VALUE'));
    },500)
    useEffect(()=>{
       if(searchRef.current) {
        searchRef.current.focus();
       }
       return ()=>{clearField()}
    },[])
    const clearField = () =>{
        dispatch(storeFiltersValue(``, 'TCKT_MOBILE_SEARCH_VALUE'));
        searchRef.current.value = "";
    }
    return (
        <Box>
            {/* {result.d.dd} */}
            <NavBar
                backIcon={true}
                toggler={false}
            />
            <MobileFilter />
            <AddUpdateOption />
            <ContactViewOption />
            <Box className="p-10p">
                <TextBoxLatest
                    multiline={false}
                    placeholder="Please Enter"
                    title={""}
                    ref={searchRef}
                    onchangeHandler={onSearchChangeHandler}
                    isMandotary={false}
                    errorMsg={``}
                    isError={false}
                    name='search'
                    type="text"
                    parentClassNames="mobile-search-field"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={clearField} className='icon-holder' position="end">
                                <CloseIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Box>
                    <SearchResultTickets />
                </Box>
            </Box>
        </Box>
    )
}

export default SearchResultsMobile