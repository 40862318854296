export enum ActionType {
  SNACKBAR = "SNACKBAR",
  API_STATUS = "API_STATUS",
}

export type Color = "success" | "info" | "warning" | "error";

export interface ISnackbar {
  message: string;
  severity: Color;
  open: boolean;
}

export interface ISnackbarType {
  type: ActionType.SNACKBAR;
  payload: ISnackbar;
}

export const snackbar = (snackbar: ISnackbar) => {
  return {
    type: ActionType.SNACKBAR,
    payload: snackbar,
  };
};
