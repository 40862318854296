import {
  Box,
  Container,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoImg from "./../../assest/logo/logo.svg";

export const LoginWrap = (props:any)=> {
  const { title } = props;
  return (
    <>
      <Box className="LoginWrap" >
        <Container maxWidth="lg" className="LoginContainer">
          <Box component="div">
            <Box className="LoginTopMenu">
              <Box>
                <Link to="/"><img className="pointer" src={LogoImg}  alt="" height={70} /></Link>
              </Box>
            </Box>
          </Box>

          <Box className="loginForm">
            <Box className="formHolder">
              <Box>
                <Typography variant="h5" gutterBottom>{title}</Typography>
              </Box>
              {props?.children}
            </Box>
          </Box>
          <Box>
            {/* <br/><br/><br/><br/><br/> */}
            </Box>
        </Container>
      </Box>
    </>
  );
}
export default LoginWrap;