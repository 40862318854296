import { Box } from '@mui/material'
import React, { useState } from 'react'
import CustomFormLabel from '../../common/form-label/CustomFormLabel '
import CustomInput from '../../common/textField/CustomInput';
import CustomDialog from '../../common/dialog/CustomDialog';
import BasicButton from '../../common/button/BasicButton';
import CustomTree from '../../common/custom-tree/CustomTree';

function CloseCategoryOption() {
    const [addCategoryPopup, setAddCategoryPopup] = useState(false);
    const [tempCat, setTempCat] = React.useState<any>([
        {id:1, name:"No Further Action"},
        {id:2, name:"Letter of Expectation"},
        {id:3, name:"First Written Warning"},
        {id:4, name:"Final Witten Warning"},
        {id:5, name:"Conduct",
            itemList:[
                {id :51, name :"Notice"},
                {id :52, name :"Gross Misconduct"},
                {id :53, name :"Dismissal"}
            ]
        },
        {id:6, name:"Attendance",
            itemList:[
                {id :61, name :"Stage 1 - Attendance"},
                {id :62, name :"Stage 2 - Attendance"},
                {id :63, name :"Dismissal"},
                {id :64, name :"No Action- Continue Process"}
            ]
        },
        {id:7, name:"Capabilty",
            itemList:[
                {id :71, name :"Stage 1 - Capability"},
                {id :72, name :"Stage 2 - Capability"},
                {id :73, name :"Dismissal - Capability"},
            ]
        },
        {id:8, name:"SOSR",
            itemList:[
                {id :81, name :"Redeploy"},
                {id :82, name :"Dismissal"},
            ]
        },
        {id:9, name:"Ill Health Capability",
            itemList:[
                {id :91, name :"Dismissal"},
            ]
        },
        {id:10, name:"Grievance",
            itemList:[
                {id :101, name :"Up Held"},
                {id :102, name :"Not Upheld"},
                {id :103, name :"Partially Upheld"},
            ]
        }
    ]);
    const [value, setValue] = React.useState<any>({});
    return (<>
    {window.location.host.split(".")?.[0] === "simplisys" && <>
        <Box>
             <CustomFormLabel labelName={"Outcomes"} isMandotary={true} />
            <CustomInput
                isDisabled={false}
                readOnly={true}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Outcomes"}
                name="closeCategory"
                value={value?.label}
                onClick={() => {
                    setAddCategoryPopup(true);
                }}
                onChange={() => { }}
                helperText={""}
                placeholder={"Click here to select an Category"}
            />
        </Box>
        <CustomDialog
            heading="Outcome"
            className='outcome-popup'
            body={
                <>
                    <div className="category-content" style={{ width: "400px" }}>
                        <CustomTree
                            data={tempCat}
                            defaultValue={value}
                            onSelect={(val) => {
                                setValue(val)
                            }}
                        />
                    </div>
                </>
            }
            open={addCategoryPopup}
            handleClose={() => {
                setAddCategoryPopup(false);
            }}
            footer={
                <>
                    <BasicButton
                        type={"submit"}
                        isLoading={false}
                        isDisabled={false}
                        color="primary"
                        label={"Save"}
                        onClick={() => {
                            setAddCategoryPopup(false);
                        }}
                        size="large"
                        variant={"contained"}
                        endIcon={false}
                    />
                </>
            }
        />
    </>}
    </>
    )
}

export default CloseCategoryOption
