import { Avatar, Box, Divider, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { storeContactView } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { IState } from '../../../redux/reducers/rootReducers';
import BottomDrawer from '../MobileCommon/BottomDrawer';
import { useEffect, useState } from 'react';
import { getContactByIdAction } from '../../../redux/actions/ContactManagement/Contact';
import { ConvertZ } from '../../helpers/helperFunctions';
interface IContactViewOptionProps {
    dispatch?:any;
    contactInfo?:any;
}
function ContactViewOption(props:IContactViewOptionProps) {
    const {dispatch,contactInfo=null} = props;
    const [byId,setById] = useState<any>({});
    useEffect(()=>{
        if(contactInfo?.id){
            console.log("contactInfocontactInfo",contactInfo)
            dispatch(getContactByIdAction(contactInfo?.id,getContactByIdActionCB))
        }
    },[contactInfo?.id])
    const getContactByIdActionCB = (resStatus:string,res:any) =>{
        if(resStatus === "0"){
            setById(res)
        }
    }
    const closeContactViewOption = () =>{
        dispatch(storeContactView(null))
    }
    return (
        <Box>
            <BottomDrawer
                isOpened ={contactInfo ? true : false}
                onClose ={closeContactViewOption}
                title="Contact Info"
            >
                <Box className="p-1">
                    <Box className="contactviewheader">
                        <FallbackAvatar
                            src={`${contactInfo?.profilePicturePath}`}
                            alt={contactInfo?.name || "Unknown Contact"}
                            fallback="UN"
                            height={50}
                            width={50}
                        />
                        <Box>
                            <Typography>{contactInfo?.name || contactInfo?.name || "Unknown Contact" } </Typography>
                            <Typography>{contactInfo?.email}</Typography>
                            <Typography>{contactInfo?.businessPhone}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="pt-1">
                        <KeyValueLabel 
                            label="Organisation :"
                            value={contactInfo?.contactOrganisation?.name}
                        />
                        <KeyValueLabel 
                            label="Division :"
                            value={byId?.division?.name}
                        />
                        <KeyValueLabel 
                            label="Department :"
                            value={byId?.department?.name}
                        />
                        <KeyValueLabel 
                            label="Base Location :"
                            value={byId?.baseLocation?.name}
                        />
                        <KeyValueLabel 
                            label="Office Location :"
                            value={byId?.officeLocation}
                        />

                    <KeyValueLabel label="Email :"  value={contactInfo?.email}/>
                    <KeyValueLabel label="Manager :"  value={byId?.manager?.name}/>
                    <KeyValueLabel label="Job Title :"  value={byId?.jobTitle}/>
                    <KeyValueLabel label="Mobile Phone :"  value={byId?.mobilePhone}/>
                    <KeyValueLabel label="Bussiness Phone :"  value={byId?.businessPhone}/>
                    <KeyValueLabel label="WhatsApp Number :"  value={byId?.whatsAppNumber}/>
                    <KeyValueLabel label="Teams Id :"  value={byId?.teamsId}/>
                    <KeyValueLabel label="Employee Hire Date :"  value={byId?.employeeHireDate ? ConvertZ(byId?.employeeHireDate, "L") : "-"}/>
                    <KeyValueLabel label="External Employee Id :"  value={byId?.externalEmployeeId}/>
                    <KeyValueLabel label="Age Group :"  value={byId?.ageGroup}/>
                    <KeyValueLabel label="Consent Provided For Minor :"  value={byId?.consentProvidedForMinor}/>
                    <KeyValueLabel label="Legal Age Group Classification :"  value={byId?.legalAgeGroupClassification}/>
                    <KeyValueLabel label="Self Service Access :"  value={byId?.isSelfServiceAccess ? "Yes" : "No"}/>
                    <KeyValueLabel label="Single Sign On Enabled :"  value={byId?.isSingleSignOnEnabled ? "Yes" : "No"}/>
                    </Box>
                </Box>
            </BottomDrawer>
        </Box>
    )
}

export const KeyValueLabel = ({label="",value="-"}:any) =>{
    return(<Box className="KeyValueLabel">
        <Typography component={"span"}>{label}</Typography>
        <Typography>{value || "-"}</Typography>
    </Box>)
}



export const FallbackAvatar = ({ src, alt, fallback,width=30,height=30, parentClassName="" }:any) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleError = () => {
    setImageSrc(fallback);
  };
  useEffect(()=>{
    setImageSrc(src)
  },[src])

  return (
    <Avatar
      alt={alt}
      src={imageSrc}
      onError={handleError}
      sx={{width:width,height:height, fontSize:"12px"}}
      className={`${parentClassName}`}
    >
      {fallback && !imageSrc ? fallback.charAt(0).toUpperCase() : null}
      </Avatar>
  );
};



const mapStateToProps = (state:IState) => {
    return ({
        contactInfo: state?.AllTicketsReducer?.contactInfo,
    })
  };
  
export default connect(mapStateToProps)(ContactViewOption);
// export default ContactViewOption