import axios from "axios";
import configs from "../../../configs/config";
import LanguageData from "../../../configs/LanguageData.json";
import { requestPermission } from "../../../firebase";
import { DoLogOutAction, getTenantBySubDomain, isLoginAction } from "../../../redux/actions/Login/Login";
import { triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import { ActionType } from "../Snackbars";
import { storeFiltersValue } from "../TicketsManagement/GetTickets";
import { getAllDAshboardData } from "../TicketsManagement/TicketsManagement";
import { getUserEditionPermissions } from "./feature";
import { LoggedInUsrRole, getSystemTypes } from "./role";
import { GetOrganisationToken } from "../FreeTrial/FreeTrial";
import { theme } from "../Theme/Theme";
import { getSubDomainName } from "../../../components/helpers/helperFunctions";
export interface IFileUpload {
  _FormFile:File;
  type:string;
  fileupload?: any;
  description:string;
  filetypeid:number;
}
export const url = `${configs.BASE_URL}/Agent`;
export const notificationDeviceIdUrl = `${configs.BASE_URL}/Agent/update/deviceid?deviceid=`;
const deleteUrl = `${configs.BASE_URL}/Agent/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Agent/status/change?id=`;
const AgentWithToken = `${configs.BASE_URL}/Agent/by/token/v2`;
const assignLicenseUrl = `${url}/assign/license?agentId=`;
const changePasswordUrl = `${url}/Change/Password`;
const resetPasswordUrlAdmin = `${url}/reset/password`;
const resetPasswordUrlPreLogin = `${configs.BASE_URL}/UserManagement/password/submit`;
const resetPasswordUrlPreLoginAgent = `${configs.BASE_URL}/Agent/change/accountstatus`;
export const fileManagementUrl = `${configs.BASE_URL_FILE_UPLOAD}/Files/`;
//successCall
export const successCall = 'successCall';
export const saveProfile = 'saveProfile';
export const saveOrgProfile = 'saveOrgProfile';
const LOGIN = 'LOGIN';
export const AgentSuccessCall = (val: any) => ({
  type: successCall,
  payload: val,
});
export const saveProfileImg = (imgType:string,val: any) => ({
  type: imgType === 'profile' ? saveProfile : saveOrgProfile ,
  payload: val,
});



// const addAgent = (data: any) => {
//   return async (dispatch: any) => {
//     try {
//       const response = await axios.post(url, data, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       dispatch({
//         type: "addAgent",
//         payload: response,
//       });
//       dispatch(AgentSuccessCall("0"));
//       dispatch(getAgent());
//       dispatch({
//         type: ActionType.SNACKBAR,
//         payload: {
//           message: "Agent Data Created Successfully",
//           severity: "success",
//           open: true,
//         },
//       });
//     } catch (error:any) {
//       if (error.response) {
//         dispatch({
//           type: ActionType.SNACKBAR,
//           payload: {
//             message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
//             severity: "error",
//             open: true,
//           },
//         });
//         console.log('error=>', error.response.data);
//       }  
//     }
//   };
// };
const addAgent = (data: any, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addAgent",
        payload: response,
      });
      dispatch(AgentSuccessCall("0"));
      // dispatch(getAgent());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_AGENTS}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
    callback('0',response.data)
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
  };
};
export const getProfileImage = (type:string,id:number) => {
  return async (dispatch: any) => {
    try {
      if(id === 0) return; 
      const response = await axios.get(fileManagementUrl+id);
      // response.data.filePath = response.data.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
      // response.data.filePath = "https://simplisysstoragev1.blob.core.windows.net" + response.data.filePath;
      dispatch(saveProfileImg(type,response.data.filePath));
    } catch (error) {
      return console.log("profile image res",error);
    }
  };
};
export const getAttachmentFile = async (id:number) => {
    try {
      const response = await axios(fileManagementUrl+id);
      return response.data.filePath
    } catch (error) {
      return false;
    }
  };

export const getAgent = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      dispatch(loading(false));
      dispatch({
        type: "getAgent",
        payload: response,
      });
    } catch (error) {
      return console.log("grt res",error);
    }
  };
};
export const changeAccountstatus = (payload:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const urlParams = new URLSearchParams(`${window.location.href.split('?')?.[1] || ""}`);
      const token =`${urlParams.get('t')}`.replaceAll(" ","+");
      
      const subDomain = getSubDomainName();
      const tenantRes = await axios(`${getTenantBySubDomain}${subDomain}`);

      //const orgValue = urlParams.get('org');
      const response = await axios.post(`${resetPasswordUrlPreLogin}`,payload,
      {
        headers: {
          "Authorization" : `Bearer ${token}`,
          "tenant-code" : tenantRes?.data?.tenantcode,//orgValue
        },
      });
      if(callback) callback('0',response.data)
    } catch (error:any) {
      if(callback) callback('1',error.data)
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log("errespo",error.response);
    } 
  };
};
export const singleAgent = (id: string,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`);
      try{
        callback('0',response.data)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
    } catch (error:any) {
      try{
        callback('1',error.response)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log("errespo",error.response);
    } 
  };
};

export const getLoggedAgent = (token?: string,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${AgentWithToken}`);
      dispatch({
        type: "LOGGED_IN_AGENT",
        payload: response.data.agent,
      });
      dispatch(saveProfileImg("orgProfile", response.data.organisation.organisationAvatarPath));

      dispatch(GetOrganisationToken(response?.data?.organisation));
      
      requestPermission((token: any)=>{
        let modifiedPayloadWithWebDeviceId = {
          ...response.data.agent,
          webDeviceId: token
        }
        console.log('getLoggedAgent getLoggedAgent',modifiedPayloadWithWebDeviceId, token)
        if(response?.data?.agent?.webDeviceId==token){
        }
        else{
          dispatch(updateAgentForSubscribingNotification(token, response.data.agent?.id, ()=>{}));
        }
      });
      try{
        dispatch(storeFiltersValue([response?.data?.agent?.id], 'TCKT_AGENTS_VALUE'));
        dispatch(storeFiltersValue(JSON.parse(response?.data?.agent?.agentPreferences)?.view || "Status", 'TCKT_LAYOUT_VALUE'));
        dispatch(storeFiltersValue([response?.data?.agent?.id], 'MOBILE_TCKT_AGENTS_VALUE'));
        dispatch(theme(JSON.parse(response?.data?.agent?.agentPreferences)?.theme === "dark-mode" ? true : false));
        dispatch(isLoginAction(true));
        dispatch(getUserEditionPermissions())
        dispatch(saveProfileImg("profile",response?.data?.agent?.agentAvatarPath));
        dispatch(LoggedInUsrRole(response.data.agent.roleId));
        //dispatch(getAllDAshboardData())
        //dispatch(getSystemTypes());
        if(callback) {setTimeout(()=>{
            callback();
          },1000) 
        }
      } catch(e){console.log('getLoggedAgent',e)}
    } catch (error :any) {
      localStorage.clear()
      dispatch(DoLogOutAction())
      return console.log(error.response);
    }
  };
};

// export const updateAgent = (data: any, id: any, callback:any) => {
//     return async (dispatch: any) => {
//     try {
//       const response  = await axios.put(`${url}/${id}`, data)
//       console.log('update log',`${url}/${id}`,data)
//       dispatch({
//         type: "updateAgent",
//       });
//       dispatch({
//         type: ActionType.SNACKBAR,
//         payload: {
//           message: "Agent Updated Successfully",
//           severity: "success",
//           open: true,
//         },
//       });
//       dispatch(AgentSuccessCall("0"));
//       dispatch(getAgent());
//       try{
//         dispatch(callback('0',response.data));
//       }catch(e){}
//   }catch (error :any) {
//     dispatch({
//       type: ActionType.SNACKBAR,
//       payload: {
//         message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
//         severity: "error",
//         open: true,
//       },
//     });
//     dispatch(callback('1',error.response));
//     return console.log(error.response);
//   }
//   };
// };
export const updateAgent = (data: any, id: any, callback:any) => {
  return async (dispatch: any) => {
  try {
    const response  = await axios.put(`${url}/${id}`, data)
    console.log('update log',`${url}/${id}`,data)
    dispatch({
      type: "updateAgent",
    });
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: `${LanguageData.SUCCESS_AGENT}${LanguageData.TICKET_UPDATED}`,
        severity: "success",
        open: true,
      },
    });
    dispatch(AgentSuccessCall("0"));
    // dispatch(getAgent());
    try{
      dispatch(callback('0',response.data));
    }catch(e){}
}catch (error :any) {
  dispatch({
    type: ActionType.SNACKBAR,
    payload: {
      message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
      severity: "error",
      open: true,
    },
  });
  dispatch(callback('1',error.response));
  return console.log(error.response);
}
};
};
export const updateAgentForSubscribingNotification = (data: any, id: any, callback:any) => {
  return async (dispatch: any) => {
  try {
    console.log('check updateAgentForSubscribingNotification',`${url}/${id}`,data)
    const response  = await axios.get(`${notificationDeviceIdUrl}${data}`)
    console.log('update log',`${url}/${id}`,data)
    // dispatch({
    //   type: "updateAgent",
    // });
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: `${LanguageData.NOTIFICATION_PERMISSION_ENABLED}`,
        severity: "success",
        open: true,
      },
    });
    dispatch(AgentSuccessCall("0"));
    // dispatch(getAgent());
    try{
      dispatch(callback('0',response.data));
    }catch(e){}
}catch (error :any) {
  dispatch({
    type: ActionType.SNACKBAR,
    payload: {
      message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
      severity: "error",
      open: true,
    },
  });
  dispatch(callback('1',error.response));
  return console.log(error.response);
}
};
};

// export const multipalUpdateAgent = (ids: any, status:any) => {
//   return function (dispatch: any) {
//     axios.delete(`${changeStatus}${ids}&status=${status}`).then((resp) => {
//       dispatch({
//         type: "multipalUpdateAgent",
//       });
//       dispatch(getAgent());
//       dispatch({
//         type: ActionType.SNACKBAR,
//         payload: {
//           message: "Agents Updated Successfully",
//           severity: "success",
//           open: true,
//         },
//       });
//     });
//   };
// };
export const multipalUpdateAgent = (ids: any, status:any) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_AGENT}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_AGENT}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${changeStatus}${ids}&status=${status}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(getAgent());
      dispatch(triggerAppAlert(statusMsg, 'success'))
    } catch (error: any) {
    }
  };
};

export const softDeleteAgent = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/${id}`);
      dispatch({
        type: "softDeleteAgent",
        payload: response,
      });
      dispatch(getAgent());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_AGENT}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const assignLicenseToAgent = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${assignLicenseUrl}${id}`);
      dispatch(getAgent());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "License Assigned to Agent Successfully",
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const deleteAgent = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteAgent",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_AGENT}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getAgent());
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const changePasswordAction = (id: any,data: any,callback:any) => {//to change logged in user password
  return async (dispatch: any) => {
    try {
      console.log('data',data)
      const response = await axios.post(changePasswordUrl, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_PASSWORD}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(callback('success'));
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong! Try again later.",
            severity: "error",
            open: true,
          },
        });
        dispatch(callback('failure'));
        console.log('error=>', error.response.data);
      }  
    }
  };
};
export const resetPasswordAction = (id: any,data:any,callback:any) => {//to change logged in user password
  return async (dispatch: any) => {
    try {
      console.log('data',data)
      const response = await axios.post(`${resetPasswordUrlAdmin}`, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_PASSWORD}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(callback('success'));
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong! Try again later.",
            severity: "error",
            open: true,
          },
        });
        dispatch(callback('failure'));
        console.log('error=>', error.response.data);
      }  
    }
  };
};
export const resetPassWordPreLogin =(data: any,Org?:any,token?:any,callback?:any)=>{
  return async (dispatch: any) => {
    try {
      const response = await axios.post(resetPasswordUrlPreLogin, data, {
        headers: {
          "Tenant-Code": Org,
          "Authorization": `Bearer ${token}`,
        },
      });
      callback("0", response.data)
    }
    catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
    }

}
export const resetPassWordPreLoginAgent =(data: any,Org?:any,token?:any,callback?:any)=>{
  return async (dispatch: any) => {
    try {
      const response = await axios.post(resetPasswordUrlPreLogin, data, {
        headers: {
          // "Tenant-Code": Org,
          "Authorization": `Bearer ${token}`,
        },
      });
      callback("0", response.data)
    }
    catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong!",
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
    }

}
export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};
export const GetAgentimage=(id:any, callback:any)=>{
  axios.get(fileManagementUrl+id).then((response) => {
    console.log("ghhjgjhghj",response.data.filePath);
    // response.data.filePath = response.data.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
    // response.data.filePath = "https://simplisysstoragev1.blob.core.windows.net" + response.data.filePath;
    callback(response.data.filePath)
  })
}
export const ImportCSV =  (
  fileObejct: IFileUpload
) => {
  return async (dispatch: any) => {
  try {
    // let localFileObj:any = fileObejct;
    const formData:any = new FormData();
    formData.append("_FormFile", fileObejct._FormFile);
    formData._FormFile = fileObejct._FormFile;
    // const res = await axios.post(
    //   importcsv, formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    //console.log(res)
    //dispatch(getContacts());
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: 'Contacts imported successfully!',
        severity: "success",
        open: true,
      },
    });
  } catch (e:any) {
    console.log(e);
    console.log(e.response);
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: e.response.data && typeof e.response.data === "string" ? e.response.data : 'Something went wront, Please try again!',
        severity: "error",
        open: true,
      },
    });
    return console.log(e.response)
  }
}
};
export default addAgent;
