import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getRandomColor } from '../horizontal-chart/VerticalChart';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({
  labels,
  datasets,
  chartLabel,
  ref,
  id,
  showLegend = true, // Show legend by default
  showLabels = true,
  isDarkMode = false,
}: {
  labels: any;
  datasets: any;
  chartLabel?: string;
  ref?: any;
  id?: any;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const options: any = {
    maintainAspectRatio: false,
    cutoutPercentage: 90,
    plugins: {
      legend: {
        display: showLegend,
        position: 'left', // Position legend on the left
        labels: {
          generateLabels: (chart: any) => {
            const { datasets, labels } = chart.data;
            return labels.map((label: any, index: any) => {
              const backgroundColor = datasets[0]?.backgroundColor[index]; // Use chart segment color
              const dataValue = datasets[0]?.data[index] || 0; // Get data value
              return {
                text: `${label} (${dataValue})`, // Include label and value
                fillStyle: backgroundColor, // Match legend color with chart segment
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          color: isDarkMode ? "#ffffff" : "#000000", // Dynamic legend text color
          font: {
            size: 14, // Adjust font size
          },
          padding: 10,
          boxWidth: 12, // Adjust color box size
          boxHeight: 12,
        },
      },
      datalabels: {
        display: true, // Always show values
        color: isDarkMode ? "#ffffff" : "#000000", // Dynamic label color
        font: { size: 14 },
        formatter: (value: any, ctx: any) => {
          return value > 0 ? value : ""; // Show value only if greater than 0
        },
      },
      title: {
        display: true,
        text: chartLabel, // Set chart title
        color: isDarkMode ? "white" : "black",
        font: {
          size: 18,
          family: "Arial",
        },
        padding: {
          top: 10,
          bottom: 30,
        },
        align: "center",
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <Doughnut
      ref={ref}
      data={data}
      options={options}
      id={id}
    />
  );
};

export default DoughnutChart;
