const initialState = {
  Data: [],
  showLoading: false,
  roleData : [],
  allGroups : [],
};
 
const groupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getGroup":
      return {
        ...state,
        Data: action.payload,
      };
    case "STORE_ALL_GROUPS":
        return {
          ...state,
          allGroups: action.payload,
      };
    case 'getByRole' : 
    return {
      ...state,
      roleData: action.payload,
    };
    case "singleGroup":
      return {
        ...state,
        Data: action.payload,
      };
    case "updateGroup":
    case "multipalUpdateGroup":
    case "softDeleteGroup":
      return {
        ...state,
      };
    case "deleteGroup":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default groupReducer;
