import { Avatar, Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { getArticles, getArticlesById } from '../../redux/actions/KnowledgeBase/article';
import Breadcrumbs from "./../common2/Breadcrumbs";
import parse from 'html-react-parser';
import PrintArticleWrapper from './PrintArticleWrapper';
import FileViewFromBlobUrl from '../MobileComponents/MobileCommon/FileView';

function ViewArticle() {
    const param = useParams();
    const dispatch = useDispatch();
    const [articleVal,setArticleVal] = useState<any>(null);
    const [list, setList] = useState([]);
    useEffect(()=>{
        dispatch(getArticles(getCallback))
    },[])
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setList(res.filter((i:any)=>i.isActive));
        }
    }
    useEffect(()=>{
        if(param?.id){
            dispatch(getArticlesById(param?.id,getArticlesByIdCB))
        }
    },[param?.id])
    const getArticlesByIdCB = (resStatus: string, res: any) => {
        if (resStatus === "0"){
            console.log("getArticlesByIdCB", res, resStatus)  
            setArticleVal(res)  
        }
    }
    return (
        <Box className="p-2">
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'Article List ',
                        path: '/ArticlesList'
                    },
                    {
                        title: `View Article`,
                        path: `${param.id ? "/ViewArticle/" + param.id : "/ViewArticle"}`
                    }
                ]
                }
            />
            <Box className="pt-1"></Box> 
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <PrintArticleWrapper title={`# Article ID : ${articleVal?.id}`} name="Article" >
                            <Box className="white-bg article-viewer-box p-2">
                                {articleVal &&<>

                                    <Typography variant="h4">
                                        {articleVal?.name}
                                    </Typography>
                                    <hr/>

                                    {parse(`${articleVal?.body || ""}`)}
                                </>}
                            </Box>
                        </PrintArticleWrapper>
                        {articleVal?.articleAttachmentList?.length > 0 && (
                        <>
                            <hr />
                            <Box className={`white-bg p-2`}>
                                <Typography variant="h6" gutterBottom>
                                    Attachments
                                </Typography>
                                <Box className={`row`}>
                                    {articleVal?.articleAttachmentList?.map((i: any, index: number) => {
                                        return (
                                            <Box className="col-auto p-3p" key={index}>
                                                <FileViewFromBlobUrl url={i.attachmentPath} />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <aside className='aside'>
                            <Box className="white-bg p-2 articles-lists">
                                <Typography variant="h6">
                                    All Articles
                                </Typography>
                                <hr/>
                                {list.map((i:any,index:number)=>{
                                    return <React.Fragment key={index}>
                                        <ArticleThumb key={index} data={i}/>
                                    </React.Fragment>
                                })}
                            </Box>
                        </aside>
                    </Grid>
                </Grid>
        </Box>
    )
}

const ArticleThumb = (props:any) =>{
    const {data} = props;
    const navigate = useNavigate();
    return(<>
        <Grid onClick={()=>{navigate(`/ViewArticle/${data?.id}`)}} container alignItems={"center"} className="pb-1 cursor-pointer" spacing={1}>
            <Grid item xs={3}>
                <Avatar
                    // className="iconAvatar contact-popup-avatar"
                    alt={"Thumb Image"}
                    src={data?.thumbnailIconPath}
                    onClick={() => { }}
                    sx={{height:40,width:40}}
                    // height="50px"
                    // width="50px"
                />
            </Grid>
            <Grid item xs={9}>
                <Typography variant='body2'>
                    {data?.name || "Article Name"}
                </Typography>
            </Grid>
        </Grid>
    </>)
}
export default ViewArticle
