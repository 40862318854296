import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { Box, IconButton, InputBase, Paper, Popover, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CommonStyles from "../../../common/CommonStyles";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
const statusesToHide = ["New", "NewtoMe","Delete"]
export const FilterOptions = memo(({actionType="",selectorKey="filtersTicketManagementReducer"}:{actionType?:string,selectorKey?:string}) =>{
    console.log('rerender1 FilterOptions')
    const classes = useStyles();
    const TicketStatus = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.ticketStatus);
    const TicketTypes = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.TicketTypes);
    const Priorities = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.priorities);
    // const OrgList = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.OrgList);
    //const ticketFilterValues = useSelector((state: IState) => state?.filtersTicketManagementReducer);
    const checkedTicketStatus = useSelector((state:any) => state?.[`${selectorKey}`]?.statuses);
    const checkedPriorities = useSelector((state:any) => state?.[`${selectorKey}`]?.priorities);
    const checkedTicketTypes = useSelector((state:any) => state?.[`${selectorKey}`]?.ticketTypes);
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    },[]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };
    // useEffect(()=>{
    //     console.log('ticketFilterValues',ticketFilterValues,TicketStatus,TicketTypes,Priorities)
    // },[ticketFilterValues,TicketStatus,TicketTypes,Priorities,OrgList])
    const addBtn = useMemo(() => <>
        <Typography aria-describedby={id} onClick={handleClick} className={`${classes.filterItem} ${checkedTicketStatus.length + checkedTicketTypes.length + checkedPriorities.length > 0 ? "active" : ""}`}>
            <FilterAltOutlinedIcon />
            Filter {checkedTicketStatus.length + checkedTicketTypes.length + checkedPriorities.length > 0 && <>
                ({checkedTicketStatus.length + checkedTicketTypes.length + checkedPriorities.length })
            </>}
            <ArrowDropDownIcon />
        </Typography>
    </>, [checkedTicketStatus?.length,checkedTicketTypes?.length,checkedPriorities?.length,classes]);

    const statusList = useMemo(() => <>
    {TicketStatus && TicketStatus.length&&<Box>
        <Typography className={classes.filterTitle} sx={{ p: 1 }}>Choose Ticket Status</Typography>
        <Box sx={{ p: 1 }}>
            <CheckboxList options={TicketStatus.filter((i: any) => !statusesToHide.includes(i.name))} values={checkedTicketStatus} keyVal={`TCKT_STATUS_VALUE${actionType}`} />
        </Box>
    </Box>}</>, [checkedTicketStatus,classes])

    const TicketTypesList = useMemo(() => <>
        {TicketTypes && TicketTypes.length && <Box>
            <Typography className={classes.filterTitle} sx={{ p: 1 }}>Choose Ticket Types</Typography>
            <Box sx={{ p: 1 }}>
                <CheckboxList options={TicketTypes} values={checkedTicketTypes} keyVal={`TCKT_TICKET_TYPE_VALUE${actionType}`} />
            </Box>
        </Box>}</>, [checkedTicketTypes,classes])
    
    
    const PrioritiesList = useMemo(() => <>
        {Priorities && Priorities.length && <Box>
            <Typography className={classes.filterTitle} sx={{ p: 1 }}>Choose Priorities</Typography>
            <Box sx={{ p: 1 }}>
                <CheckboxList options={Priorities} values={checkedPriorities} keyVal={`TCKT_PRIORITIES_VALUE${actionType}`} />
            </Box>
        </Box>}</>, [checkedPriorities])
    return (
        <>
             {addBtn} 
            <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                <Box className={`${classes.row}`}>
                    {statusList}

                    {TicketTypesList}
                    
                    {PrioritiesList}
                    
                </Box>
            </Popover>
        </>
    )
})
const CheckboxList = memo((props:any) =>{
    const classes = useStyles();
    const dispatch = useDispatch();
    const {options,values,keyVal} = props;
    console.log('rerender1 FilterOptions',keyVal)
    const clickHandler = (val: any, action: string) => {
        let tempArr: any = [...values];
        if (action === "add") {
            tempArr.push(val);
        } else {
            tempArr = tempArr.filter((i: any) => i !== val);
        }
        console.log('FiltersVal', tempArr);
        dispatch(storeFiltersValue(tempArr, keyVal));
    }
    return(
        <>
            {options?.map && options.map((i: any, index: number) => {
                const checked = values?.includes(i.id);
                return (
                    <>
                        <Typography key={index} onClick={() => { clickHandler(i.id, checked ? "remove" : "add") }} className={classes.filterItem}>
                            {checked ? <CheckBoxIcon className={`${classes.checkedIcon} checkbox-icon`}/> : <CheckBoxOutlineBlankIcon />} 
                            <span>{i?.displayName ? i?.displayName : i?.name}</span>
                        </Typography>
                    </>
                )
            })}
        </>
    )
});
const useStyles = makeStyles(() => ({
    row:{
        display:"flex"
    },
    filterItem:{
        fontSize:"11px",
        cursor:"pointer",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        "& svg":{
            width: "18px",
            height: "18px",
            color: "#777",
        }
    },
    filterTitle:{
        fontSize:"13px",
        borderBottom:"1px solid #ddd"
    },
    checkedIcon:{
        color:"#007bff !important"
    }
}))
export default FilterOptions;