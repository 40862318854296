import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box } from '@mui/material';
import { useState } from 'react';
import profileImg from "../../../assest/icons/menu/img-Placeholder.png";
import { getFileObjUrl } from '../../helpers/helperFunctions';
function ProfilePictureUploader(props: any) {
    const { callback,path="", classes=""} = props;
    const [filePath,setPath] = useState(path);
    const inputFileHandler = (file: any) => {
        if(!file?.target?.files?.[0]) return;
      const url = getFileObjUrl(file.target.files[0]);
      setPath(url.src);
      callback(url);
    };
    return (
        <Box className={`profileImgBox ${classes}`}>
            <Box>
                <input
                    id="profileImage"
                    accept=".jpeg, .jpg, .png"
                    type="file"
                    multiple={false}
                    value=""
                    onChange={inputFileHandler}
                    //value={FileUploadVal}
                    className="d-none"
                />
                <label htmlFor="profileImage">
                    <span>
                        <CloudUploadOutlinedIcon />
                        Choose Image
                    </span>
                </label>

                {filePath === "" ? 
                    <img src={profileImg} height="70px" width="70px" alt="" /> :
                    <img src={filePath} height="70px" width="70px" alt="" />
                }

            </Box>
        </Box>
    )
}

export default ProfilePictureUploader
