import { Button, Grid } from "@mui/material";
import CustomSelect from "../../common/customSelect/CustomSelect";
import { useEffect, useMemo, useRef, useState } from "react";
import { IPieData } from "./InterfaceWallBoard";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import HorizontalChart from "../../charts/horizontal-chart/HorizontalChart";
import LineChart from "../../charts/line/LineChart";
import DoughnutChart from "../../charts/dougnut/DoughnutChart";
import PieChart from "../../charts/pie/PieChart";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import { useDispatch } from "react-redux";
import CustomModalDialog from "../../common/customDialog";
import CommonStyles from "../../common/CommonStyles";
import HorizontalChartActual from "../../charts/horizontal-chart/HorizontalChartActual";
import { colorPalette } from "../../Theme/Theme";
const priorityName: any = {
    "P1": 409,
    "P2": 88,
    "P3": 60,
    "P4": 9,
};

const AgentData: any = {
    "Agent 1": 0,
    "Agent 2": 3,
    "Agent 3": 0,
    "Agent 4": 1,
}

const ticketStatus: any = {
    "Status 1": 36,
    "Status 2": 159,
    "Status 3": 312,
    "Status 4": 12,
};

const groupData: any = {
    "1st Line": 1,
    "2nd Line": 2,
    "3rd Line": 3,
    "4th Line": 4,
}

const ticketTypes: any = {
    "Type 1": 36,
    "Type 2": 81,
    "Type 3": 65,
    "Type 4": 80,
}
const contacts: any = {
    "Conatct 1": 36,
    "Conatct 2": 81,
    "Conatct 3": 65,
    "Conatct 4": 80,
}
const division: any = {
    "Division 1": 36,
    "Division 2": 81,
    "Division 3": 65,
    "Division 4": 80,
}
const department: any = {
    "Department 1": 36,
    "Department 2": 81,
    "Department 3": 65,
    "Department 4": 80,
}
const organisation: any = {
    "Organisation 1": 36,
    "Organisation 2": 81,
    "Organisation 3": 65,
    "Organisation 4": 80,
}
const renderChart = (type: any, data: any, property: string = "") => {
    const labels = Object.keys(data);
    const values = Object.values(data);
    const chartData: IPieData = {
        labels: labels,
        chartType: type,
        datasets: [
            {
                label: property,
                data: values,
                backgroundColor: colorPalette,
                borderColor: colorPalette,
                borderWidth: 1,
            },
        ],
    };
    return chartData;
}
export const generateChartObj = (chartVal: string, propertyVal: string,isActive?:boolean) => {
    if (chartVal && propertyVal) {
        let newData;
        switch (propertyVal) {
            case "Group":
                newData = groupData;
                break;
            case "Priority":
                newData = priorityName;
                break;
            case "TicketStatus":
                newData = ticketStatus;
                break;
            case "Agent":
                newData = AgentData;
                break;
            case "TicketType":
                newData = ticketTypes;
                break;
            case "Department":
                newData = department;
                break;
            case "ContactOrganisation":
                newData = organisation;
                break;
            case "Division":
                newData = division;
                break;
            case "Contacts":
                newData = contacts;
                break;
            default:
                newData = {};
        }

        const chart: any = renderChart(chartVal, newData, propertyVal);
        return { chartType: chartVal, data: chart, isActive: isActive }
        //chart;
    }
}
const ChatItem = (props: any) => {
    const { editWallboradList = [] } = props
    const dispatch = useDispatch()
    const classes = CommonStyles()
    const [chartList, setChartList] = useState([
        {
            id: 1,
            name: "DoughnutGraph",
            label: "DoughnutGraph",
            value: "DoughnutGraph",
        },
        {
            id: 2,
            name: "VerticalBarGraph",
            label: "VerticalBarGraph",
            value: "VerticalBarGraph",
        },
        {
            id: 3,
            name: "HorizontalBarGraph",
            label: "HorizontalBarGraph",
            value: "HorizontalBarGraph",
        },
        {
            id: 4,
            name: "PieGraph",
            label: "PieGraph",
            value: "PieGraph",
        },
        {
            id: 5,
            name: "LineGraph",
            label: "LineGraph",
            value: "LineGraph",
        },
    ]);

    const [propertyList, setPropertyList] = useState([
        {
            id: 1,
            name: "Priority",
            label: "Priority",
            value: "Priority",
        },
        {
            id: 2,
            name: "TicketStatus",
            label: "TicketStatus",
            value: "TicketStatus",
        },
        {
            id: 3,
            name: "Group",
            label: "Group",
            value: "Group",
        },
        {
            id: 4,
            name: "TicketType",
            label: "TicketType",
            value: "TicketType",
        },
        {
            id: 5,
            name: "Agent",
            label: "Agent",
            value: "Agent",
        },
        {
            id: 6,
            name: "Contacts",
            label: "Contacts",
            value: "Contacts",
        },
        {
            id: 7,
            name: "ContactOrganisation",
            label: "ContactOrganisation",
            value: "ContactOrganisation",
        },
        {
            id: 8,
            name: "Department",
            label: "Department",
            value: "Department",
        },
        {
            id: 9,
            name: "Division",
            label: "Division",
            value: "Division",
        },
    ]);
    const [chartVal, setChartVal] = useState<any>(null);
    const [propertyVal, setPropertyVal] = useState<any>(null)
    const [wallBoardTypeList, setWallBoardTypeList] = useState<any[]>(editWallboradList)
    const [modelOpen, setModelOpen] = useState(false)
    const [editvalueObj, setEditvalueObj] = useState<any>({})
    const [editvalueId, setEditvalueId] = useState<any>({})
    const [chartValEdit, setChartValEdit] = useState<any>({});
    const [propertyValEdit, setPropertyValEdit] = useState<any>({})
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

    useEffect(() => {
        setChartValEdit(chartList.find((ele: any) => ele.name == editvalueObj?.chartType))
        setPropertyValEdit(propertyList.find((ele: any) => ele.name == editvalueObj?.data?.datasets[0]?.label))
        setEditvalueId(editvalueObj.id)
    }, [editvalueObj])
    useEffect(() => {
        const isDarkTheme = localStorage.getItem('darkTheme') === 'true';
        if (isDarkTheme) {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
        
    }, [localStorage.getItem('darkTheme')])
    
    const getChartHandler = () => {
        const newVal = generateChartObj(chartVal.name, propertyVal.name, true)
        setWallBoardTypeList((prev) => [
            ...prev,
            newVal
        ])
    };
    const getChartHandlerForEdit = () => {
        if (chartValEdit.name && propertyValEdit.name) {
            const newVal = generateChartObj(chartValEdit.name, propertyValEdit.name,true)
            setEditvalueObj({ ...newVal, id: editvalueId });
        }
    };

    const handleRemove = (index: any) => {
        setWallBoardTypeList((prev) => prev.filter((item, i) => i !== index));
    };

    const handleEdit = (index: any, chartComponent: any) => {
        setModelOpen(true)
        setEditvalueObj(chartComponent)
    };

    const handleClickClose = () => {
        setModelOpen(false)
    };
    const pushResponse = (obj: any) => {
        console.log("objobjobjobj", obj, wallBoardTypeList);
    
        setWallBoardTypeList((prev: any[]) => {
            const elementExists = prev.some((ele: any) => ele.id === obj.id)
            if (elementExists) {
                return prev.map((ele: any) => 
                    ele.id === obj.id ? obj : ele
                );
            }
            return [...prev, obj];
        });
        setModelOpen(false)
    };

    const ChartItem = useMemo(() => {
        const activeItems = wallBoardTypeList.filter(chartComponent => chartComponent?.isActive);
        return activeItems.map((chartComponent, index) => {
            if (!chartComponent?.isActive) {
                return null; 
            }
            const getColNum=()=>{
                if(activeItems.length===1){
                    return 12
                }else if(activeItems.length === 2 || activeItems.length===  4){
                    return 6
                }else if(activeItems.length=== 3 || activeItems.length=== 6){
                    return 4
                }else if(activeItems.length===5 ||activeItems.length===  7 ||activeItems.length===  8 ||activeItems.length===  9){
                    return 4
                }else{
                    return 12
                }
            }
            switch (chartComponent?.chartType) {
                case "VerticalBarGraph":
                    return (
                        <Grid item md={getColNum()} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <div style={{ transform: "rotate(-45deg)", position: "absolute", top: "175px", left: "90px", zIndex: "1", color: "rgb(0 0 0 / 30%)", fontSize: "24px" }}>Demo Data Demo Data Demo Data</div>
                            <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                                <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "HorizontalBarGraph":
                    return (
                        <Grid item md={getColNum()} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <div style={{ transform: "rotate(-45deg)", position: "absolute", top: "175px", left: "90px", zIndex: "1", color: "rgb(0 0 0 / 30%)", fontSize: "24px" }}>Demo Data Demo Data Demo Data</div>
                            <HorizontalChartActual label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "LineGraph":
                    return (
                        <Grid item md={getColNum()} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <div style={{ transform: "rotate(-45deg)", position: "absolute", top: "175px", left: "90px", zIndex: "1", color: "rgb(0 0 0 / 30%)", fontSize: "24px" }}>Demo Data Demo Data Demo Data</div>
                            <LineChart datasets={chartComponent?.data?.datasets || []} labels={chartComponent?.data?.labels} isDarkMode={isDarkMode}/>
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "PieGraph":
                    return (
                        <Grid item md={getColNum()} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <div style={{ transform: "rotate(-45deg)", position: "absolute", top: "175px", left: "60px", zIndex: "1", color: "rgb(0 0 0 / 30%)", fontSize: "24px" }}>Demo Data Demo Data Demo Data</div>
                            <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                case "DoughnutGraph":
                    return (
                        <Grid item md={getColNum()} xs={12} key={index} height={400} style={{ position: "relative", marginTop: "15px" }}>
                            <div style={{ transform: "rotate(-45deg)", position: "absolute", top: "175px", left: "60px", zIndex: "1", color: "rgb(0 0 0 / 30%)", fontSize: "24px" }}>Demo Data Demo Data Demo Data</div>
                            <DoughnutChart
                                labels={chartComponent?.data?.labels}
                                datasets={chartComponent?.data?.datasets || []}
                                isDarkMode={isDarkMode}
                            />
                            <Button onClick={() => { handleRemove(index) }}>Remove</Button>
                            {editWallboradList.length > 0 &&
                            <Button onClick={() => { handleEdit(index, chartComponent) }}>Edit</Button>
                            }
                        </Grid>
                    );
                default:
                    return (
                        <Grid item md={getColNum()} xs={12} key={index}>
                            <>Choose dropdowns to render chart!</>
                        </Grid>
                    );
            }
        });
    }, [wallBoardTypeList,isDarkMode]);

    const ChartItemForEdit = useMemo(() => {
        const chartComponent = editvalueObj
        switch (chartComponent?.chartType) {
            case "VerticalBarGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                    </Grid>
                );
            case "HorizontalBarGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <HorizontalChartActual label={chartComponent?.data?.labels} dataSets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                    </Grid>
                );
            case "LineGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <LineChart datasets={chartComponent?.data?.datasets || []} labels={chartComponent?.data?.labels} isDarkMode={isDarkMode}/>
                    </Grid>
                );
            case "PieGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                    </Grid>
                );
            case "DoughnutGraph":
                return (
                    <Grid item md={4} xs={12} height={400} style={{ position: "relative", marginTop: "15px" }}>
                        <DoughnutChart labels={chartComponent?.data?.labels} datasets={chartComponent?.data?.datasets || []} isDarkMode={isDarkMode}/>
                    </Grid>
                );
            default:
                return (
                    <Grid item md={4} xs={12} >
                        <>Choose dropdowns to render chart!</>
                    </Grid>
                );
        }
    }, [editvalueObj,isDarkMode]);

    useEffect(() => {
        dispatch({ type: "WALL_BOARD_TYPE_LIST", payload: wallBoardTypeList })
    }, [wallBoardTypeList])

    return (
        <>
            <Grid container spacing={1} alignItems={"end"}>
                {wallBoardTypeList.filter(chartComponent => chartComponent?.isActive).length < 9 &&
                    <>
                        <Grid item md={1.5} xs={6}>
                            <CustomFormLabel labelName={"Chart Type"} isMandotary={true} classNames="sml-txt" />
                            <CustomSelect
                                isOpen={false}
                                placeholder="Select Type"
                                width={"100%"}
                                name="ChartTypeId"
                                isColor={false}
                                isDisabled={false}
                                isError={false}
                                helperText={`Property is required.`}
                                defaultValue={chartVal ? chartVal : null}
                                options={chartList}
                                handleChange={(e: any) => {
                                    setChartVal(e);
                                }}
                                isMulti={false}
                                customClassNames="sml-txt-dropdown new-service"
                            />
                        </Grid>
                        <Grid item md={1.5} xs={6}>
                            <CustomFormLabel labelName={"Property"} isMandotary={true} classNames="sml-txt" />
                            <CustomSelect
                                isOpen={false}
                                placeholder="Select Type"
                                width={"100%"}
                                name="propertyTypeId"
                                isColor={false}
                                isDisabled={false}
                                isError={false}
                                helperText={`Property is required.`}
                                defaultValue={propertyVal ? propertyVal : null}
                                options={propertyList}
                                handleChange={(e: any) => {
                                    setPropertyVal(e);
                                }}
                                isMulti={false}
                                customClassNames="sml-txt-dropdown new-service"
                            />
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Button variant="contained" onClick={getChartHandler} disabled={!chartVal || !propertyVal}>
                                {"Get Chart"}
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                {ChartItem}
            </Grid>
            {modelOpen &&
                <CustomModalDialog
                    title=""
                    type=""
                    id="upgradeModal"
                    isOpen={modelOpen}
                    onClose={handleClickClose}
                    headerContent={
                        <>
                            Edit Chart
                        </>
                    }
                    bodyContent={
                        <>
                            <Grid container spacing={1} alignItems={"end"}>
                                <Grid item md={3} xs={6}>
                                    <CustomFormLabel labelName={"Chart Type"} isMandotary={true} classNames="sml-txt" />
                                    <CustomSelect
                                        isOpen={false}
                                        placeholder="Select Type"
                                        width={"100%"}
                                        name="ChartTypeId"
                                        isColor={false}
                                        isDisabled={false}
                                        isError={false}
                                        helperText={`Property is required`}
                                        defaultValue={chartValEdit ? chartValEdit : null}
                                        options={chartList}
                                        handleChange={(e: any) => {
                                            setChartValEdit(e);
                                        }}
                                        isMulti={false}
                                        customClassNames="sml-txt-dropdown new-service"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <CustomFormLabel labelName={"Property"} isMandotary={true} classNames="sml-txt" />
                                    <CustomSelect
                                        isOpen={false}
                                        placeholder="Select Type"
                                        width={"100%"}
                                        name="propertyTypeId"
                                        isColor={false}
                                        isDisabled={false}
                                        isError={false}
                                        helperText={`Property is required.`}
                                        defaultValue={propertyValEdit ? propertyValEdit : null}
                                        options={propertyList}
                                        handleChange={(e: any) => {
                                            setPropertyValEdit(e);
                                        }}
                                        isMulti={false}
                                        customClassNames="sml-txt-dropdown new-service"
                                    />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                    <Button variant="contained" onClick={getChartHandlerForEdit} >
                                        {"Get Chart"}
                                    </Button>
                                </Grid>
                                <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                                    {ChartItemForEdit}
                                </Grid>
                            </Grid>
                        </>
                    }
                    footerContent={
                        <>
                            <Button onClick={handleClickClose}>Close</Button>
                            <Button
                                onClick={()=>{pushResponse(editvalueObj)}}
                                className={`${classes.btn} ${classes.btnSubmit}`}
                                variant="contained"
                            >
                                Update
                            </Button>
                        </>
                    }
                />
            }
        </>
    );
};
export default ChatItem;
