import { Box } from '@mui/material'
import React from 'react'
import MobileTicketCard from '../../MobileUtilities/MobileTicketCard'
import { useSelector } from 'react-redux';
import { IState } from '../../../../redux/reducers/rootReducers';
import { ITicket } from '../../../../redux/actions/TicketsManagement/TicketsManagement';
import { NoDataImg } from '../../../common/svgIcons/chartIcon';

function TicketList() {
    const allTickets = useSelector((state: IState) => state?.AllTicketsReducer?.otherTickets);
    return (
        <Box>
            {allTickets?.map && allTickets?.map((i:ITicket,index:number)=>{
                return <React.Fragment key={index}><MobileTicketCard ticket={i} /></React.Fragment>
            })}
            {allTickets?.length === 0 && <NoDataImg />}
        </Box>
    )
}

export default TicketList