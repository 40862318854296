import axios from "axios"; 
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"
import { updateAllResponseList } from "../TicketsManagement/TicketsManagement";

export const url = `${configs.BASE_URL}/Group`;
const deleteUrl = `${configs.BASE_URL}/Group/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Group/status/change?ids=`;
const groupsByRole = `${configs.BASE_URL}/Group/by/role/`;
export const storeGroupsList = (val: any) => ({
  type: 'getGroup',
  payload: val,
});
export const getGroupsByRole = (data: any) => ({
  type: 'getByRole',
  payload: data,
});
const addGroup = (data: any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addGroup",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Group Created Successfully",
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
  };
};

export const getGroup = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      dispatch(loading(false));
      dispatch(storeGroupsList(response));
      

      const tempArr:any = [];
      response.data.map((i:any)=>{
        if(!i.isActive) return;
        tempArr.push({...i,value:i.id,label:i.name})
      })
      dispatch(updateAllResponseList({"groups" : tempArr }))
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      if(callback) callback(['1', error.response])
      return console.log(error);
    }
  };
};

export const getGroupByRole = (roleId:number,callback?:any) => {
  return async (dispatch: any) => {
    try {
        const response = await axios(`${groupsByRole}${roleId}`);
        dispatch(getGroupsByRole(response.data));

        const filteredObj:any = [];
        response.data.map((i:any)=>{
            if(i.isActive){
              filteredObj[i.id] = {
                ...i,
                label : i.name,
                value : i.name,
              }
            }
        })
        
        if(callback) callback(Object.values(filteredObj));
    } catch (error:any) {
       console.log(error);
      console.log('groupsByRole',error.data);
    }
  };
};

export const singleGroup = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singleGroup",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const updateGroup = (data: any, id: any,callback:any) => {
  return function (dispatch: any) {
    axios.put(`${url}/${id}`, data).then((resp) => {
      dispatch({
        type: "updateGroup",
      });
      // dispatch(getGroup());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Successfully ! Group Updated",
          severity: "success",
          open: true,
        },
      });
      callback();
    });
  };
};

export const multipalUpdateGroup = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_GROUPS}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_GROUPS}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_GROUPS}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_GROUPS}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${changeStatus}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateSSOConType",
      });
      dispatch(getGroup());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};
// export const multipalUpdateGroup = (data: any, ids: any,status:any) => {
//   return function (dispatch: any) {
//     axios.delete(`${changeStatus}${ids}&status=${status}`, data).then((resp) => {
//       dispatch({
//         type: "multipalUpdateGroup",
//       });
//       dispatch(getGroup());
//       if(!status){
//         dispatch({
//           type: ActionType.SNACKBAR,
//           payload: {
//             message: "Success.!.\n Groups updated to In-Active",
//             severity: "success",
//             open: true,
//           },
//         });
//       }else{
//         dispatch({
//           type: ActionType.SNACKBAR,
//           payload: {
//             message: "Success.!.\n Groups updated to Active",
//             severity: "success",
//             open: true,
//           },
//         });
//       }
//     });
//   };
// };

export const softDeleteGroup = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${changeStatus}${id}`);
      dispatch({
        type: "softDeleteGroup",
        payload: response,
      });
      dispatch(getGroup());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Successfully ! Group Moved to In-Active ",
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deleteGroup = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteGroup",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Group Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(getGroup());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default addGroup;