import { Avatar, Divider } from "@mui/material";
import avatarImg from "../../assest/avatar.png";
import { handleReadOrUnread } from "../../services/timeline/timeline.service";
import { getTimeLineById } from "../../components/navbar/sideNavbar/TopNavbar";
import { useDispatch } from "react-redux";
import { ConvertZ } from "../../components/helpers/helperFunctions";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name: string, isRead: boolean) {
  const initials = name.substring(0, 2).toUpperCase(); // Get the first two characters

  return {
    sx: {
      bgcolor: isRead ? "gray" : stringToColor(name),
    },
    children: initials,
  };
}

const handleReadOrUnreadAPICall = async (isReadorNot: any, ticketId: any) => {
  let timelineLogs = await handleReadOrUnread(isReadorNot, ticketId);
  return timelineLogs;
};

const NotificationItem = ({
  title,
  desc,
  time,
  ticketId,
  taskId,
  url,
  isRead,
  notifId,
}: {
  title: string;
  desc: string;
  time: string;
  ticketId: string;
  taskId: string;
  url: string;
  isRead: boolean;
  notifId: any;
}) => {
  const dispatch = useDispatch();

  const onHandleClick = (isReadorNot: any, ticketId: any) => {
    handleReadOrUnreadAPICall(isReadorNot, ticketId).then((res: any) => {
      console.log("all notif", res?.data);
      getTimeLineById(1).then((res: any) => {
        if (res?.data?.length > 0) {
          dispatch({
            type: "STORE_ALL_NOTIF",
            payload: res?.data,
          });
          dispatch({
            type: "STORE_COUNT",
            payload: res?.unreadCount,
          });
        } else {
        }
      });
      // window.scrollTo({
      //   top: -10,
      //   behavior: 'smooth', // Optional: makes the scroll smooth
      // });
    });
  };

  return (
    <>
      <div style={{ padding: "10px 10px 0px 10px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "5px 8px 5px 8px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          className="hover-notification-item"
          onClick={() => {
            const baseUrl = `/#/ticketInfoView/${ticketId}`;
            const urlWithTask = taskId
              ? `${baseUrl}?openTasks=true&taskId=${taskId}`
              : baseUrl;
            window.open(urlWithTask, "_blank");
            onHandleClick(!isRead, notifId);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "flex-start",
            }}
          >
            <Avatar
              src={url ? url : avatarImg}
              style={{ height: "30px", width: "30px", fontSize: "12px" }}
              {...stringAvatar(desc, isRead)}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  color: isRead ? "gray" : "black",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "13px",
                  color: isRead ? "gray" : "black",
                  width: "200px",
                }}
              >
                {desc}
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              fontSize: "12px",
              color: "gray",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                fontSize: "10px",
                color: isRead ? "#9ecf9e" : "",
              }}
            >
              {isRead ? "Read" : ""}
              {isRead ? (
                <>
                  <DoneIcon sx={{ width: "10px", color: "#9ecf9e" }} />
                </>
              ) : (
                <></>
              )}
            </div>
            {ConvertZ(time)}
          </div>
        </div>

        <Divider style={{ marginTop: "10px" }} />
      </div>
    </>
  );
};

export default NotificationItem;
