import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Grid, createMuiTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { useFormik } from "formik";
import React, { FunctionComponent } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BasicButton from "../../components/common/button/BasicButton";
import CustomCreatableSelect from "../../components/common/customSelect/CustomCreate";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import CustomInput from "../../components/common/textField/CustomInput";
import { getContacts } from "../../redux/actions/ContactManagement/Contact";
import { getEmailPreview } from "../../redux/actions/FreeTrial/FreeTrial";
import { ActionType } from "../../redux/actions/Snackbars";
import { getTicketStatus } from "../../redux/actions/SystemManagement/TicketStatus";
import { IState } from "../../redux/reducers/rootReducers";
import LanguageData from "../../configs/LanguageData.json";
import {
  getTicketsById,
  sendEmailAfterTicketCreation,
} from "../../services/dashboard/dashboard.service";
// import { convertToHTML,convertFromHTML } from "draft-convert";
import htmlToDraft from "html-to-draftjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins";
import configs from "../../configs/config";
import { getTimeLogs } from "../../services/timeline/timeline.service";
import { FileUpload } from "../../redux/actions/FileUpload/FileUpload";
import { newFilterAllTickets } from "../../redux/actions/TicketsManagement/TicketsManagement";
import { Language } from "@mui/icons-material";
import AddAttachmentBlobWithPreview from "../../components/EmailTemplates/AddAttachmentBlobWithPreview";
import { fileUploadServiceNew } from "../../services/fileUpload/FileUpload";

const sendEmailAfterTicketCreationFun = async (payload: any) => {
  console.log("payload", payload);
  let newTicket: any = await sendEmailAfterTicketCreation(payload);
  return newTicket;
};
const save = (data: any) => {
  console.log(data);
};

function replaceEscapedQuotes(str: any) {
  return str?.replace(/\\"/g, '"');
}
const customContentStateConverter = (contentState: any) => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map((block: any) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic",
            text: "img",
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });

  const newContentState = contentState.set("blockMap", newBlockMap);
  return newContentState;
};
const getTicketsByIdFunc = async (id: any) => {
  let res = await getTicketsById(id);
  return res;
};
const emptyContentState = JSON.stringify(
  convertToRaw(EditorState.createEmpty().getCurrentContent())
);
const getTimeLineById = async (id: Number, pageCount: Number) => {
  let timelineLogs = await getTimeLogs(id, pageCount);
  return timelineLogs;
};

const CustomHookForFormik = (
  setIsSendEMailPopup: any,
  submittedData: any,
  type: any,
  setEditData: any,
  setData: any,
  source: any,
  setLoading: any,
  attachmentList: any
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const AllInfo = useSelector(
    (state: IState) => state?.TicketsReducer?.alldataStructure
  );

  const formik = useFormik({
    initialValues: {
      from: null,
      to: null,
      Emailsubject: null,
      defaultValueBody: emptyContentState,
      body: null,
      cc: null,
      bcc: null,
      optionsForTo: [],
      optionsForCC: [],
      optionsForBCC: [],
      optionsForSuggestion: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, actions) => {
      console.log("body", values);
      setLoading(true);

      const filteredArrayForTo = values?.to?.filter(
        (item: any) => item.label !== undefined
      );
      const filteredArrayForCC = values?.cc?.filter(
        (item: any) => item.label !== undefined
      );
      const filteredArrayForBCC = values?.bcc?.filter(
        (item: any) => item.label !== undefined
      );

      const StringTO = filteredArrayForTo
        ?.map((item: any) => item.label)
        .join(";");
      const StringCC = filteredArrayForCC
        ?.map((item: any) => item.label)
        .join(";");
      const StringBCC = filteredArrayForBCC
        ?.map((item: any) => item.label)
        .join(";");

      console.log("submittedData", submittedData);

      let sourceTemp = source?.find((x: any) => x.name === "Email");

      let temp: any = ticketId;

      const attachmentPaths = attachmentList?.map((item: any) => {
        return { AttachmentPath: item.attachmentPath };
      });

      let payload = {
        sourceCode: configs.SOURCE,
        TicketLogs: {
          TicketId: type == "add" ? parseInt(submittedData.id) : parseInt(temp),
          name: "string",
          actions: submittedData?.template?.actions,
          companyName: "string",
          from: values.from,
          to: StringTO ? StringTO : null,
          title: values?.Emailsubject,
          body: values.body,
          cc: StringCC ? StringCC : null,
          bcc: StringBCC ? StringBCC : null,
          description: "string",
          roleId: 0,
          sourceCode: configs.SOURCE,
          isActive: true,
          ticketLogAttachmentsList: attachmentPaths,
        },
      };
      sendEmailAfterTicketCreationFun(payload).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          setIsSendEMailPopup(false);
          dispatch({
            type: "SEND_EMAIL_VIEW",
            payload: null,
          });
          if (type == "history") {
            getTimeLineById(Number(ticketId), 0).then((res1: any) => {
              console.log("addfromtimeline", res1);

              if (res1?.ticketLog?.length > 0) {
                setData({
                  logData: res1?.ticketLog,
                  totalEfforts: res1?.totalEfforts,
                });
              } else {
              }
            });
          }
          if (type == "edit") {
            getTicketsByIdFunc(Number(ticketId)).then((res) => {
              console.log("getTicketsByIdFunc", res);
              setEditData(res);
            });
            // navigate(-1)
            // navigate(`/TicketManagement?assignee=${currAgent.id}`);
          }
          if (type == "add" || type == "edit") {
            navigate(`/TicketManagement?assignee=${currAgent.id}`);
            //dispatch(newFilterAllTickets(AllInfo?.statusTypes));
            // navigate("/TicketManagementSys");
          } else {
            // navigate(`/TicketManagement?assignee=${currAgent.id}`);
          }

          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: LanguageData.EMAIL_UPDATED_SUCESSFULLY,
              severity: "success",
              open: true,
            },
          });
        } else {
          console.log("sendEmailAfterTicketCreationFun", res);
          setLoading(false);
          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: "Email sending failed! please try again after sometime",
              severity: "error",
              open: true,
            },
          });
        }
      }).catch((err:any)=>{
        console.log("sendEmailAfterTicketCreationFun_err",err)
      });
    },
  });
  return formik;
};

const validationSchema = yup.object({
  to: yup
    .array()
    .max(1000, "Subject is lengthy(Allowed character length is 10)")
    .required("required"),
  Emailsubject: yup
    .string()
    .max(1000, "Subject is lengthy(Allowed character length is 10)"),
});

type TStaff = {
  job: string;
  name: string;
  color: string;
};

const Staff: FunctionComponent<TStaff> = (props) => {
  return (
    <>
      <ListItemAvatar>
        <Avatar
          style={{
            backgroundColor: props.color,
          }}
        >
          {props.name.substr(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.name} secondary={props.job} />
    </>
  );
};

const SendEmailPopup = ({
  setIsSendEMailPopup,
  isSendEmailPopup,
  submittedData,
  type,
  filterEmailData,
  setEditData,
  setData,
  formData,
}: {
  setIsSendEMailPopup: any;
  isSendEmailPopup: any;
  submittedData: any;
  type: any;
  filterEmailData: any;
  setEditData: any;
  setData?: any;
  formData?: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultTheme = createMuiTheme();
  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 0,
          height: "auto",
          // minHeight:"100px"
        },
        editor: {
          borderBottom: "1px solid gray",
          minHeight: "100px",
          fontSize: "14px",
        },
      },
    },
  });

  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [loading, setLoading] = React.useState<any>(false);

  const currOrganisation = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation // current org
  );
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const ContactList = useSelector(
    (state: IState) => state?.ContactsReducer?.AllContacts
  );
  const agent = useSelector((state: IState) => state.agentReducer?.Data?.data);
  const [attachmentList, setAttachmentList] = React.useState<any>([]);

  const onImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
    const uploadCB = (resStatus: string, res: any) => {
      if (resStatus !== "0") return;
      let { filePath = "", name = "", fileSize = 0 } = res?.data;
      console.log("uploadesRes", res.data);
      const response = {
        result: [
          {
            url: filePath,
            name: name,
            size: fileSize,
          },
        ],
      };
      uploadHandler(response);
    };
    let fileObject = {
      description: "First File",
      filetypeid: 1,
      ExtensionTypeId: 1,
      name: "email-photo",
      formFile: files[0],
    };
    dispatch(
      FileUpload(
        fileObject,
        () => {},
        false,
        uploadCB,
        () => {},
        "quicksendmailattachment"
      )
    );
  };

  const formik = CustomHookForFormik(
    setIsSendEMailPopup,
    submittedData,
    type,
    setEditData,
    setData,
    formData?.source,
    setLoading,
    attachmentList
  );

  const getPreview = async () => {
    const s = await getEmailPreview(1);
    return s;
  };

  React.useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getContacts());
  }, []);

  const parseFun = (data: any, key: any) => {
    if (data) {
      return data?.replace(
        /{{(?!Sur_)(.+?)}}/g,
        (match: any, cap1: any) => key[cap1]
      );
    } else {
      return "";
    }
  };

  const parseFunForSubject = (
    data: string,
    key: Record<string, any>
  ): string => {
    if (data) {
      return data?.replace(/{{(.+?)}}/g, (match: string, cap1: string) => {
        if (cap1 === "ticket_id") {
          return `{${key[cap1]}}`;
        } else {
          return key[cap1];
        }
      });
    } else {
      return "";
    }
  };
  React.useEffect(() => {
    console.log("template submited", submittedData);
    // if (type == "history") {
    //   console.log("submittedDatasubmittedData", submittedData);
    //   const splitCC = submittedData?.circulationListCC?.split(";");
    //   const splitBCC = submittedData?.circulationListBCC?.split(";");
    //   const splitTO = submittedData?.circulationListTO?.split(";");

    //   let temp: any = [];
    //   splitCC?.filter((ele: any) => {
    //     let a: any = {
    //       label: ele,
    //       name: ele,
    //       value: ele,
    //       id: ele,
    //       color: "red",
    //     };
    //     temp.push(a);
    //   });

    //   let temp1: any = [];
    //   splitBCC?.filter((ele: any) => {
    //     let a: any = {
    //       label: ele,
    //       name: ele,
    //       value: ele,
    //       id: ele,
    //       color: "red",
    //     };
    //     temp1.push(a);
    //   });

    //   let temp2: any = [];
    //   splitTO?.filter((ele: any) => {
    //     let a: any = {
    //       label: ele,
    //       name: ele,
    //       value: ele,
    //       id: ele,
    //     };
    //     temp2.push(a);
    //   });

    //   if (submittedData?.circulationListTO) {
    //     formik.setFieldValue("to", temp2);
    //     formik.setFieldValue("optionsForTo", temp);
    //   }
    //   if (submittedData?.circulationListCC) {
    //     formik.setFieldValue("cc", temp1);
    //     formik.setFieldValue("optionsForTo", temp1);
    //   }
    //   if (submittedData?.circulationListBCC) {
    //     formik.setFieldValue("bcc", temp1);
    //     formik.setFieldValue("optionsForTo", temp2);
    //   }
    // }

    if (type == "edit") {
      setAttachmentList(submittedData?.template?.emailTemplateAttachmentList);

      const PrimaryContact = ContactList?.find(
        (x: any) => x.id === submittedData?.ticketLogs?.contactId
      );
      const Agent = agent?.find(
        (x: any) => x.id === submittedData?.ticketLogs?.ownerId
      );
      const Status = formData.status.find(
        (x: any) => x.id === submittedData?.ticketLogs?.status
      );
      const Priority = formData.priority.find(
        (x: any) => x.id === submittedData?.ticketLogs?.priority
      );
      const Group = formData.group.find(
        (x: any) => x.id === submittedData?.ticketLogs?.groupId
      );
      const Owner = formData.agent.find(
        (x: any) => x.id === submittedData?.ticketLogs?.ownerId
      );
      const TicketType = formData.ticketType.find(
        (x: any) => x.id === submittedData?.ticketLogs?.ticketType
      );
      const Category = formData.Category.find(
        (x: any) => x.id === submittedData?.ticketLogs?.category
      );

      const Sla = submittedData?.ticketLogs?.sla
        ? submittedData.ticketLogs.sla
        : null;

      const body = parseFun(submittedData?.body, {
        organisation_name: currOrganisation?.name,
        ticket_id: submittedData?.id,
        note: submittedData?.ticketLogs?.note
          ? submittedData?.ticketLogs?.note
          : 0,
        Title: submittedData?.ticketLogs?.title,
        Description: submittedData?.ticketLogs?.description,
        OpenDateTime: submittedData?.ticketLogs?.openDateTime,
        DueDateTime: submittedData?.ticketLogs?.dueDateTime,
        FirstResponseDueDateTime:
          submittedData?.ticketLogs?.firstResponseDueDateTime,
        NextActionDescription: submittedData?.ticketLogs?.nextActionDescription,
        ScheduleDateTime: submittedData?.ticketLogs?.scheduleDateTime,
        CreatedDateTime: submittedData?.ticketLogs?.createdDateTime,
        Status: Status?.label,
        Priority: Priority?.label,
        Group_Id: Group?.label,
        Owner_Id: Owner?.name,
        TicketType: TicketType?.name,
        Sla: Sla,
        Category: Category?.name,
        primaryagent: PrimaryContact == undefined ? null : PrimaryContact?.name,
        secondaryagent: Agent == undefined ? "null" : Agent?.firstName,
        PrimaryContactEmail: PrimaryContact?.email,
        KnownAs: PrimaryContact?.knownAs,
      });

      const subject = parseFunForSubject(submittedData?.template?.subject, {
        ticket_id: submittedData?.id,
        organisation_name: currOrganisation?.name,
        primaryagent: PrimaryContact == undefined ? null : PrimaryContact?.name,
        secondaryagent: Agent == undefined ? "null" : Agent?.firstName,
        PrimaryContactEmail: PrimaryContact?.email,
        KnownAs: PrimaryContact?.knownAs,
      });

      console.log("filterEmailData  check email", filterEmailData);
      formik.setFieldValue("optionsForBCC", filterEmailData?.bcc);
      formik.setFieldValue("optionsForCC", filterEmailData?.cc);
      formik.setFieldValue("optionsForTo", filterEmailData?.to);
      const from = submittedData?.template?.from;
      formik.setFieldValue("from", from);

      if (filterEmailData?.selectedTo?.length > 0) {
        formik.setFieldValue("to", filterEmailData?.selectedTo);
      } else {
        if (PrimaryContact) {
          formik.setFieldValue("to", [
            {
              label: PrimaryContact?.email,
              value: PrimaryContact?.email,
            },
          ]);
        }
      }
      if (filterEmailData?.selectedCC?.length >= 0) {
        formik.setFieldValue("cc", filterEmailData?.selectedCC);
      }
      if (filterEmailData?.selectedBCC?.length >= 0) {
        formik.setFieldValue("bcc", filterEmailData?.selectedBCC);
      }
      formik.setFieldValue("Emailsubject", subject);
      if (body) {
        formik.setFieldValue("defaultValueBody", body);
        formik.setFieldValue("body", body);
      }
    }
    //--------------------------------------------------------------------------------------
    else if (type == "add") {
      console.log("content of email", submittedData);
      setAttachmentList(submittedData?.template?.emailTemplateAttachmentList);

      if (Object.keys(submittedData)?.length > 0) {
        const PrimaryContact = ContactList?.find(
          (x: any) => x.id === submittedData?.ticketLogs?.contactId
        );
        const Agent = agent?.find(
          (x: any) => x.id === submittedData?.ticketLogs?.ownerId
        );
        const Status = formData?.status.find(
          (x: any) => x.id === submittedData?.ticketLogs?.ticketStatus
        );
        const Priority = formData?.priority.find(
          (x: any) => x.id === submittedData?.ticketLogs?.priorityId
        );
        const Group = formData?.group.find(
          (x: any) => x.id === submittedData?.ticketLogs?.groupId
        );
        const TicketType = formData?.ticketType.find(
          (x: any) => x.id === submittedData?.ticketLogs?.ticketType
        );
        const Category = formData?.Category.find(
          (x: any) => x.id === submittedData?.ticketLogs?.categoryId
        );
        const Sla = submittedData?.ticketLogs?.sla
          ? submittedData.ticketLogs.sla
          : null;

        console.log("PrimaryContactPrimaryContact", submittedData);
        const body = parseFun(submittedData?.template?.body, {
          organisation_name: currOrganisation?.name,
          ticket_id: submittedData?.id,
          Note: submittedData?.ticketLogs?.note
            ? submittedData?.ticketLogs?.note
            : null,
          Title: submittedData?.ticketLogs?.title,
          Description: submittedData?.ticketLogs?.description,
          OpenDateTime: submittedData?.ticketLogs?.openDateTime,
          DueDateTime: submittedData?.ticketLogs?.dueDateTime,
          FirstResponseDueDateTime:
            submittedData?.ticketLogs?.firstResponseDueDateTime,
          NextActionDescription: submittedData?.ticketLogs
            ?.nextActionDescription
            ? submittedData?.ticketLogs?.nextActionDescription
            : null,
          ScheduleDateTime: submittedData?.ticketLogs?.scheduleDateTime,
          CreatedDateTime: submittedData?.ticketLogs?.createdDateTime,
          Status: submittedData?.ticketLogs?.ticketStatus?.name,
          Priority: submittedData?.ticketLogs?.priority?.name,
          Group_Id: submittedData?.ticketLogs?.group?.name,
          Owner_Id: submittedData?.ticketLogs?.agent?.firstName,
          TicketType: submittedData?.ticketLogs?.ticketType?.name,
          Sla: Sla,
          Category: submittedData?.ticketLogs?.categoryId,
          primaryagent: submittedData?.ticketLogs?.contacts?.name,
          secondaryagent: submittedData?.ticketLogs?.contacts?.name,
          PrimaryContactEmail: submittedData?.ticketLogs?.contacts?.email,
          KnownAs: submittedData?.ticketLogs?.contacts?.knownAs,
          ManagerName: submittedData?.ticketLogs?.manager?.name,
          ManagerEmail: submittedData?.ticketLogs?.manager?.email,
          Owner_Emai: submittedData?.ticketLogs?.agent?.email,
          ActionBy: submittedData?.ticketLogs?.user?.name,
          Effort: submittedData?.totalEffort,
        });

        const subject = parseFunForSubject(submittedData?.ticketLogs?.title, {
          ticket_id: submittedData?.id,
          organisation_name: currOrganisation?.name,
          primaryagent:
            PrimaryContact == undefined ? null : PrimaryContact?.name,
          secondaryagent: Agent == undefined ? "null" : Agent?.firstName,
          PrimaryContactEmail: PrimaryContact?.email,
          KnownAs: PrimaryContact?.knownAs,
        });

        const from = submittedData?.template?.from;
        if (filterEmailData?.selectedTo?.length > 0) {
          formik.setFieldValue("to", filterEmailData?.selectedTo);
        } else {
          const splitTO: any =
            submittedData?.ticketLogs?.circulationListTO?.split(";");
          let temp: any = [];
          splitTO?.filter((ele: any) => {
            let a: any = {
              label: ele,
              name: ele,
              value: ele,
              id: ele,
              color: "red",
            };
            temp.push(a);
          });
          formik.setFieldValue("to", temp);
        }
        formik.setFieldValue("Emailsubject", subject);
        formik.setFieldValue("from", from);
        const splitCC = submittedData?.template?.cc?.split(";");
        let tempsplitCC: any = [];
        splitCC?.filter((ele: any) => {
          let a: any = {
            label: ele,
            name: ele,
            value: ele,
            id: ele,
            color: "red",
          };
          tempsplitCC.push(a);
        });

        const splitBCC = submittedData?.template?.bcc?.split(";");
        let tempsplitBCC: any = [];
        splitBCC?.filter((ele: any) => {
          let a: any = {
            label: ele,
            name: ele,
            value: ele,
            id: ele,
            color: "red",
          };
          tempsplitBCC.push(a);
        });
        if (filterEmailData?.selectedCC?.length > 0) {
          formik.setFieldValue("cc", filterEmailData?.selectedCC);
        } else {
          const splitCC =
            submittedData?.ticketLogs.circulationListCC?.split(";");
          let temp: any = [];
          splitCC?.filter((ele: any) => {
            let a: any = {
              label: ele,
              name: ele,
              value: ele,
              id: ele,
              color: "red",
            };
            temp.push(a);
          });
          formik.setFieldValue("cc", temp);
        }
        if (filterEmailData?.selectedBCC?.length > 0) {
          formik.setFieldValue("bcc", filterEmailData?.selectedBCC);
        } else {
          const splitBCC =
            submittedData?.ticketLogs?.circulationListBCC?.split(";");
          let temp: any = [];
          splitBCC?.filter((ele: any) => {
            let a: any = {
              label: ele,
              name: ele,
              value: ele,
              id: ele,
              color: "red",
            };
            temp.push(a);
          });
          formik.setFieldValue("bcc", temp);
        }

        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = JSON.stringify(
          convertToRaw(
            EditorState.createWithContent(contentState).getCurrentContent()
          )
        );
        console.log("entityType", editorState);
        formik.setFieldValue("defaultValueBody", body);
        formik.setFieldValue("body", body);

        if (submittedData?.contactList?.length > 0) {
          let temp: any = [];
          let temp1: any = [];
          submittedData.contactList.filter((item: any) => {
            if (item.mail !== null) {
              let a: any = {
                label: item.mail,
                name: item.mail,
                value: item.mail,
                id: item.mail,
              };
              let b: any = {
                keys: [item.name.toLowerCase()],
                value: item.mail,
                content: (
                  <Staff name={item.mail} job={item.name} color="tomato" />
                ),
              };
              temp.push(a);
              temp1.push(b);
            }
          });

          formik.setFieldValue("optionsForSuggestion", temp1);
          formik.setFieldValue("optionsForTo", temp);
          formik.setFieldValue("optionsForCC", temp);
          formik.setFieldValue("optionsForBCC", temp);
        }
      }
    }
    //--------------------------------------------------------------------------------------
    else if (type == "addfromtimeline") {
      setAttachmentList(submittedData?.template?.emailTemplateAttachmentList);

      if (Object.keys(submittedData)?.length > 0) {
        const PrimaryContact = ContactList.find(
          (x: any) => x.id === submittedData?.ticketLogs?.contactId
        );
        const Agent = agent.find(
          (x: any) => x.id === submittedData?.ticketLogs?.agentId
        );
        const Status = formData?.status.find(
          (x: any) => x.id === submittedData?.ticketLogs?.status
        );
        const Priority = formData?.priority.find(
          (x: any) => x.id === submittedData?.ticketLogs?.priority
        );
        const Group = formData?.group.find(
          (x: any) => x.id === submittedData?.ticketLogs?.groupId
        );
        const TicketType = formData?.ticketType.find(
          (x: any) => x.id === submittedData?.ticketLogs?.ticketType
        );
        const Category = formData?.Category.find(
          (x: any) => x.id === submittedData?.ticketLogs?.category
        );
        const Sla = submittedData?.ticketLogs?.sla
          ? submittedData.ticketLogs.sla
          : null;

        const body = parseFun(submittedData?.template?.body, {
          organisation_name: currOrganisation?.name,
          ticket_id: submittedData?.id,
          Note: submittedData?.ticketLogs?.note,
          Title: submittedData?.ticketLogs?.title,
          Description: submittedData?.ticketLogs?.description,
          OpenDateTime: submittedData?.ticketLogs?.openDateTime,
          DueDateTime: submittedData?.ticketLogs?.dueDateTime,
          FirstResponseDueDateTime:
            submittedData?.ticketLogs?.firstResponseDueDateTime,
          NextActionDescription:
            submittedData?.ticketLogs?.nextActionDescription,
          ScheduleDateTime: submittedData?.ticketLogs?.scheduleDateTime,
          CreatedDateTime: submittedData?.ticketLogs?.createdDateTime,
          Status: Status?.label,
          Priority: Priority?.label,
          Group_Id: Group?.label,
          Owner_Id: Agent?.firstName,
          TicketType: TicketType?.name,
          Sla: Sla,
          Category: Category?.name,
          primaryagent:
            PrimaryContact == undefined ? null : PrimaryContact?.name,
          secondaryagent: Agent == undefined ? null : Agent?.firstName,
          PrimaryContactEmail: PrimaryContact?.email,
          KnownAs: PrimaryContact?.knownAs,
        });
        console.log("body show test", body);

        const subject = parseFunForSubject(submittedData?.template?.subject, {
          organisation_name: currOrganisation?.name,
          ticket_id: submittedData?.id,
          Note: submittedData?.ticketLogs?.note,
          Title: submittedData?.ticketLogs?.title,
          Description: submittedData?.ticketLogs?.description,
          OpenDateTime: submittedData?.ticketLogs?.openDateTime,
          DueDateTime: submittedData?.ticketLogs?.dueDateTime,
          FirstResponseDueDateTime:
            submittedData?.ticketLogs?.firstResponseDueDateTime,
          NextActionDescription:
            submittedData?.ticketLogs?.nextActionDescription,
          ScheduleDateTime: submittedData?.ticketLogs?.scheduleDateTime,
          CreatedDateTime: submittedData?.ticketLogs?.createdDateTime,
          Status: Status?.label,
          Priority: Priority?.label,
          Group_Id: Group?.label,
          Owner_Id: Agent?.firstName,
          TicketType: TicketType?.name,
          Sla: Sla,
          Category: Category?.name,
          primaryagent:
            PrimaryContact == undefined ? null : PrimaryContact?.name,
          secondaryagent: Agent == undefined ? null : Agent?.firstName,
          PrimaryContactEmail: PrimaryContact?.email,
          KnownAs: PrimaryContact?.knownAs,
        });
        const from = submittedData?.template?.from;

        formik.setFieldValue("Emailsubject", subject);
        formik.setFieldValue("from", from);

        const splitCC = submittedData?.ticketLogs?.circulationListCC
          ? submittedData?.ticketLogs?.circulationListCC.split(";")
          : submittedData?.template?.cc?.split(";");
        const splitBCC = submittedData?.ticketLogs?.circulationListBCC
          ? submittedData?.ticketLogs?.circulationListBCC?.split(";")
          : submittedData?.template?.bcc?.split(";");
        const splitTO = submittedData?.ticketsLogs?.circulationListTO
          ? submittedData?.ticketsLogs?.circulationListTO?.split(";")
          : [PrimaryContact?.email];
        let temp: any = [];
        splitCC?.filter((ele: any) => {
          let a: any = {
            label: ele,
            name: ele,
            value: ele,
            id: ele,
            color: "red",
          };
          temp.push(a);
        });
        let temp1: any = [];
        splitBCC?.filter((ele: any) => {
          let a: any = {
            label: ele,
            name: ele,
            value: ele,
            id: ele,
            color: "red",
          };
          temp1.push(a);
        });
        let temp2: any = [];
        splitTO?.filter((ele: any) => {
          let a: any = {
            label: ele,
            name: ele,
            value: ele,
            id: ele,
          };
          temp2.push(a);
        });
        formik.setFieldValue("to", temp2);
        formik.setFieldValue("cc", temp);
        formik.setFieldValue("bcc", temp1);
        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = JSON.stringify(
          convertToRaw(
            EditorState.createWithContent(contentState).getCurrentContent()
          )
        );
        formik.setFieldValue("defaultValueBody", body);
        formik.setFieldValue("body", body);
        if (submittedData?.contactList?.length > 0) {
          let temp: any = [];
          let temp1: any = [];
          submittedData.contactList.filter((item: any) => {
            if (item.mail !== null) {
              let a: any = {
                label: item.mail,
                name: item.mail,
                value: item.mail,
                id: item.mail,
              };
              let b: any = {
                keys: [item.name.toLowerCase()],
                value: item.mail,
                content: (
                  <Staff name={item.mail} job={item.name} color="tomato" />
                ),
              };
              temp.push(a);
              temp1.push(b);
            }
          });

          formik.setFieldValue("optionsForSuggestion", temp1);
          formik.setFieldValue("optionsForTo", temp);
          formik.setFieldValue("optionsForCC", temp);
          formik.setFieldValue("optionsForBCC", temp);
        }
      }
    } else if (type == "history") {
      setAttachmentList(submittedData?.ticketLogAttachmentsList);

      console.log(
        "submiitedDatahistory",
        replaceEscapedQuotes(submittedData?.body)
      );
      formik.setFieldValue("from", submittedData?.from);

      // if (submittedData?.body && submittedData?.body !== null) {
      //   const blocksFromHtml = htmlToDraft(
      //     submittedData?.body,
      //     (nodeName, node) => {
      //       if (nodeName === "img") {
      //         var src = node?.getAttribute("src");
      //         var width = node?.getAttribute("width");
      //         var height = node?.getAttribute("height");
      //         var alignment: any = node?.getAttribute("alignment");

      //         console.log("entityType", node);

      //         return {
      //           type: "IMAGE",
      //           mutability: "MUTABLE",
      //           data: {
      //             src: src,
      //             height: height,
      //             width: width,
      //             url: src,
      //             alignment: alignment,
      //           },
      //         };
      //       }
      //     }
      //   );
      //   const { contentBlocks, entityMap } = blocksFromHtml;
      //   const contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   const editorState = JSON.stringify(
      //     convertToRaw(
      //       EditorState.createWithContent(contentState).getCurrentContent()
      //     )
      //   );

      //   console.log("entityType", editorState);
      //   formik.setFieldValue(
      //     "defaultValueBody",
      //     replaceEscapedQuotes(submittedData?.body)
      //   );
      //   formik.setFieldValue("body", replaceEscapedQuotes(submittedData?.body));
      // }

      formik.setFieldValue("body", replaceEscapedQuotes(submittedData?.body));

      formik.setFieldValue("Emailsubject", submittedData?.title);
      const splitCC = submittedData?.cc?.split(";");
      const splitBCC = submittedData?.bcc?.split(";");
      const splitTO = submittedData?.to?.split(";");
      let temp: any = [];
      splitCC?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
          color: "red",
        };
        temp.push(a);
      });
      let temp1: any = [];
      splitBCC?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
          color: "red",
        };
        temp1.push(a);
      });
      let temp2: any = [];
      splitTO?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
        };
        temp2.push(a);
      });
      formik.setFieldValue("to", temp2);
      formik.setFieldValue("cc", temp);
      formik.setFieldValue("bcc", temp1);
    }
  }, [submittedData, currOrganisation, ContactList]);

  return (
    <>
      <CustomDialog
        heading={
          formik.values.Emailsubject ? formik.values.Emailsubject : "Send email"
        }
        body={
          <>
            {isLoading ? (
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>To:</div>
                      <div style={{ width: "100%" }}>
                        <CustomCreatableSelect
                          name="to"
                          options={formik.values.optionsForTo}
                          defaultValue={formik.values.to}
                          placeholder=""
                          width={"100% !important"}
                          isMulti={true}
                          handleInputChange={(e: any) => {
                            formik.setFieldValue("to", e);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>Cc:</div>

                        <div style={{ width: "100%" }}>
                          <CustomCreatableSelect
                            name="cc"
                            options={formik.values.optionsForCC}
                            defaultValue={formik.values.cc}
                            placeholder=""
                            width={"100%"}
                            isMulti={true}
                            handleInputChange={(e: any) => {
                              formik.setFieldValue("cc", e);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  </Grid>

                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>Bcc:</div>

                        <div style={{ width: "100%" }}>
                          <CustomCreatableSelect
                            name="bcc"
                            options={formik.values.optionsForBCC}
                            defaultValue={formik.values.bcc}
                            placeholder=""
                            width={"100%"}
                            isMulti={true}
                            handleInputChange={(e: any) => {
                              formik.setFieldValue("bcc", e);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  </Grid>

                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>Subject: </div>
                      <div style={{ width: "100%" }} className="">
                        <CustomInput
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.Emailsubject &&
                            Boolean(formik.errors.Emailsubject)
                          }
                          label={"Enter the Notes"}
                          name="Emailsubject"
                          value={formik.values.Emailsubject}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.Emailsubject &&
                            formik.errors.Emailsubject
                          }
                          placeholder={"Subject"}
                        />{" "}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <div>
                      <AddAttachmentBlobWithPreview
                        btnLabel={"Add attachments"}
                        accept={".pdf,.doc,.docx,.jpg,.jpeg,.png,.zip,.txt,.xls,.xlsx,.mp4,.bmp"}
                        type="Attachment"
                        isAttachmentOnFly={true}
                        isShowBtn={true}
                        attachmentList={attachmentList}
                        setAttachmentList={setAttachmentList}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <div
                      style={{
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      <SunEditor
                        autoFocus={true}
                        lang="en"
                        setOptions={{
                          showPathLabel: false,
                          minHeight: "50vh",
                          maxHeight: "50vh",
                          placeholder: "Enter your text here!!!",
                          plugins: [
                            align,
                            font,
                            fontColor,
                            fontSize,
                            formatBlock,
                            hiliteColor,
                            horizontalRule,
                            lineHeight,
                            list,
                            paragraphStyle,
                            table,
                            template,
                            textStyle,
                            image,
                            link,
                          ],
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            ["paragraphStyle"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["fontColor", "hiliteColor"],
                            ["removeFormat"],
                            "/", // Line break
                            ["outdent", "indent"],
                            ["align", "horizontalRule", "list", "lineHeight"],
                            ["table", "link", "image"],
                          ],
                          formats: [
                            "p",
                            "div",
                            "h1",
                            "h2",
                            "h3",
                            "h4",
                            "h5",
                            "h6",
                          ],
                          font: [
                            "Arial",
                            "Calibri",
                            "Comic Sans",
                            "Courier",
                            "Garamond",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Palatino Linotype",
                            "Segoe UI",
                            "Tahoma",
                            "Times New Roman",
                            "Trebuchet MS",
                          ],
                          imageMultipleFile: true,
                        }}
                        // onImageUpload={()=>{
                        //   console.log('upload Progress')
                        // }}
                        onImageUploadBefore={onImageUploadBefore}
                        //imageMultipleFile={true}
                        defaultValue={formik.values.defaultValueBody}
                        setContents={formik.values.body}
                        onChange={(content: string) => {
                          // handleChange(content)
                          formik.setFieldValue("body", content);
                        }}
                      />
                      {/* <ThemeProvider theme={defaultTheme}>
                        <MUIRichTextEditor
                          label="Body"
                          autocomplete={{
                            strategies: [
                              {
                                items: formik.values.optionsForSuggestion,
                                triggerChar: "@",
                                insertSpaceAfter: false,
                              },
                            ],
                          }}
                          defaultValue={formik.values.defaultValueBody}
                          onChange={(e: any) => {
                            // const options : any = {}; // optional conversion options
                            const rawContentState = e.getCurrentContent();
                            console.log("bodybody", rawContentState);
                            let options = {
                              entityStyleFn: (entity: any) => {
                                console.log(
                                  "draftToHtml(rawContentState)-----",
                                  entity
                                );
                                const entityType = entity
                                  ?.get("type")
                                  ?.toLowerCase();
                                if (entityType === "image") {
                                  const data = entity.getData();
                                  console.log("pottan", data);
                                  return {
                                    element: "img",
                                    attributes: {
                                      ...data,
                                      src: data.url,
                                      height: data.height,
                                      width: data.width,
                                    },
                                    style: {
                                      // Put styles here...
                                    },
                                  };
                                }
                              },
                            };
                            let html = stateToHTML(rawContentState, options);

                            rawContentState &&
                              formik.setFieldValue("body", html);
                          }}
                          inlineToolbar={true}
                        />
                      </ThemeProvider> */}
                    </div>
                  </Grid>
                </Grid>
              </form>
            ) : (
              "loading"
            )}
          </>
        }
        open={isSendEmailPopup}
        handleClose={() => {
          setIsSendEMailPopup(false);
          dispatch({
            type: "SEND_EMAIL_VIEW",
            payload: null,
          });
          type == "add" &&
            navigate(`/TicketManagement?assignee=${currAgent.id}`);
        }}
        footer={
          <>
            <div style={{ display: "flex", gap: 10 }}>
              <BasicButton
                onClick={() => {
                  setIsSendEMailPopup(false);
                  dispatch({
                    type: "SEND_EMAIL_VIEW",
                    payload: null,
                  });
                  if (type == "edit") {
                  } else {
                    if (type == "history") {
                    } else {
                      navigate(`/TicketManagement?assignee=${currAgent.id}`);
                    }
                  }
                }}
                type={"cancel"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                label={"Cancel"}
                variant={"outlined"}
                endIcon={<CloseIcon sx={{ width: "20px" }} />}
              />
              <form onSubmit={formik.handleSubmit}>
                <BasicButton
                  onClick={() => {}}
                  type="submit"
                  label={type == "history" ? "Resend" : "Send"}
                  isLoading={loading}
                  isDisabled={false}
                  color="primary"
                  size="large"
                  variant={"contained"}
                  endIcon={<SendIcon sx={{ width: "20px" }} />}
                />
              </form>
            </div>
          </>
        }
      />
    </>
  );
};
export default SendEmailPopup;
