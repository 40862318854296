import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertToTimeSpan } from '../../../../pages/new-ticket/AddUpdate';
import { schemaForTicketCreationFormData } from '../../../../pages/new-ticket/LatestTicket/Schema';
import { isValidate } from '../../../../pages/new-ticket/LatestTicket/Validation';
import { triggerAppAlert } from '../../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { getDateRecomFun, getValueToPrefillDueDate } from '../../../../redux/actions/SystemManagement/AddPriority';
import { createNewTicket, getAllGroup, getUsrList } from '../../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../../../redux/reducers/rootReducers';
import { ChooseAttachment, MobileUserIconBlue, MobileUserIconOrange } from '../../../common/svgIcons/chartIcon';
import { TextBoxLatest } from '../../../common/TextBoxLatest/TextBoxLatest';
import { formatDate, getFileObjUrl } from '../../../helpers/helperFunctions';
import CategorySelection from '../../MobileCommon/CategorySelection';
import AccordionCollapse from '../../MobileCommon/Collapse';
import ContactMobileDropdown from '../../MobileCommon/ContactDropdown';
import { FileView } from '../../MobileCommon/FileView';
import CommonMobileDatePicker from '../../MobileCommon/MobileDatePicker';
import MobileDropdown from '../../MobileCommon/MobileDropdown';
import ChooseAsset from '../../MobileUtilities/ChooseAsset';
import EffortField from '../../MobileUtilities/EffortField';
import EmailTemplateOption from '../../MobileUtilities/EmailTemplateOption';
import FormWrapper from '../../MobileUtilities/FormWrapper';
import NavBar from '../../MobileUtilities/NavBar';
interface ICreateTicketProps{
  allResponses?:any;
  dispatch?:any;
  loggedInAgent?:any
}
function CreateTicket(props:ICreateTicketProps) {
  {/*_____ STATES , PROPS ,DECLARATION ____*/}
  const {allResponses,dispatch,loggedInAgent} = props;
  const {groups=[],priorities=[],ticketSources=[],ticketStatus=[],TicketTypes=[]} = allResponses;
  const titleRef = useRef();
  const navigate = useNavigate();
  const [agentList,setAgentList] = useState<any[]>([]);
  const [filesList,setFilesList] = useState<any[]>([]);
  const [priorityOptions,setPriorityOptions] = useState<any[]>([]);
  const [priorityObj,setPriorityObj] = useState<any>({});
  const [ticketTypeId,setTicketTypeId] = useState(0);
  const [status,setStatus] = useState(null);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [fileListToShow,setFileListToShow] = useState<any[]>([]);


  const [prefillDueDate,setPrefillDueDate] = useState<any>(null);
  
  
  const [defaultAgentGroup,setDefaultAgentGroup] = useState<any>(undefined);


  {/*_____ HANDLERS ____*/}
  const prefillTheNextActionDateAndDueDate = debounce((priority:any)=>{
    if(priority?.id){
      getDateRecomFun(priority.id).then((res: any) => {
        if(res){
          const value:any = getValueToPrefillDueDate(priority,res)
          console.log("prefillTheNextActionDateAndDueDate",priority,value)
          setPrefillDueDate(value);
          if(value?.isAlert){
            dispatch(triggerAppAlert(`SLA retrieval failed, Please retry later or contact our support team`, "error" ));
          }
        }
      })
    }else{
      setPrefillDueDate(null)
    }
    
  },100);

  const getPriorityStructure = debounce(()=>{
    let tempObj:any={}
    try {
      priorities.map((i:any)=>{
        const {ticketTypeList=[],label,value} = i;
            ticketTypeList.map((j:any)=>{
                const {id} = j.ticketType || {};
                if(!id) return;
                if(!tempObj[id]){ tempObj[id] = []}
                tempObj[id].push({...i,label, value, id:i.id})
            })
      })
      setPriorityObj(tempObj)
    } catch (error) {
      console.log("getPriorityStructure",error)
      setPriorityObj([]);
    }
    
  },100)

  const onTicketTypeChange = debounce((val:any) =>{
    console.log("onTicketTypeChange",val,priorities)
    setPriorityOptions(priorityObj?.[val?.id] || [])
    setTicketTypeId(val?.id || 0)
  },100)
  const groupChangeHandler = debounce((val:any) =>{
    console.log("groupChangeHandler",val)
    setAgentList(val?.agentsList || [])
  },100)
  const statusChangeHandler = debounce((val:any) =>{
    console.log("statusChangeHandler",val)
    setStatus(val || null)
  },100)
  const agentChangeHandler = debounce((val:any) =>{
    // setStatus(val || null)
    let newToMeStatus = null;
    let inProgressStatus = null;
    ticketStatus.map((i:any)=>{
      if(i.name === "NewtoMe"){
        newToMeStatus = i;
      }else if(i.name === "InProgress"){
        inProgressStatus = i;
      }
    })
    if(val?.id === loggedInAgent?.id){
      setStatus(inProgressStatus)
    }else if(val?.id) {
      setStatus(newToMeStatus)
    } else{
      setStatus(null)
    }
  },100)
  
  
  {/*_____ page submit & cancel functions ____*/}
  const onValidationFailure = (errors:any) =>{
    console.log("onValidationFailure",errors)

  }

  const onPageSubmit = (val:any) =>{
    const payload:any = generateAPIPayload(val,onValidationFailure);
    console.log("onPageSubmit",payload,val)
    // return;
    if(payload.emailErr){
      dispatch(triggerAppAlert("Please Choose Email Template","warning"))
      return;
    }
    if(!payload) {
      dispatch(triggerAppAlert("Please fill in all required fields to proceed.","warning"))
      return;
    };  
    payload.ticketLogs.ticketLogAttachmentsList = filesList.map((i)=>{
      return {"AttachmentPath" : i.name}
    });
    payload.files = filesList;
    dispatch(createNewTicket(payload,createNewTicketCB));
  }
  const createNewTicketCB = () =>{
    console.log("createNewTicketCB")
    cancelHandler();
  }
  const cancelHandler = () =>{
    navigate('/')
  }



  {/*_____ useEffects ____*/}

  
  useEffect(() => {
    dispatch(getAllGroup(getCallback))
    console.log("allResponses-onload",allResponses)
    return () => {console.log("cleanup function")};
  }, [])
  const getCallback = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      res = res.map((i:any)=>{
        return {
          ...i,
          agentsList: getUsrList(i?.userGroupList),
        }
      })
      setGroupOptions(res);
    }
  }
  useEffect(()=>{
    if(loggedInAgent?.id){
      const defaultGroupId = (loggedInAgent?.userGroupList || []).find((x:any)=>x.isDefault)?.groupId || 0;
      let group:any = null;
      if(defaultGroupId){
        group = groups.find((x:any)=>x.id === defaultGroupId) || null;
        groupChangeHandler(group)
      }
      let agent:any = null;
      if(group?.agentsList){
        agent = group?.agentsList.find((x:any)=>x.id === loggedInAgent?.id) || null;        
      }
      
      let status:any = null;
      if(group && agent){
        status = ticketStatus.find((x:any)=>x.name === "InProgress") || null;        
      }
      setStatus(status)
      setTimeout(() => {
        setDefaultAgentGroup({group,agent,status})
      }, 1000);
      console.log("loggedInAgent", loggedInAgent?.userGroupList?.[0]?.isDefault, groups)
    }
  },[loggedInAgent])
  
  useEffect(()=>{
    getPriorityStructure();
  },[priorities?.length])
  const fileChangeHandler = (e:any)=>{
    // 
    const files = Array.from(e.target.files);
    const formattedArr = files.map((i:any)=>{
      return getFileObjUrl(i)
    })
    console.log("formattedArr",formattedArr)
    setFileListToShow((old)=>old.concat(formattedArr));
    setFilesList((old)=>old.concat(Object.values(e.target.files)));
  }
  const removeFile = (index:number)=>{
    setFileListToShow((old)=>old.filter((_,ind)=>ind!==index));
    setFilesList((old)=>old.filter((_,ind)=>ind!==index));
  }
  return (
    <Box className="p-10p pb-100vh">
      <NavBar
        pageTitle="Create New Ticket"
        toggler={false}
        backIcon={true}
        backPath={"/"}
      />
      <FormWrapper
          onClose={cancelHandler}
          onSubmit={onPageSubmit}
      >
          <Box>
            <TextBoxLatest
              multiline={false}
              placeholder="Add Title/Subject...  "
              title={""}
              ref={titleRef}
              isMandotary={false}
              errorMsg={``}
              isError={false}
              name='title'
              type="text"
              parentClassNames="mobile-subject-field"
            />

          <AccordionCollapse 
            title={<span className="label-required">Description <b>*</b></span>}
            body={<>
              <TextBoxLatest
                multiline={true}
                placeholder="Add a description..."
                title={""}
                ref={titleRef}
                isMandotary={false}
                errorMsg={``}
                isError={false}
                name='description'
                type="text"
              />
            </>}
            id="desc"
          />
          <AccordionCollapse 
            title={"Case Information"}
            body={<>
              <MobileDropdown 
                  label = "Ticket Type"
                  placeholder = "Select a ticket type"
                  options={TicketTypes}
                  isRequired={true}
                  defaultValue={null}
                  name="ticketTypeId"
                  onChange={onTicketTypeChange}
              />
              <MobileDropdown 
                  label = "Priority"
                  placeholder = "Select a priority"
                  options={priorityOptions}
                  isRequired={true}
                  defaultValue={null}
                  name="priorityId"
                  onChange={prefillTheNextActionDateAndDueDate}
              />
              <CategorySelection 
                ticketTypeId= {ticketTypeId}
              />
              {/* <MobileDropdown 
                  label = "Category"
                  placeholder = "Select a ticket type"
                  options={[
                    {label:"Incident",value:1,id:1},
                    {label:"Service Request",value:2,id:2},
                    {label:"Problem",value:3,id:3},
                  ]}
                  isRequired={true}
                  defaultValue={null}
                  name="categoryId"
              /> */}
              <Typography className="mobile-field-note">Choose Ticket Type before selecting a category or priority</Typography>
            </>}
            id="case-info"
          />



          <AccordionCollapse 
            title={"Contact Details"}
            body={<>
              <ContactMobileDropdown 
                  icon = {<MobileUserIconOrange />}
                  label = "Primary Contact"
                  placeholder = "Select a primary contact"
                  isRequired={true}
                  defaultValue={null}
                  name="contactId"
              />
              <ContactMobileDropdown 
                  icon = {<MobileUserIconBlue />}
                  label = "Secondary Contact"
                  placeholder = "Select a secondary contact"
                  defaultValue={null}
                  name="secondaryContactId"
              />

              {/* <AddNewButton label="Add Contact" /> */}
            </>}
            id="contact-info"
          />

          <ChooseAsset />

          <AccordionCollapse 
            title={"Details"}
            body={<>
                <MobileDropdown 
                      label = "Sources"
                      placeholder = "Select a sources"
                      options={ticketSources}
                      isRequired={true}
                      defaultValue={null}
                      name="sourceCode"
                />
                <CommonMobileDatePicker
                    label={"Due Date"}
                    isRequired={true}
                    name='dueDateTime'
                    dynamicValue={prefillDueDate?.dueDate?.value}
                />
              
                <CommonMobileDatePicker
                    label={"Next Action Date"}
                    name='firstResponseDueDateTime'
                    dynamicValue={prefillDueDate?.nextActionDate?.value}
                />
                <TextBoxLatest
                  multiline={true}
                  placeholder="Add a description"
                  title={"Next Action Description"}
                  ref={titleRef}
                  isMandotary={false}
                  errorMsg={``}
                  isError={false}
                  name='nextActionDescription'
                  type="text"
                />
                <Divider />
            </>}
            id="t-details"
          />
        
          <AccordionCollapse 
              title={"Attachments"}
              body={<>
                <Box className="gap5 row m-0">
                  {fileListToShow.map((i:any,index:number)=><>
                    <FileView name={i.name} onRemoveHandler={()=>{removeFile(index)}} fileExt={i.type} url={i.src}/>
                  </>)}
                  
                  <Box>
                    <label htmlFor="ticketLogAttachmentsList" className="mbl-attachIcon">
                      <ChooseAttachment /> <span>{filesList.length >0 ? filesList.length : ""  }</span>
                    </label>
                    <input className='d-none' type="file" onChange={fileChangeHandler} name="ticketLogAttachmentsList" id="ticketLogAttachmentsList" multiple/>
                  </Box>
                </Box>
              </>}
              id="attachment-details"
          />

          {defaultAgentGroup !== undefined && 
            <AccordionCollapse 
              title={"Additional Information"}
              body={<>
                  <MobileDropdown 
                        label = "Group"
                        placeholder = "Select a group"
                        options={groupOptions}
                        isRequired={true}
                        defaultValue={defaultAgentGroup?.group || null}
                        name="groupId"
                        onChange={groupChangeHandler}
                  />
                  
                  <MobileDropdown 
                        icon = {<MobileUserIconOrange />}
                        label = "Agent"
                        placeholder = "Select a agent"
                        options={agentList}
                        isRequired={true}
                        defaultValue={defaultAgentGroup?.agent || null}
                        name="agentId"
                        onChange={agentChangeHandler}
                  />
                  
                  <MobileDropdown 
                        label = "Status"
                        placeholder = "Select a status"
                        options={ticketStatus}
                        isRequired={true}
                        defaultValue={defaultAgentGroup?.status || null}
                        name="statusId"
                        onChange={statusChangeHandler}
                        dynamicValue={status}
                  />
                  
                  
                  <EffortField />
                  <TextBoxLatest
                    multiline={false}
                    placeholder="Add a brief note"
                    title={"Note"}
                    ref={titleRef}
                    isMandotary={false}
                    errorMsg={``}
                    isError={false}
                    name='note'
                    type="text"
                  />

                  <EmailTemplateOption ticketStatus={status} />

              </>}
              id="group-and-status-details"
            />
          }


          </Box>
      </FormWrapper>
    </Box>
  )
}


const getDropDownValue = (value:string)=>{
  const val = +value ? +value : 0;
  return val
}
const generateAPIPayload = (value:any,onValidationFailure:any)=>{
  const {ticketTypeId,title,description,nextActionDescription,sourceCode,priorityId,categoryId,contactId,secondaryContactId,dueDateTime,firstResponseDueDateTime,groupId,agentId,statusId,hours="",mins="",assetsList="", emailTemplateId="",note="",sendEmail=""} = value;
  const assetObj = assetsList === "" ? {} : JSON.parse(assetsList);
  const ticketAssetList:any = []
  
  Object.keys(assetObj).map((i:any)=>{
      if(i.includes('asset-')){
        ticketAssetList.push({
            assetId: Number(i.split('-')[1])
          });
      }
  })

  let validationData: any = {//iUserFormDataPayload
    contactId: getDropDownValue(contactId),
    secondaryContactId: getDropDownValue(secondaryContactId),
    priorityId: getDropDownValue(priorityId),
    title,
    description,
    ticketTypeId: getDropDownValue(ticketTypeId),
    // dueDateTime:  dueDateTime ? moment.utc(dueDateTime)?.toISOString() : null,
    dueDateTime: formatDate(dueDateTime),
    firstResponseDueDateTime: formatDate(firstResponseDueDateTime),
    sourceCode,//: getDropDownValue(sourceCode),// ? {} : null,
    nextActionDescription,
    openDate: new Date().toISOString(),
    // ticketLogAssetList:[],//userFormData.asset.length > 0 ? modifyAsset(userFormData.asset) : null,
    categoryId: getDropDownValue(categoryId),
  };

  console.log("moment_ooo",moment(dueDateTime))
  const validation = isValidate(
    validationData,
    // isShowMandotaryIconForDueDate ? 
    schemaForTicketCreationFormData //: 
    // schemaForTicketCreationFormDataWithoutDueDate
  );
  console.log("validation-resullt", validation,validationData)
  if (!validation.isValid) {
    onValidationFailure(validation?.errors)
    return;
  }else if (sendEmail === "on" && emailTemplateId === ""){
    onValidationFailure({emailErr:true})
    return {emailErr:true};
  }
  let payload = {
    isActive: true,
    remainingSLAInMinutes: 0,
    sourceCode: "System",
    workflowId: 0 ,
    ticketAssetList: ticketAssetList,
    ticketLogs: {
      ...validationData,
      circulationListTO:null, //submittedData?.primaryContact?.mail ? submittedData?.primaryContact?.mail : null,
      isActive: true,
      ticketLogAssetList: [],
      dueDateTime : validationData.dueDateTime,
      groupId : getDropDownValue(groupId),
      agentId : getDropDownValue(agentId),
      statusId : getDropDownValue(statusId),
      effort: convertToTimeSpan("", hours,mins),
      downtime: convertToTimeSpan("", hours,mins),
      sourceCode: "System",
      note, 
      ticketLogAttachmentsList : [],
    },
    ticketTagList: [],
    ticketTaskList: [],//getfirstTaskByWorkflow(submittedData?.workflow?.workflowNodesList),
    emailTemplateId,
  };
  
  return payload;
}

const mapStateToProps = (state:IState) => {
  return ({
    allResponses: state?.TicketsReducer?.allResponses,
    loggedInAgent : state?.agentReducer?.loggedInAgent
  })
};

export default connect(mapStateToProps)(CreateTicket);