import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getRandomColor } from "./VerticalChart";
import { useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip);

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};

const getDarkLightModeTheme = (theme:boolean,isDarkMode:boolean) =>{
  return  {
    y: {
      beginAtZero: true,
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid: {
        color: theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    },
    x:{
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid : {
        color : theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    }
  }
}
const HorizontalChartActual = ({
  id='myReportBar',
  label,
  dataSets,
  chartLabel,
  callback,
  isDarkMode = false,
  showLabels = true
}: {
  label: any;
  dataSets: any;
  chartLabel?: string;
  callback?: any;
  id?: any
  isDarkMode?: boolean
  showLabels?: boolean
}) => {
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const labels = chart.data.labels;

            return labels.map((label: any, index: any) => {
              const dataset = datasets.find((d: any) => d.data[index] !== undefined);
              const backgroundColor = dataset.data.map(() => getRandomColor())
              const dataValue = dataset?.data[index] || 0;
              return {
                text: `${label} (${dataValue})`,
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          usePointStyle: false, // Use regular legend markers (not point-style)
          color: !isDarkMode ? "black" : "white", // Dynamic color based on mode
          font: {
            size: 14, // Adjust font size based on `showLabels`
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10,      // Custom size for the legend box width (color box)
          boxHeight: 10,
        }
      },
  
      tooltip: {
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return true;
        }
      },
      title: {
        display: true, // Enable the title
        text: chartLabel, // Set the title text
        color: isDarkMode ? "white" : "black", // Dynamic title color based on mode
        font: {
          size: 18, // Title font size
          family: "Arial", // Font family for the title
        },
        padding: {
          top: 10,
          bottom: 30,
        },
        align: "center", // Align title in the center
      },
      datalabels: {
        display: false,
        align: "center",
        anchor: "center",
        formatter: function (value: any) {
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join(",");
          return value;
        },
      },
    },
    scales: getDarkLightModeTheme(toggleTheme,isDarkMode),
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    indexAxis: 'y', // Set this to 'y' to make the bars horizontal
  };
  
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
  return <Bar height={400} id={id} data={data} options={options} onChange={onchange}
  />;
};
export default HorizontalChartActual;
