import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { triggerAppAlert } from '../../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { bulkUpdateNewTickets, getBulkNewTicket, storeAllCSVResponses } from '../../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../../../redux/reducers/rootReducers';
import CustomDialog from '../../../common/dialog/CustomDialog';
import Breadcrumbs from "../../../common2/Breadcrumbs";
import ConfirmationPopup from '../../../common2/ConfirmationPopup';
import TopFilterNew from '../../../common2/TopFilterNew';
import CSVLoader from '../../../ReportsTest/CSVLoader';
import AgentGroupOption from './AgentGroupOption';
const columns: GridColDef[] = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 100
    },
    {
        "field": "sourceCode",
        "headerName": "Source",
        "width": 150
    },
    {
        "field": "title",
        "headerName": "Title",
        "width": 250
    },
    {
        "field": "openDateTime",
        "headerName": "Opened Date",
        "width": 150
    },
    {
        "field": "group",
        "headerName": "Group",
        "width": 150
    },
    {
        "field": "contacts",
        "headerName": "Contact",
        "width": 150
    },
    {
        "field": "contactOrganisation",
        "headerName": "Organisation",
        "width": 150
    }
];
function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector />
        <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
}
interface IAllNewTicketsProps{
    allTickets:any;
    allResponses:any;
    dispatch:any
}
const getGroupIdsFromUrl = () =>{
    try {
        // URL to extract the groupIds from
        const url = window.location.href;

        // Extract the query string from the hash
        const queryString = url.split('?')[1];

        // Use URLSearchParams to parse the query string
        const params = new URLSearchParams(queryString);

        // Get the groupIds parameter value
        const groupIds = params.get('groupIds'); // "2,3"

        // Convert it into an array, if needed
        const groupIdsArray = groupIds ? groupIds.split(',').map(Number) : [];

        console.log(groupIds);       // Output: "2,3"
        console.log(groupIdsArray);  // Output: [2, 3]
        return groupIdsArray;
    } catch (error) {
        return [];   
    }
}
export function AllNewTickets({allTickets=[],allResponses={},dispatch}:IAllNewTicketsProps) {
    
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const loadTickets = debounce((search:string="",statusIds:number[]=[],groupIds:number[]=[]) =>{
        dispatch(getBulkNewTicket(search,statusIds,groupIds,10000,getBulkNewTicketCB))
    },150);
    const getBulkNewTicketCB = () =>{
        setRowSelectionModel([])
    }


    const table = React.useMemo(()=>{
        return <DataGrid 
                    rows={allTickets} 
                    columns={columns} 
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
    },[allTickets?.length,rowSelectionModel.length])
    const loader = React.useMemo(()=>{
        return <CSVLoader />
    },[])
    React.useEffect(()=>{
        getAllTicket()
        return()=>{
            dispatch(storeAllCSVResponses([]))
        }
    },[])

    const getAllTicket = (searchVal?:string) =>{
        console.log("AllNewTickets", allResponses?.ticketStatus,allResponses?.groups);
        const statusIds:number[] = [];
        const groupIds:number[] = [];
        allResponses?.ticketStatus.map((i:any)=>{if(i.name === "New"){statusIds.push(i.id);}})
        
        const groupIdsFromURL =  getGroupIdsFromUrl();
        if(groupIdsFromURL.length>0){
            groupIds.push(...groupIdsFromURL);
        }else{
            allResponses?.groups.map((i:any)=>{groupIds.push(i.id);})
        }
        loadTickets(searchVal||"",statusIds,groupIds);
    }

    
    return (
        <>
            <Breadcrumbs
                data={[
                {
                    title: "Ticket ManagementSys ",
                    path: "/TicketManagementSys",
                },
                {
                    title: "All New Tickets ",
                    path: "/AllNewTickets",
                },
                ]}
            />
            <Box className="allNew-tickets" style={{marginTop:"10px", height: "80vh", width: '100%' }}>
                {loader}
                {/* {allTickets?.length} {rowSelectionModel?.length} */}
                <FilterTickets rowSelectionModel={rowSelectionModel} loadTickets={getAllTicket} />
                
                {table}
            </Box>

            
        </>
    );
}

const FilterTickets = ({loadTickets,rowSelectionModel}:any) =>{
    const dispatch = useDispatch();
    const allResponses = useSelector((state:IState)=>state?.TicketsReducer?.allResponses?.ticketStatus);
    const [showAssign,setShowAssign] = React.useState(false);
    const [showDelete,setShowDelete] = React.useState(false);
    const [filterValues, setFilterValues] = React.useState({
        search : "",
        isActive : true,
    })
    const filterCallback = React.useCallback((values:any) =>{
        setFilterValues(values)
    },[])
    React.useEffect(()=>{
        // LoadTickets
        loadTickets(filterValues.search)
    },[filterValues])



    const deleteTickets = () =>{
        setShowDelete(true)
    }
    const assignTickets = () =>{
        setShowAssign(true)
    }
    const proceedDeleteItems = () =>{
        const deleteId = allResponses.find((x:any)=>x.name === "Delete")?.id;
        console.log("rowSelectionModel",rowSelectionModel,allResponses,deleteId);

        if(!deleteId) {
            dispatch(triggerAppAlert(`User doesn't have Role Permission to Delete! Please contact Admin.`, 'warning'))
            return;
        };
        // return;
        const payload = {
            "sourceCode": "System",
            "ticketLogs": {
                "sourceCode": "System",
                "groupId": 0,
                "agentId": 0,
                "statusId": deleteId
            }
        }
        dispatch(bulkUpdateNewTickets(rowSelectionModel,payload,bulkUpdateNewTicketsCB))
    }
    const bulkUpdateNewTicketsCB = () =>{
        setShowDelete(false)
        loadTickets(filterValues.search)
    }
    return(
        <>
            <TopFilterNew 
                callback = {filterCallback}
                backNavigationPath = "/TicketManagementSys"
                hideAddBtn = {true}
                hideToggleBtns = {true}
                extraFeatures={<>
                    {rowSelectionModel.length > 0 &&<>
                        <Tooltip title={`Delete Selected Items`}>
                            <Button onClick={deleteTickets}><DeleteOutlineIcon /></Button>
                        </Tooltip>
                        <Tooltip title={`Assign Selected Items`}>
                            <Button onClick={assignTickets}><GroupAddIcon /> </Button>
                        </Tooltip>
                        <Typography component="span">{rowSelectionModel.length} Item{rowSelectionModel.length ===1 ? "" : "s"} selected.</Typography>
                    </>}
                </>}
            />



            {showDelete && 
                <ConfirmationPopup 
                    onClose = {()=>{setShowDelete(false)}}
                    onSubmit = {proceedDeleteItems}
                    title = "Warning" 
                    desc = "Are you sure you want to delete the selected tickets?"
                />
            }
            {showAssign && <>
                <CustomDialog
                    heading={`Assign Agent!`}
                    body={
                    <>
                       <AssignAgentForm
                        selectedTicketIds = {rowSelectionModel}
                        handleClose={() => {
                            setShowAssign(false);
                        }}
                       />
                    </>
                    }
                    open={true}
                    handleClose={() => {
                        setShowAssign(false);
                    }}
                />
            </>}
        </>
    )
}


const AssignAgentForm = ({selectedTicketIds,handleClose}:any) =>{
    return(<>
        <AgentGroupOption  selectedTicketIds={selectedTicketIds} handleClose={handleClose}/>
    </>)
}

const mapStateToProps = (state:IState) => {
    return ({
      allTickets: state?.AllTicketsReducer?.allTickets,
      allResponses : state?.TicketsReducer?.allResponses
    })
  };
  
export default connect(mapStateToProps)(AllNewTickets);