import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getRandomColor } from '../horizontal-chart/VerticalChart';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
ChartJS.defaults.font.family = "'DM Sans', sans-serif";
ChartJS.defaults.font.size = 14;

// Footer callback for tooltips
const footer = (tooltipItems: any) => {
  let sum = 0;
  tooltipItems.forEach((tooltipItem: any) => {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};
const getDarkLightModeTheme = (theme:boolean,isDarkMode:boolean) =>{
  return  {
    y: {
      beginAtZero: true,
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid: {
        color: theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    },
    x:{
      ticks: {
        color : theme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, // Set font size for Y-axis labels
        },
      },
      grid : {
        color : theme || isDarkMode ? "#ffffff10" : "#00000010",
      }
    }
  }
}
const LineChart = ({
  datasets,
  labels,
  chartLabel,
  showLegend = true,
  showLabels = true,
  isDarkMode = false,
}: {
  datasets: any;
  labels: any;
  chartLabel?: string;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const optionsForTicketTrendsChart: any = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    plugins: {
      legend: {
        display: false,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const labels = chart.data.labels;

            return labels.map((label: any, index: any) => {
              const dataset = datasets.find((d: any) => d.data[index] !== undefined);
              const backgroundColor = dataset.data.map(() => getRandomColor())
              const dataValue = dataset?.data[index] || 0;

              return {
                text: `${label} (${dataValue})`,
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          usePointStyle: false, // Use regular legend markers (not point-style)
          color: !isDarkMode ? "black" : "white", // Dynamic color based on mode
          font: {
            size: 14, // Adjust font size based on `showLabels`
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10,      // Custom size for the legend box width (color box)
          boxHeight: 10,     // Custom size for the legend box height
        }
      },
      datalabels: {
        display: true,
        color: toggleTheme || isDarkMode ? "#ffffff" : "#000000",
        font: {
          size: 14, 
          weight: "bold",
        },
      },
      tooltip: {
        display: false,
        callbacks: {
          footer: footer,
        },
        usePointStyle: true,
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return value !== 0; 
        },
      },
      title: {
        display: true, // Enable the title
        text: chartLabel, // Set the title text
        color: isDarkMode ? "white" : "black", // Dynamic title color based on mode
        font: {
          size: 18, // Title font size
          family: "Arial", // Font family for the title
        },
        padding: {
          top: 10,
          bottom: 30,
        },
        align: "center", // Align title in the center
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: any) => context.active,
      },
    },
    scales: getDarkLightModeTheme(toggleTheme,isDarkMode)
  };
  

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Line data={data} options={optionsForTicketTrendsChart} className="line-chart" />;
};

export default LineChart;
