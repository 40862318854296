import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import CustomSelect from '../../common/customSelect/CustomSelect';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import CustomInput from '../../common/textField/CustomInput';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import CategoryTreeSelection from '../../../pages/new-ticket/AddCategoryPopup';

//AddContactAid
function AddContactAid(props: any) {
    const { ticketTypes = [], colVal=6, hideWebForm=false ,stateVal, customDispatch,webFormList=[], initValue=null,dispatch } = props;

    {/* === TicketType Value & Error === */ }
    const [ticketTypeVal, setTicketTypeVal] = useState<any>(null);

    {/* === WebForms Structure === */ }
    const [webFormListObj, setWebFormListObj] = useState<any>(null);
    const [webFormOptions, setWebFormOptions] = useState<any>(null);
    const [webFormVal, setWebFormVal] = useState<any>(null);

    {/* === Category (open && selected value) & Error === */ }
    const [addCategoryPopup, setAddCategoryPopup] = useState<boolean>(false);
    const [chosenSubCategoryVal, setChosenSubCategoryVal] = useState<any>(null);
    const [categoryTree, setCategoryTree] = useState<any>(null);
    const [catTree, setCatTree] = useState<any>([]);

    // when category popup submitted
    const onCategorySubmit = useCallback((res: any) => {
        console.log("onCategorySubmit", res, "category");
        setChosenSubCategoryVal(res)
    }, []);

    

    // change category when ticket type changed
    useEffect(() => {
        if (ticketTypeVal && ticketTypeVal?.id) {
            if (categoryTree?.[ticketTypeVal?.id]) setCatTree([categoryTree[ticketTypeVal?.id]]);
            setWebFormOptions(webFormListObj?.[ticketTypeVal?.id] || [])
        } else {
            setCatTree([])
            setWebFormOptions([])
        }
    }, [ticketTypeVal?.id,categoryTree])    

    // category modal
    console.log("CatalogueCatalogue",catTree,categoryTree);
    

    {/*=== create webFormList structure ===*/}
    useEffect(() => {
        const tempObj: any = {};
        for (const form of webFormList) {
            tempObj[form.ticketTypeId] = tempObj[form.ticketTypeId] ? [...tempObj[form.ticketTypeId],form] : [form];
        }
        setWebFormListObj(tempObj);
        console.log('setWebFormListObj(tempObj);',tempObj)
    }, [webFormList.length]);

    {/*=== populate on edit ===*/}
    useEffect(()=>{
        if(!initValue) return;
        console.log('initVali___nitValinitVal',initValue);
        const {ticketType,category,webform} = initValue;
        setTicketTypeVal(ticketType  || null)
        setChosenSubCategoryVal(category  || null)
        setWebFormVal(webform || null)

    },[initValue])

    {/*=== storeValues ===*/}
    useEffect(()=>{
        customDispatch({
            type: "CHANGE_VALUE" ,
            payload : {
                ticketType : ticketTypeVal,
                category : chosenSubCategoryVal,
                webFrom : webFormVal,
            }
        })
    },[ticketTypeVal,chosenSubCategoryVal,webFormVal])
    const unsetErr = (name:string="") => {
        //UNSET_ERR
        customDispatch({
            type: "UNSET_ERR",
            payload : name
        })
    }
    const handleClearClick = (e:any) => {
        e.stopPropagation()
        setChosenSubCategoryVal({ label: '' });
      };
      const handleCategoryClick = (e:any) => {
        setAddCategoryPopup(true);
        unsetErr('categoryId');
      };
    return (
        <>
            <Grid item md={colVal} xs={12}>
                <CustomFormLabel
                    labelName={"Select TicketType"}
                    isMandotary={true}
                />
                <CustomSelect
                    isOpen={false}
                    placeholder="Select TicketType"
                    width={"100%"}
                    name="ticketTypeId"
                    isColor={false}
                    isDisabled={false}
                    isError={stateVal?.errors?.ticketTypeId ? true : false}
                    helperText={`${stateVal?.errors?.ticketTypeId ? stateVal?.errors?.ticketTypeId : ""}`}
                    defaultValue={ticketTypeVal}
                    options={ticketTypes || []}
                    handleChange={(e: any) => {
                        setTicketTypeVal(e)
                        setChosenSubCategoryVal(null)
                        setWebFormVal(null)
                        unsetErr("ticketTypeId")
                    }}
                    isMulti={false}
                    customClassNames="sml-txt-dropdown new-service"
                />
            </Grid>
            <Grid item md={colVal} xs={12} className='article-select-category'>
                <CustomFormLabel
                    labelName={"Select Category"}
                    isMandotary={false}
                />
                <CustomInput
                        readOnly={false}
                        iconPosition={"start"}
                        icon={<AccountTreeIcon/>}
                        isError={stateVal?.errors?.categoryId ? true : false}
                        helperText={`${stateVal?.errors?.categoryId ? stateVal?.errors?.categoryId : ""}`}
                        label={"Please Enter"}
                        name="category"
                        isDisabled={false}
                        // value={categoryData?.[`${values?.ticketType?.value}`]?.[0]?.name ? categoryData?.[`${values?.ticketType?.value}`]?.[0]?.name : "No category to show"}
                        value={chosenSubCategoryVal?.label || ""}
                        onChange={(e: any) => { }}
                        onClick={(e: any) => {
                            if(ticketTypeVal?.id){
                                dispatch({
                                  type:"CATEGORY_POPUP_TICKET_TYPE_ID",
                                  payload : ticketTypeVal?.id
                                })
                              }else{
                                dispatch(triggerAppAlert("Please choose Ticket type before selecting Category","warning"))
                            }
                            // handleCategoryClick(e)
                            // setAddCategoryPopup(true)
                            // unsetErr("categoryId")
                        }}
                        classNames='common-new-input with-icon'
                        placeholder={"View category tree"}
                        // onClearClick={handleClearClick}
                    />
            </Grid>
            {!hideWebForm && 
                <Grid item md={colVal} xs={12}>
                    <CustomFormLabel
                        labelName={"Select Webform"}
                        isMandotary={false}
                    />
                    <CustomSelect
                        isOpen={false}
                        placeholder="Select Webform"
                        width={"100%"}
                        name="webformId"
                        isColor={false}
                        isDisabled={false}
                        isError={stateVal?.errors?.webformId ? true : false}
                        helperText={`${stateVal?.errors?.webformId ? stateVal?.errors?.webformId : ""}`}
                        defaultValue={webFormVal}
                        options={webFormOptions}
                        handleChange={(e: any) => {
                            setWebFormVal(e)
                            unsetErr("webformId")
                        }}
                        isMulti={false}
                        customClassNames="sml-txt-dropdown new-service"
                    />
                </Grid>
            }
            <CategoryTreeSelection callback={onCategorySubmit}/> 
        </>
    )
}
const mapStateToProps = (state: IState) => {
    return ({
        ticketTypes: state?.TicketsReducer?.allResponses?.TicketTypes,
        categoryList: state?.categoryReducer?.Data?.data,
    })
};

export default connect(mapStateToProps)(AddContactAid);