import CloseIcon from '@mui/icons-material/Close';
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getContactByIdAction } from "../../../redux/actions/ContactManagement/Contact";
import { handleContactTicketsModal } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from "../../../redux/reducers/rootReducers";

export const ContactPopup = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const contactInfo = useSelector((state: IState) => state?.TicketViewReducer?.contactPopupInfo);
    const contactsById = useSelector((state: IState) => state?.ContactsReducer?.contactByIdValues);

   
    useEffect(()=>{
        console.log('contactsById',contactsById)
        if(contactInfo?.id && !contactsById?.[contactInfo?.id]){
            console.log('contactsById',contactsById) 
            dispatch(getContactByIdAction(contactInfo?.id))
        }
    },[contactInfo?.id])
    // useEffect(()=>{
    //     console.log('AllInfo',AllInfo)
    // },[AllInfo])
    const onCloseHandler = useCallback(() =>{
        dispatch({
            type:"CONTACT_POPUP_INFO",
            payload : null
        })
    },[])
    const viewContent = useMemo(()=><>
        {contactInfo?.id && 
        <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
            <Box className="modal-dialog">
            <Box className="modal-content">
                <Box className="modal-header">
                <Typography variant="h5" className="txtCapitalize">
                    {contactInfo?.name}
                    <b>
                    {contactInfo?.isVIP ? (
                        <Tooltip title="VIP User">
                        <DiamondOutlinedIcon
                            fontSize="small"
                            color="primary"
                        />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    &nbsp;&nbsp;
                    {contactInfo?.alert && contactInfo.alert !== "" ? (
                        <Tooltip title={contactInfo.alert}>
                        <ReportProblemOutlinedIcon
                            fontSize="small"
                            color="warning"
                        />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    </b>
                </Typography>
                <Box className="row m-0 gap15 align-items-center cursor-pointer">
                    <Typography className="title-txts" onClick={()=>{
                        dispatch(handleContactTicketsModal({
                            id : contactInfo?.id,
                            name : contactInfo?.name
                        }))
                    }}><b className='txt-primary'>View Tickets</b></Typography>
                    <Typography className="cursor-pointer" onClick={onCloseHandler}>
                        <CloseIcon className="cursor-pointer" />
                    </Typography>
                </Box>
                </Box>
                <Box className="modal-body">
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3} >
                    <div className='aside t-0'>
                        {/* <img src={ contactInfo?.profilePictureId>0 ? `${ContactListImgs?.[contactInfo?.profilePictureId]}` : UserIcon} alt="UserIcon" height={"200px"} width={"100%"} className="Contactprofile" /> */}
                        <Avatar className='Contactprofile cursor-pointer' sx={{height:"200px",width:"90%"}} alt={"Contact Image"} src={`${contactInfo?.profilePicturePath || ""}`}   />
                        <hr />
                        <Typography className="title-txts">
                        <span>Name :</span> {contactInfo?.name}
                        </Typography>
                        <Typography className="title-txts">
                        <span>Organisation :</span> {contactsById?.[contactInfo?.id]?.contactOrganisation?.name ? contactsById?.[contactInfo?.id]?.contactOrganisation?.name : "-"}
                        </Typography>
                        <Typography className="title-txts">
                        <span>Division :</span> {contactsById?.[contactInfo?.id]?.division?.name ? contactsById?.[contactInfo?.id]?.division?.name : "-"}
                        </Typography>
                        <Typography className="title-txts">
                        <span>Department :</span> {contactsById?.[contactInfo?.id]?.department?.name ? contactsById?.[contactInfo?.id]?.department?.name : "-"}
                        </Typography>
                    </div>
                    </Grid>
                    <Grid item xs={6} md={6} className='bl-1'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Base Location :</span>{contactsById?.[contactInfo?.id]?.baseLocation?.name ? contactsById?.[contactInfo?.id]?.baseLocation?.name : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Office Location :</span>
                            {contactsById?.[contactInfo?.id]?.officeLocation ? contactsById?.[contactInfo?.id]?.officeLocation : "-"}
                        </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Contact Type :</span>
                            {contactInfo?.contactType ? contactInfo?.contactType?.name : "-"}
                        </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Email :</span><p style={{margin:"0px",overflowWrap:"break-word"}}>{contactInfo?.email}</p> </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Manager :</span> {contactsById?.[contactInfo?.id]?.manager?.name ? contactsById?.[contactInfo?.id]?.manager.name : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Job Title :</span> {contactsById?.[contactInfo?.id]?.jobTitle ? contactsById?.[contactInfo?.id]?.jobTitle : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Mobile Phone :</span> {contactInfo?.mobilePhone === '' || contactInfo?.mobilePhone === null ? '-' : contactInfo?.mobilePhone}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Business Phone :</span>{contactsById?.[contactInfo?.id]?.businessPhone ? contactsById?.[contactInfo?.id]?.businessPhone : "-"} </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>WhatsApp Number :</span>{contactsById?.[contactInfo?.id]?.whatsAppNumber ? contactsById?.[contactInfo?.id]?.whatsAppNumber : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Teams Id :</span>{contactsById?.[contactInfo?.id]?.teamsId ? contactsById?.[contactInfo?.id]?.teamsId : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Employee Hire Date :</span>{contactsById?.[contactInfo?.id]?.employeeHireDate ? moment(contactsById?.[contactInfo?.id]?.employeeHireDate).format('DD-MM-YYYY') : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>External Employee Id :</span>{contactsById?.[contactInfo?.id]?.externalEmployeeId ? contactsById?.[contactInfo?.id]?.externalEmployeeId: "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Age Group :</span>{contactsById?.[contactInfo?.id]?.ageGroup ? contactsById?.[contactInfo?.id]?.ageGroup : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>  
                        <Typography className="title-txts"><span>Consent Provided For Minor :</span>{contactsById?.[contactInfo?.id]?.consentProvidedForMinor ? contactsById?.[contactInfo?.id]?.consentProvidedForMinor : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Legal Age Group Classification :</span>{contactsById?.[contactInfo?.id]?.legalAgeGroupClassification ? contactsById?.[contactInfo?.id]?.legalAgeGroupClassification : "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Self Service Access :</span> {contactsById?.[contactInfo?.id]?.isSelfServiceAccess ? "Yes" : "No"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography className="title-txts"><span>Single Sign On Enabled :</span> {contactsById?.[contactInfo?.id]?.isSingleSignOnEnabled ? "Yes" : "No"}</Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={6} md={3} className='bl-1'>
                    <Typography className="title-txts"><span>Assets ({contactsById?.[contactInfo?.id]?.assetList?.length})</span></Typography>

                    {(!contactsById?.[contactInfo?.id]?.assetList || contactsById?.[contactInfo?.id]?.assetList?.length === 0) && <><Typography className={`${classes.textWithSpanTitle}`}>No Assets found</Typography></>}
                    {contactsById?.[contactInfo?.id]?.assetList && contactsById?.[contactInfo?.id]?.assetList.map((ele: any, i: number) => (
                        <Box key={ele.id}>
                        <Typography onClick={onCloseHandler} className={`${classes.txtWithLink} view-contact-links`} gutterBottom>
                            <span>{i + 1}{")"}&nbsp;{ele.assetTag}  {ele?.assetType?.name ? ` - ${ele?.assetType?.name}` : ""} </span>
                            <Link to={`/ViewAsset/${ele.id}?from=contact`}><OpenInNewIcon /></Link>
                        </Typography>
                        </Box>
                    ))}
                    </Grid>
                </Grid>
                </Box>
            </Box>
            </Box>
        </Box>
        }
    </>,[contactInfo?.id,contactsById?.[contactInfo?.id]])
    return (<>
      {viewContent}
    </>)
  })

const useStyles = makeStyles(() =>
  createStyles({
    popupHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#0005",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 1399,
    "& .modal-dialog": {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
    },
    "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
    },
    "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px"
    },
    "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)"
    },
    "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "1000px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
        width: "calc(100% - 10px)",
        },
        "& img": {
        objectFit: "cover"
        }
    }
    },
    textWithSpanTitle: {
        "& .title-txts":{
            lineHeight: '1.5',
            marginBottom: 10,
            fontSize: '13px',
            "& > span": {
            display: 'block',
            fontSize: '11px',
            marginBottom: '2px',
            lineHeight: '1',
            color: "#5559"//mode.link,
            }
        }
      },
      txtWithLink: {
        fontSize: ".7em",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& span": {
          wordBreak: "break-all"
        },
        "& a": {
          fontSize: ".7em",
          textDecoration: "none",
          display: "inline-block",
          lineHeight: 1,
          "& svg": {
            fontSize: "12px"
          }
        }
      }
}))